<!-- NOT IMPLEMENTED YET -->

<template>
  <div class="d-flex justify-content-between">
    <h1>Neuer auftrag</h1>
  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <div v-else>
    {{ neuerAuftrag.auftrag }}
  </div>

  <div v-if="!loading && errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Erstellen des Auftrags aufgetreten. Bite probieren Sie es später erneut.
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, reactive, onMounted } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { Auftrag } from '@/models/AuftragModels';

  import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';
  import { ActionTypes as KundeActionTypes } from '@/store/modules/Kunde/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  //#endregion

  const store = useStore();
  const router = useRouter();

  const neuerAuftrag = reactive({
    auftrag: new Auftrag(),
  });

  const artikel = computed(() => store.getters.artikel);

  const loading = computed(() => store.getters.status.createAuftragLoading);
  const errorMsg = computed(() => store.getters.status.auftragErrorMsg);

  function loadData() {
    store.dispatch(AuftragActionTypes.GetAuftragStatus, undefined);
    store.dispatch(KundeActionTypes.GetKunde, undefined);
    store.dispatch(ArtikelActionTypes.GetArtikel, undefined);
  }

  function createAuftrag() {
    store.dispatch(AuftragActionTypes.CreateAuftrag, neuerAuftrag.auftrag).then(() => {
      if (errorMsg.value == '') {
        clearInputs();
        router.push({ name: 'Auftraege' });
      }
    });
  }

  function clearInputs() {
    neuerAuftrag.auftrag = new Auftrag();
  }

  onMounted(() => {
    loadData();
  });
</script>
