<template>
  <button v-if="displayButton == 'N'" @click="showModal()" class="btn btn-primary me-2">
    <font-awesome-icon icon="arrow-right-from-bracket" class="me-2" />Umlagern
  </button>
  <button v-else-if="displayButton == 'S'" @click="showModal()" class="btn btn-primary me-2">
    <font-awesome-icon icon="arrow-right-from-bracket" />
  </button>
  <span v-else-if="displayButton == 'T'" @click="showModal()" role="button" class="w-100">Umlagern</span>

  <!-- Update Modal -->
  <div
    class="modal fade"
    :id="'bestandUmlagernModal' + bestand.id"
    tabindex="-1"
    :aria-labelledby="'bestandUmlagernModal' + bestand.id"
    aria-hidden="true"
    ref="bestandUmlagernModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white py-2">
          <span class="modal-title my-auto" :id="'bestandUmlagernModalLabel' + bestand.id">
            <span v-if="lagerbereich?.lagerbereichartid == 5 || lagerbereich?.lagerbereichartid == 7">Einlagern</span>
            <span v-else>Umlagern</span>
          </span>

          <button type="button" class="btn-close btn-sm text-white" @click="closeModal()"></button>
        </div>

        <div class="modal-body" :id="'bestandUmlagernModalBody' + bestand.id">
          <LoadingSpinner v-if="updateBestandLoading" />

          <div v-else>
            <form @submit.prevent="updateBestandUmlagern()">
              <p><strong>Anzahl:</strong> (optional)<br />Wenn Anzahl = 0 ist, wird alles umgelagert</p>
              <div class="row mb-3">
                <div class="col-4 my-auto">
                  <input type="number" v-model="anzahl" class="form-control" :id="'einheit-anzahl-' + bestand.id" />
                </div>

                <div class="col-8 my-auto">
                  <span>{{ bestand.einheit?.name }}</span>
                </div>
              </div>

              <div class="mb-3" v-if="lagerbereich?.lagerbereichartid == 7">
                <label for="new-Barcode" class="form-label">Neuer Barcode</label>
                <input type="text" class="form-control" id="new-barcode" v-model="umlagernDaten.neuerBarcode" />
              </div>

              <hr class="my-4" />

              <div class="mb-3">
                <label :for="'lagerortauswahl-' + bestand.id" class="form-label">Lagerort</label>
                <select class="form-select" :id="'lagerortauswahl-' + bestand.id" v-model="umlagernDaten.lagerortid" @change="resetBereich()">
                  <option :value="null">Lagerort auswählen...</option>
                  <option v-for="lagerort in lagerorte" :key="lagerort.id" :value="lagerort.id">{{ lagerort.name }}</option>
                </select>
              </div>

              <div v-if="umlagernDaten.lagerortid != 0" class="mb-3">
                <label :for="'lagerbereichauswahl-' + bestand.id" class="form-label">Lagerbereich</label>
                <select class="form-select" :id="'lagerbereichauswahl-' + bestand.id" v-model="umlagernDaten.lagerbereichid">
                  <option :value="null">Lagerbereich auswählen...</option>
                  <option v-for="bereiche in lagerbereiche" :key="bereiche.id" :value="bereiche.id">
                    {{ getLagerbereichInfo(bereiche.ownerid || 0)?.name }} {{ bereiche.name }}
                  </option>
                </select>
              </div>

              <div v-if="umlagernDaten.lagerortid != 0 && umlagernDaten.lagerbereichid != 0" class="mb-3">
                <label :for="'reiheauswahl-' + bestand.id" class="form-label">Reihe</label>
                <select class="form-select" :id="'reiheauswahl-' + bestand.id" v-model="umlagernDaten.lagerreiheid">
                  <option :value="null">Lagerreihe auswählen...</option>
                  <option v-for="reihe in lagerreihen" :key="reihe.id" :value="reihe.id">{{ reihe.reihe }}</option>
                </select>
              </div>

              <div
                v-if="
                  umlagernDaten.lagerortid != 0 &&
                  umlagernDaten.lagerbereichid != 0 &&
                  umlagernDaten.lagerreiheid != 0 &&
                  selectedLagerbereich?.lagerbereichartid != 2
                "
                class="mb-3"
              >
                <label :for="'platzauswahl-' + bestand.id" class="form-label">Platz</label>
                <select class="form-select" :id="'platzauswahl-' + bestand.id" v-model="umlagernDaten.lagerplatzid">
                  <option :value="null">Lagerplatz auswählen...</option>
                  <option v-for="platz in lagerplaetze" :key="platz.id" :value="platz.id">{{ platz.platz }}</option>
                </select>
              </div>

              <hr class="my-3" />

              <LoadingSpinner v-if="updateBestandLoading" />

              <div v-if="bestandErrorMsg != ''" class="alert alert-danger">
                {{ bestandErrorMsg }}
              </div>

              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary btn-sm me-3" @click="closeModal()">Abbrechen</button>
                <button type="submit" class="btn btn-primary btn-sm">Speichern</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed, ref } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';
  import { ActionTypes as LagerReservierungActionTypes } from '@/store/modules/LagerReservierung/actions';

  import { ArtikelBestand, ArtikelBestandUmlagernRequest } from '@/models/BestandModels';
  import { Lagerbereich, Lagerreihe, Lagerplatz } from '@/models/LagerModels';
  import { LagerReservierung } from '@/models/LagerReservierungModels';

  import LoadingSpinner from '../LoadingSpinner.vue';

  //#endregion

  const { displayButton, bestand } = defineProps({
    displayButton: {
      type: String,
      default: 'X', // "S" = small, "N" = normal, "T" = text
    },

    bestand: {
      type: Object as PropType<ArtikelBestand>,
      required: true,
    },
  });

  const store = useStore();

  const updateBestandLoading = computed(() => store.getters.status.updateBestandLoading);
  const bestandErrorMsg = computed(() => store.getters.status.bestandErrorMsg);

  const anzahl = ref(0);
  const umlagernDaten = ref(new ArtikelBestandUmlagernRequest());

  const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == bestand.lagerbereichid));

  const lagerReservierung = computed(() => store.getters.lagerReservierungen.find((lr: LagerReservierung) => lr.bestandid == bestand.id));

  if (lagerReservierung.value != null) {
    umlagernDaten.value.lagerortid = lagerReservierung.value.lagerortid;
    umlagernDaten.value.lagerbereichid = lagerReservierung.value.lagerbereichid;
    umlagernDaten.value.lagerreiheid = lagerReservierung.value.lagerreiheid;
    umlagernDaten.value.lagerplatzid = lagerReservierung.value.lagerplatzid;
  }

  function resetBereich() {
    umlagernDaten.value.lagerbereichid = null;
    umlagernDaten.value.lagerreiheid = null;
    umlagernDaten.value.lagerplatzid = null;
  }

  function updateBestandUmlagern() {
    umlagernDaten.value.artikelbestandid = bestand.id;
    umlagernDaten.value.anzahl = anzahl.value > 0 ? anzahl.value : null;

    closeModal();

    store.dispatch(BestandActionTypes.UpdateBestandUmlagern, umlagernDaten.value).then(() => {
      if (bestandErrorMsg.value == '') {
        store.dispatch(LagerReservierungActionTypes.GetLagerReservierung, undefined);
      }
    });
    // store.dispatch(BestandActionTypes.UpdateBestandUmlagern, updatedBestand.value).then(() => {
    //   if (bestandErrorMsg.value == '') {
    //     closeModal();
    //     store.dispatch(LagerReservierungActionTypes.GetLagerReservierung, undefined);
    //   }
    // });
  }

  const selectedLagerbereich = computed(() => lagerbereiche.value.find((lb: Lagerbereich) => lb.id == umlagernDaten.value.lagerbereichid));

  const allLagerbereiche = computed(() => store.getters.lagerbereiche);
  function getLagerbereichInfo(id: number) {
    return allLagerbereiche.value.find((l: Lagerbereich) => l.id == id);
  }

  const lagerorte = computed(() => store.getters.lagerorte);
  const lagerbereiche = computed(() => store.getters.lagerbereiche.filter((l: Lagerbereich) => l.lagerortid == umlagernDaten.value.lagerortid));
  const lagerreihen = computed(() => store.getters.lagerreihen.filter((bl: Lagerreihe) => bl.lagerbereichid == umlagernDaten.value.lagerbereichid));
  const lagerplaetze = computed(() => store.getters.lagerplaetze.filter((bl: Lagerplatz) => bl.lagerreiheid == umlagernDaten.value.lagerreiheid));

  function resetInputs() {
    umlagernDaten.value = new ArtikelBestandUmlagernRequest();
    if (lagerReservierung.value != null) {
      umlagernDaten.value.lagerortid = lagerReservierung.value.lagerortid;
      umlagernDaten.value.lagerbereichid = lagerReservierung.value.lagerbereichid;
      umlagernDaten.value.lagerreiheid = lagerReservierung.value.lagerreiheid;
      umlagernDaten.value.lagerplatzid = lagerReservierung.value.lagerplatzid;
    }

    anzahl.value = 0;
  }
  //#region Modal
  const bestandUmlagernModalRef = ref();
  function closeModal() {
    Modal.getInstance(bestandUmlagernModalRef.value)?.hide();
  }
  async function showModal() {
    if (bestandUmlagernModalRef.value) {
      resetInputs();
      new Modal(bestandUmlagernModalRef.value).show();
    }
  }
  //#endregion

  defineExpose({ showModal, closeModal });
</script>
