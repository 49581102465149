import { MutationTree } from 'vuex';
import { State } from './state';
import { ArtikelBestand, ArtikelBestandBewegung } from '@/models/BestandModels';
import { Lieferung, LieferungStatus } from '@/models/LieferungModels';

export enum MutationTypes {
  SetBestand = 'SETBESTAND',
  AddOrUpdateBestand = 'ADDORUPDATEBESTAND',
  AddOrUpdateBestaende = 'ADDORUPDATEBESTAENDE',
  DeleteBestand = 'DELETEBESTAND',

  SetLieferung = 'SETLIEFERUNG',
  AddLieferung = 'ADDLIEFERUNG',
  UpdateLieferung = 'UPDATELIEFERUNG',
  DeleteLieferung = 'DELETELIEFERUNG',

  SetLieferungStatus = 'SETLIEFERUNGSTATUS',
  AddLieferungStatus = 'ADDLIEFERUNGSTATUS',
  UpdateLieferungStatus = 'UPDATELIEFERUNGSTATUS',
  DeleteLieferungStatus = 'DELETELIEFERUNGDSTATUS',

  SetBestandBewegung = 'SETBESTANDBEWEGUNG',
  AddBestandBewegungen = 'ADDBESTANDBEWEGUNGEN',
}

export type Mutations = {
  [MutationTypes.SetBestand](state: State, bestand: Array<ArtikelBestand>): void;
  [MutationTypes.AddOrUpdateBestand](state: State, bestand: ArtikelBestand): void;
  [MutationTypes.AddOrUpdateBestaende](state: State, bestaende: Array<ArtikelBestand>): void;
  [MutationTypes.DeleteBestand](state: State, bestandid: number): void;

  [MutationTypes.SetLieferung](state: State, bestand: Array<Lieferung>): void;
  [MutationTypes.AddLieferung](state: State, bestand: Lieferung): void;
  [MutationTypes.UpdateLieferung](state: State, bestand: Lieferung): void;
  [MutationTypes.DeleteLieferung](state: State, bestandid: number): void;

  [MutationTypes.SetLieferungStatus](state: State, data: Array<LieferungStatus>): void;
  [MutationTypes.AddLieferungStatus](state: State, data: LieferungStatus): void;
  [MutationTypes.UpdateLieferungStatus](state: State, data: LieferungStatus): void;
  [MutationTypes.DeleteLieferungStatus](state: State, dataid: number): void;

  [MutationTypes.SetBestandBewegung](state: State, data: Array<ArtikelBestandBewegung>): void;
  [MutationTypes.AddBestandBewegungen](state: State, data: Array<ArtikelBestandBewegung>): void;
};

export const mutations: MutationTree<State> & Mutations = {
  //#region Bestand
  [MutationTypes.SetBestand](state: State, bestand: Array<ArtikelBestand>) {
    state.bestand = bestand;
    sessionStorage.setItem('bestand', JSON.stringify(state.bestand));
  },

  [MutationTypes.AddOrUpdateBestand](state: State, bestand: ArtikelBestand) {
    const index = state.bestand.findIndex((a) => a.id === bestand.id);
    if (index === -1) {
      state.bestand.push(bestand);
    } else {
      state.bestand.splice(index, 1, bestand);
    }

    sessionStorage.setItem('bestand', JSON.stringify(state.bestand));
  },

  [MutationTypes.AddOrUpdateBestaende](state: State, bestaende: Array<ArtikelBestand>) {
    for (var bestand of bestaende) {
      const index = state.bestand.findIndex((a) => a.id === bestand.id);
      if (index === -1) {
        state.bestand.push(bestand);
      } else {
        state.bestand.splice(index, 1, bestand);
      }
    }

    sessionStorage.setItem('bestand', JSON.stringify(state.bestand));
  },

  [MutationTypes.DeleteBestand](state: State, bestandid: number) {
    const bestand = state.bestand.find((a) => a.id === bestandid);
    if (bestand != null) {
      const index = state.bestand.indexOf(bestand);
      state.bestand.splice(index, 1);
    }

    sessionStorage.setItem('bestand', JSON.stringify(state.bestand));
  },
  //#endregion

  //#region  Lieferung
  [MutationTypes.SetLieferung](state, lieferung) {
    state.lieferungen = lieferung;
    sessionStorage.setItem('lieferungen', JSON.stringify(state.lieferungen));
  },

  [MutationTypes.AddLieferung](state, lieferung) {
    state.lieferungen.push(lieferung);
    sessionStorage.setItem('lieferungen', JSON.stringify(state.lieferungen));
  },

  [MutationTypes.UpdateLieferung](state, lieferung) {
    const liefer = state.lieferungen.find((a) => a.id === lieferung.id);
    if (liefer != null) {
      const index = state.lieferungen.indexOf(liefer);
      state.lieferungen.splice(index, 1, lieferung);
    }

    sessionStorage.setItem('lieferungen', JSON.stringify(state.lieferungen));
  },

  [MutationTypes.DeleteLieferung](state, lieferungid) {
    const liefer = state.lieferungen.find((a) => a.id === lieferungid);
    if (liefer != null) {
      const index = state.lieferungen.indexOf(liefer);
      state.lieferungen.splice(index, 1);
    }

    sessionStorage.setItem('lieferungen', JSON.stringify(state.lieferungen));
  },
  //#endregion

  //#region  LieferungStatus
  [MutationTypes.SetLieferungStatus](state, data) {
    state.lieferungstatus = data;
    sessionStorage.setItem('lieferungstatus', JSON.stringify(state.lieferungstatus));
  },

  [MutationTypes.AddLieferungStatus](state, data) {
    state.lieferungstatus.push(data);
    sessionStorage.setItem('lieferungstatus', JSON.stringify(state.lieferungstatus));
  },

  [MutationTypes.UpdateLieferungStatus](state, data) {
    const liefer = state.lieferungstatus.find((a) => a.id === data.id);
    if (liefer != null) {
      const index = state.lieferungstatus.indexOf(liefer);
      state.lieferungstatus.splice(index, 1, data);
    }

    sessionStorage.setItem('lieferungstatus', JSON.stringify(state.lieferungstatus));
  },

  [MutationTypes.DeleteLieferungStatus](state, dataid) {
    const liefer = state.lieferungstatus.find((a) => a.id === dataid);
    if (liefer != null) {
      const index = state.lieferungstatus.indexOf(liefer);
      state.lieferungstatus.splice(index, 1);
    }

    sessionStorage.setItem('lieferungstatus', JSON.stringify(state.lieferungstatus));
  },
  //#endregion

  //#region  BestandBewegung
  [MutationTypes.SetBestandBewegung](state, data) {
    state.bestandBewegungen = data;
    sessionStorage.setItem('bestandBewegungen', JSON.stringify(state.bestandBewegungen));
  },

  [MutationTypes.AddBestandBewegungen](state, data) {
    state.bestandBewegungen.push(...data);
    sessionStorage.setItem('bestandBewegungen', JSON.stringify(state.bestandBewegungen));
  },
  //#endregion
};
