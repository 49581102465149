<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm artikel-row" @click="openArtikelDetails()">
    <div class="col-8 my-auto">
      {{ artikeltemplate.titel }}
    </div>

    <div class="col-2 my-auto">{{ artikelart?.name }}</div>

    <div class="col-2 my-auto">
      <button class="btn btn-primary m-0 float-end">
        <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from '@/store';

  import { Artikeltemplate } from '@/models/ArtikeltemplateModels';
  import { Artikelart } from '@/models/ArtikelModels';

  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  //#endregion

  const props = defineProps({
    artikeltemplate: {
      type: Object as PropType<Artikeltemplate>,
      required: true,
    },
  });

  const router = useRouter();
  const store = useStore();

  const artikelart = computed(() => store.getters.artikelarten.find((a: Artikelart) => a.id == props.artikeltemplate.artikelartid));

  function openArtikelDetails() {
    router.push({ name: 'ArtikeltemplateDetails', params: { id: props.artikeltemplate.id } });
  }

  onMounted(() => {
    if (store.getters.artikelarten.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikelarten, undefined);
    }
  });
</script>

<style scoped>
  .artikel-row:hover {
    cursor: pointer;
    color: #2a579b;
    background-color: #dfe9f8 !important;
  }
</style>
