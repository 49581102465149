<template>
  <button class="btn btn-secondary" @click="showModal()"><font-awesome-icon icon="pen" /></button>

  <div
    class="modal fade p-0"
    tabindex="-1"
    aria-labelledby="editImportArtikelModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="editImportArtikelModalRef"
  >
    <div class="modal-dialog modal-xl text-start">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white d-flex justify-content-between p-2">
          <h5 class="m-0 p-0">Artikel</h5>

          <button type="button" class="btn-close" @click="closeModal()"></button>
        </div>

        <div class="modal-body text-dark">
          <!-- TITEL -->
          <div class="row mb-3">
            <div class="col-2 fw-bold my-auto">
              <label for="artikel-titel" class="form-label m-0">Titel</label>
            </div>

            <div v-if="existingArtikel" class="col-3 my-auto">
              {{ existingArtikel.titel }}
            </div>

            <div class="col">
              <input type="text" v-model="artikel.titel" class="form-control" id="artikel-titel" />
            </div>
          </div>

          <!-- ARTIKELNUMMER -->
          <div class="row mb-3">
            <div class="col-2 fw-bold my-auto">
              <label for="artikel-nr" class="form-label m-0">Artikelnummer</label>
            </div>

            <div v-if="existingArtikel" class="col-3 my-auto">
              {{ existingArtikel.artikelNr }}
            </div>

            <div class="col">
              <input type="text" v-model="artikel.artikelNr" class="form-control" id="artikel-nr" />
            </div>
          </div>

          <div class="mb-3">
            <label for="anzahl" class="form-label">Anzahl</label>
            <input type="number" v-model="artikel.anzahl" class="form-control" id="anzahl" />
          </div>

          <div class="mb-3">
            <select class="form-select" v-model="artikel.einheitid">
              <option v-for="ae in artikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
            </select>
          </div>

          <!-- BESCHREIBUNG -->
          <div class="row mb-3">
            <div class="col-2 fw-bold">
              <label for="artikel-beschreibung" class="form-label">Beschreibung</label>
            </div>

            <div v-if="existingArtikel" class="col-3">
              {{ existingArtikel.beschreibung }}
            </div>

            <div class="col">
              <textarea class="form-control" v-model="artikel.beschreibung" id="artikel-beschreibung" rows="3"></textarea>
            </div>
          </div>

          <p class="fw-bold">Bestand Einstellung</p>
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" v-model="artikel.inProdZusammenfuehren" id="in-prod-zusammenfuehren" />
            <label class="form-check-label" for="in-prod-zusammenfuehren"> Bestand in Produktion zusammenführen </label>
          </div>

          <hr class="my-4" />

          <code>{{ artikel }}</code>

          <!-- EIGENSCHAFTEN -->
          <p class="fw-bold">Eigenschaften</p>
          <div class="row">
            <div v-if="existingArtikel && existingArtikel.eigenschaften.length > 0" class="col">
              <p>Alte Eigenschaften</p>
              <div v-for="(eigen, index) in existingArtikel.eigenschaften" :key="index" class="row mb-2">
                <div class="col-2 my-auto">
                  <label :for="'einheit-' + index" class="form-label fw-bold me-2">{{ eigen.key }}:</label>
                </div>

                <div class="col">
                  <input type="text" v-model="eigen.value" class="form-control" :id="'einheit-' + index" disabled />
                </div>
              </div>
            </div>

            <div v-if="artikel.eigenschaften.length > 0" class="col">
              <p v-if="existingArtikel && existingArtikel.eigenschaften.length > 0">Neue Eigenschaften</p>
              <div v-for="(eigen, index) in artikel.eigenschaften" :key="index" class="row mb-2">
                <div class="col-2 my-auto">
                  <label :for="'eigenschaft-' + eigen.key + '-' + index" class="form-label">{{ eigen.key }}:</label>
                </div>

                <div class="col">
                  <input type="text" v-model="eigen.value" class="form-control" :id="'eigenschaft-' + eigen.key + '-' + index" />
                </div>
              </div>
            </div>
          </div>

          <hr class="my-4" />

          <!-- TAGS -->
          <p class="fw-bold">Tags</p>
          <div class="d-flex flex-row mb-3 flex-wrap">
            <div v-for="tag in artikel.tags" :key="tag.id" class="small fw-bold text-primary p-2 rounded border border-primary me-2">
              {{ tags.find((t) => t.id == tag.tagid)?.key }}
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary ms-3" @click="closeModal()">Fertig</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, PropType, ref } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import { Artikel } from '@/models/ArtikelModels';
  //#endregion

  const store = useStore();

  const { artikel } = defineProps({
    artikel: {
      type: Object as PropType<Artikel>,
      required: true,
    },
  });

  const existingArtikel = computed(() => store.getters.artikel.find((a: Artikel) => a.artikelNr === artikel.artikelNr));

  const artikeleinheiten = computed(() => store.getters.artikeleinheiten);

  const tags = computed(() => store.getters.tags);

  const editImportArtikelModalRef = ref();
  function showModal() {
    if (editImportArtikelModalRef.value) {
      new Modal(editImportArtikelModalRef.value).show();
    }
  }

  function closeModal() {
    Modal.getInstance(editImportArtikelModalRef.value)?.hide();
  }
</script>
