<template>
  <div class="mb-2 bg-white shadow-sm">
    <div class="row mx-1 py-2">
      <div class="col-2 my-auto">{{ artikel?.artikelNr }}</div>
      <div class="col-3 my-auto">{{ artikel?.titel }}</div>
      <div class="col-6 d-flex flex-wrap my-auto">
          <span class="border border-primary px-2 py-1 me-2"
            >{{ gesamtBestand }} {{ artikel?.einheit?.name }}</span
        </strong>
      </div>

      <div class="col-1 my-auto">
        <button
          class="btn btn-primary float-end"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#bestaendeCollapse-' + artikelnummer"
          :aria-expanded="false"
          :aria-controls="'bestaendeCollapse-' + artikelnummer"
        >
          <font-awesome-icon icon="magnifying-glass-plus" />
        </button>
      </div>
    </div>

    <div class="collapse p-3 border-top mt-3 bestand-collapse-bg" :id="'bestaendeCollapse-' + artikelnummer">
      <div class="row mx-1 my-3">
        <div class="col-1 fw-bold">Art Nr.</div>
        <div class="col-2 fw-bold">Artikel</div>
        <div class="col-2 fw-bold">Barcode</div>
        <!-- <div class="col-1 fw-bold">Anzahl</div>
        <div class="col-1 fw-bold">Einheit</div> -->

        <div class="col-2 fw-bold">Anzahl/Einheit</div>

        <div class="col-1 fw-bold">Lager</div>
        <div class="col-1 fw-bold">Lagerbereich</div>
        <div class="col-1 fw-bold">Reihe</div>
        <div class="col-1 fw-bold">Platz</div>
        <div class="col-1 fw-bold"></div>
      </div>

      <BestandRow v-for="bestand in bestaende" :key="bestand.id" :bestand="bestand" />
    </div>
  </div>
</template>

<script lang="ts" setup>
//#region Imports
  import { PropType, computed } from 'vue';
  import { useStore } from '@/store';
  
  import BestandRow from './BestandRow.vue';
  
  import { Artikel } from '@/models/ArtikelModels';
  import { ArtikelBestand } from '@/models/BestandModels';
//#endregion

    const props = defineProps({
      artikelnummer: {
        type: String,
        required: true,
      },

      bestaende: {
        type: Object as PropType<Array<ArtikelBestand>>,
        required: true,
      },
    });

      const store = useStore();

      const artikel = computed(() => store.getters.artikel.find((a: Artikel) => a.artikelNr == props.artikelnummer));

      const gesamtBestand = computed(() => {
        let anzahl = 0;

        for (var bestand of props.bestaende) {
          anzahl += bestand.anzahl;
        }

        return anzahl;
      });
</script>

<style scoped>
  .bestand-collapse-bg {
    background: rgb(242, 243, 248);
  }
</style>
