<template>
  <div class="row py-3 mb-2 mx-1 bg-white shadow-sm auftrag-row">
    <div class="col-1 my-auto">{{ artikelbestand.artikelnummer }}</div>
    <div class="col-3 my-auto">{{ artikelbestand.artikeltitel }}</div>
    <div class="col-1 my-auto">{{ umfuhrpos.anzahl }}</div>
    <div class="col-1 my-auto">{{ artikelbestand.einheit}}</div>
    <div class="col-1 my-auto">{{ artikelbestand.barcode }}</div>
    <div class="col-2 my-auto">{{ lagerort?.name }}</div>
    <div class="col-1 my-auto">{{ lagerbereich?.name }}</div>
    <div class="col-1 my-auto">{{ lagerreihe?.reihe }}</div>
    <div class="col-1 my-auto">{{ lagerplatz?.platz }}</div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { Lagerort, Lagerbereich, Lagerreihe, Lagerplatz } from '@/models/LagerModels';
import { UmfuhrPos } from "@/models/UmfuhrModels";
import { ArtikelBestand } from "@/models/BestandModels";

export default defineComponent({
  name: "AuftragPosRow",

  props: {
    umfuhrpos: {
      type: Object as PropType<UmfuhrPos>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    // const router = useRouter()

    const artikelbestand = computed(() => store.getters.bestand.find((a: ArtikelBestand) => a.id == props.umfuhrpos.artikelbestandid))
    const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == artikelbestand.value?.lagerortid))
    const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == artikelbestand.value?.lagerbereichid))
    const lagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == artikelbestand.value?.lagerreiheid))
    const lagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == artikelbestand.value?.lagerplatzid))

    return {
      // router,
      artikelbestand,
      lagerort,
      lagerbereich,
      lagerreihe,
      lagerplatz,
    }
  },
})

</script>

<style scoped>
.kunde-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
}
</style>