<template>
  <h1>Artikel bearbeiten</h1>
  <LoadingSpinner v-if="loading || deleteLoading" text="... die Daten werden bearbeitet ..." />

  <div v-else-if="!loading && artikeltemplate == null" class="alert alert-warning">
    <router-link :to="{ name: 'Artikeltemplates' }" class="btn btn-outline-secondary my-auto me-4">Zurück</router-link>
    <strong>Ungültiger Artikel</strong>
  </div>

  <div v-else>
    <form @submit.prevent="updateCurrentArtikeltemplate">
      <h3>Template</h3>
      <div class="mb-3">
        <label for="artikel-titel" class="form-label">Titel</label>
        <input type="text" v-model="updateArtikeltemplate.template.titel" class="form-control" id="artikel-titel" />
      </div>

      <div class="mb-3">
        <label for="artikel-beschreibung" class="form-label">Beschreibung</label>
        <textarea class="form-control" v-model="updateArtikeltemplate.template.beschreibung" id="artikel-beschreibung" rows="3"></textarea>
      </div>

      <hr class="my-3" />

      <h3>Artikel Template Definition</h3>

      <div class="row mb-3">
        <div class="col-4">
          <label for="artikelart" class="form-label">Artikelart auswählen</label>
          <select class="form-select" id="artikel-template" v-model="updateArtikeltemplate.template.artikelartid">
            <option v-for="artikelart in artikelarten" :key="artikelart.id" :value="artikelart.id">
              {{ artikelart.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-3">
        <label for="artikel-titel" class="form-label">ArtikelTitel</label>
        <input type="text" v-model="updateArtikeltemplate.template.artikeltitel" class="form-control" id="artikel-titel" />
      </div>

      <div class="mb-3">
        <label for="artikel-nr" class="form-label">Artikelnummer</label>
        <input type="text" v-model="updateArtikeltemplate.template.artikelnummer" class="form-control" id="artikel-nr" />
      </div>

      <div class="mb-3">
        <label for="anzahl" class="form-label">Anzahl</label>
        <input type="text" v-model="updateArtikeltemplate.template.anzahl" class="form-control" id="anzahl" />
      </div>

      <div class="mb-3">
        <select class="form-select" v-model="updateArtikeltemplate.template.einheitid">
          <option v-for="ae in artikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="artikel-beschreibung" class="form-label">Artikel Beschreibung</label>
        <textarea class="form-control" v-model="updateArtikeltemplate.template.artikelbeschreibung" id="artikel-beschreibung" rows="3"></textarea>
      </div>

      <p class="fw-bold">Bestand Einstellung</p>
      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" v-model="updateArtikeltemplate.template.inProdZusammenfuehren" id="in-prod-zusammenfuehren" />
        <label class="form-check-label" for="in-prod-zusammenfuehren"> Bestand in Produktion zusammenführen </label>
      </div>

      <hr class="my-3" />

      <!-- EIGENSCHAFTEN -->
      <div class="mb-3">
        <p class="fw-bold">Eigenschaften</p>

        <div class="row w-50 mb-3">
          <div class="col-3">
            <div>
              <span>Eigenschaft</span>
            </div>
          </div>

          <div class="col-7">
            <div>
              <label class="form-label">Wert</label>
            </div>
          </div>

          <div class="col-2"></div>
        </div>

        <div v-for="(eigenschaft, index) in updateArtikeltemplate.template.eigenschaften" :key="index">
          <div class="row w-50 mb-3">
            <div class="col-3">
              <div>
                <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required />
              </div>
            </div>

            <div class="col-7">
              <div>
                <input type="text" v-model="eigenschaft.value" class="form-control" :id="'value-' + index" placeholder="Rot" required />
              </div>
            </div>
            <div class="col-2">
              <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEigenschaft(index)">
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>

        <div>
          <button type="button" class="btn btn-secondary" @click="addEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
        </div>
      </div>

      <hr class="my-3" />

      <!-- Tags -->
      <div class="my-3">
        <p class="fw-bold">Tags</p>

        <div class="d-flex flex-row mb-3 flex-wrap">
          <p v-for="tag in updateArtikeltemplate.template.tags" :key="tag.tagid" class="lead me-2">
            <span class="badge text-bg-primary">{{ tags.find((t) => t.id == tag.tagid)?.key }}</span>
          </p>
        </div>

        <ArtikeltemplateTagSelectModal :templateTags="updateArtikeltemplate.template.tags.map((t) => t.tagid)" @set-selected-tags="setSelectedTags" />
      </div>

      <!-- VARIANTENEIGENSCHAFTEN -->
      <div class="mb-3" v-if="updateArtikeltemplate.template.artikelartid == 2">
        <hr class="my-3" />

        <p class="fw-bold">Varianten</p>

        <div class="mb-3">
          <p class="fw-bold mb-3">Varianten Eigenschaften</p>

          <div class="row w-50 mb-3">
            <div class="col-4">
              <div>
                <span>Eigenschaft</span>
              </div>
            </div>

            <div class="col-8">
              <div>
                <label class="form-label">Werte</label>
              </div>
            </div>
          </div>

          <div v-for="(eigenschaft, index) in updateArtikeltemplate.variantenEigenschaften" :key="index">
            <div class="row mb-4">
              <div class="col-2">
                <div>
                  <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required />
                </div>
              </div>

              <div class="col-8">
                <div class="row">
                  <div class="col-5">Wert</div>
                  <div class="col-5">Artikelnummer Erweiterung</div>
                  <div class="col-2"></div>
                </div>

                <div class="row mb-2" v-for="(wert, windex) in eigenschaft.values" :key="'key-' + index + '-' + windex">
                  <div class="col-5">
                    <input type="text" class="form-control" v-model="wert.value" placeholder="Rot" required />
                  </div>

                  <div class="col-5">
                    <input type="text" class="form-control" v-model="wert.artikelnummerextension" placeholder="04" required />
                  </div>

                  <div class="col-2">
                    <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVarianteEigenschaftValue(index, windex)">
                      <font-awesome-icon icon="trash" />
                    </button>
                  </div>
                </div>

                <div>
                  <button type="button" class="btn btn-outline-secondary" @click="addVarianteEigenschaftValue(index)">
                    <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
                  </button>
                </div>
              </div>

              <div class="col-2">
                <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVarianteEigenschaft(index)">
                  <font-awesome-icon icon="trash" />
                </button>
              </div>
            </div>
          </div>

          <div>
            <button type="button" class="btn btn-secondary" @click="addVarianteEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
          </div>
        </div>
      </div>

      <hr class="my-3" />

      <div v-if="errorMsg != ''" class="alert alert-danger">Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.</div>

      <div class="d-flex justify-content-end py-3">
        <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
        <button type="submit" class="btn btn-success">Änderungen speichern</button>
      </div>
    </form>

    <hr class="my-5" />

    <p class="fw-bold">Artikeltemplate löschen</p>
    <button type="button" class="btn btn-danger" @click="deleteCurrentArtikeltemplate"><font-awesome-icon icon="trash" /> Artikeltemplate löschen</button>
  </div>
</template>

<script lang="ts" setup>
  import { computed, onMounted, reactive } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { ActionTypes } from '@/store/modules/Artikeltemplates/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { v4 as uuidv4 } from 'uuid';

  import { EditVariantenEigenschaft, EditVariantenEigenschaftValue } from '@/models/ArtikelModels';
  import { Artikeltemplate, ArtikeltemplateEigenschaft, ArtikeltemplateVariantenEigenschaft, ArtikeltemplateTag } from '@/models/ArtikeltemplateModels';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import ArtikeltemplateTagSelectModal from '@/components/Artikeltemplates/ArtikeltemplateTagSelectModal.vue';

  const { id } = defineProps({
    id: {
      type: String,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const artikeltemplate = computed(() => store.getters.artikeltemplates.find((a) => a.id === parseInt(id)));
  const artikelarten = computed(() => store.getters.artikelarten);
  const artikeleinheiten = computed(() => store.getters.artikeleinheiten);

  const tags = computed(() => store.getters.tags);

  const loading = computed(() => store.getters.status.updateArtikeltemplateLoading);
  const deleteLoading = computed(() => store.getters.status.deleteArtikeltemplateLoading);
  const errorMsg = computed(() => store.getters.status.artikeltemplateErrorMsg);

  const updateArtikeltemplate = reactive({
    template: new Artikeltemplate(),
    variantenEigenschaften: new Array<EditVariantenEigenschaft>(),
    defaulteinheit: -1,
  });

  if (artikeltemplate.value != null) {
    updateArtikeltemplate.template = JSON.parse(JSON.stringify(artikeltemplate.value));
    setVariantenEigenschaften();
  }

  function setSelectedTags(data: Array<number>) {
    const newTags = new Array<ArtikeltemplateTag>();
    for (var value of data) {
      const index = updateArtikeltemplate.template.tags.findIndex((t) => t.tagid == value);
      if (index == -1) {
        const newValue = new ArtikeltemplateTag();
        newValue.artikeltemplateid = updateArtikeltemplate.template.id;
        newValue.tagid = value;
        newTags.push(newValue);
      } else {
        newTags.push(updateArtikeltemplate.template.tags[index]);
      }
    }

    updateArtikeltemplate.template.tags = newTags;
  }

  //#region Eigenschaften
  function addEigenschaft() {
    updateArtikeltemplate.template.eigenschaften.push(new ArtikeltemplateEigenschaft());
  }

  function removeEigenschaft(index: number) {
    updateArtikeltemplate.template.eigenschaften.splice(index, 1);
  }

  function setVariantenEigenschaften() {
    const uniquekeys: Array<string> = [
      ...new Set<string>(updateArtikeltemplate.template.variantenEigenschaften.map((i: ArtikeltemplateVariantenEigenschaft) => i.key)),
    ];

    for (var key of uniquekeys) {
      const eve = new EditVariantenEigenschaft(key);
      const eigenschaften = updateArtikeltemplate.template.variantenEigenschaften.filter((e: ArtikeltemplateVariantenEigenschaft) => e.key == key);
      for (var eigenschaft of eigenschaften) {
        eve.values.push(new EditVariantenEigenschaftValue(eigenschaft.identifier, eigenschaft.value, eigenschaft.artikelnummerextension, eigenschaft.id));
      }

      updateArtikeltemplate.variantenEigenschaften.push(eve);
    }
  }

  function addVarianteEigenschaft() {
    updateArtikeltemplate.variantenEigenschaften.push(new EditVariantenEigenschaft(''));
  }

  function removeVarianteEigenschaft(index: number) {
    updateArtikeltemplate.variantenEigenschaften.splice(index, 1);
  }

  function addVarianteEigenschaftValue(varianteindex: number) {
    updateArtikeltemplate.variantenEigenschaften[varianteindex].values.push(new EditVariantenEigenschaftValue(uuidv4(), '', ''));
  }

  function removeVarianteEigenschaftValue(varianteindex: number, index: number) {
    updateArtikeltemplate.variantenEigenschaften[varianteindex].values.splice(index, 1);
  }
  //#endregion

  function clearData() {
    if (artikeltemplate.value != null) {
      updateArtikeltemplate.template = JSON.parse(JSON.stringify(artikeltemplate.value));
    }
  }

  function cancel() {
    clearData();
    router.push({
      name: 'ArtikeltemplateDetails',
      params: { id: id },
    });
  }

  function updateCurrentArtikeltemplate() {
    if (artikeltemplate.value != null) {
      // update varianteneigenschaften
      if (updateArtikeltemplate.variantenEigenschaften.length > 0) {
        const varianteneigenschaften = new Array<ArtikeltemplateVariantenEigenschaft>();
        for (var eigenschaft of updateArtikeltemplate.variantenEigenschaften) {
          for (var value of eigenschaft.values) {
            varianteneigenschaften.push(
              new ArtikeltemplateVariantenEigenschaft(
                value.id,
                updateArtikeltemplate.template.id,
                value.identifier,
                eigenschaft.key,
                value.value,
                value.artikelnummerextension
              )
            );
          }
        }

        updateArtikeltemplate.template.variantenEigenschaften = varianteneigenschaften;
      }

      store.dispatch(ActionTypes.UpdateArtikeltemplate, updateArtikeltemplate.template).then(() => {
        if (errorMsg.value == '') {
          cancel();
        }
      });
    }
  }

  function deleteCurrentArtikeltemplate() {
    if (artikeltemplate.value != null) {
      store.dispatch(ActionTypes.DeleteArtikeltemplate, artikeltemplate.value.id).then(() => {
        if (errorMsg.value == '') {
          clearData();
          router.push({ name: 'Artikeltemplates' });
        }
      });
    }
  }

  onMounted(() => {
    if (artikelarten.value.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikelarten, undefined);
    }

    if (artikeleinheiten.value.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikelEinheit, undefined);
    }
  });
</script>

<style scoped>
  .footerbar {
    position: absolute;
    bottom: 0;
  }
</style>
