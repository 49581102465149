<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm auftrag-row">
    <div class="col-2 my-auto">
      {{ auftrag.id }}
    </div>

    <div class="col-2 my-auto">
      {{ kunde?.firma }}
    </div>

    <div class="col-3 my-auto">{{ kunde?.vorname }} {{ kunde?.name }}</div>

    <div class="col-2 my-auto">
      {{ auftragstatus?.status }}
    </div>

    <div class="col-2 my-auto">
      {{ auftrag.auslieferungsdatum ? dateToDateConverter(auftrag.auslieferungsdatum) : '-' }}
    </div>

    <div class="col-1 my-auto">
      <button class="btn btn-primary m-0 float-end" @click="openAutragDetails()">
        <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { useDateConverter } from '@/utils/date_converter';

  import { Auftrag, AuftragStatus } from '@/models/AuftragModels';
  import { Kunde } from '@/models/KundeModels';

  const { auftrag } = defineProps({
    auftrag: {
      type: Object as PropType<Auftrag>,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const { dateToDateConverter } = useDateConverter();

  const kunde = computed(() => store.getters.kunden.find((k: Kunde) => k.id == auftrag.kundeid));
  const auftragstatus = computed(() => store.getters.auftragstatus.find((p: AuftragStatus) => p.id == auftrag.auftragStatusid));

  function openAutragDetails() {
    router.push({ name: 'DetailsAuftrag', params: { id: auftrag.id } });
  }
</script>

<style scoped>
  .kunde-row:hover {
    cursor: pointer;
    color: #2a579b;
    background-color: #dfe9f8 !important;
  }
</style>
