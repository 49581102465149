<template>
  <div v-if="auftrag == null" class="alert alert-danger">Ungültige Auftrag Nr.: {{ id }}</div>

  <div v-else>
    <div class="d-flex justify-content-between">
      <h1>Auftrag {{ auftrag.id }} bearbeiten</h1>
    </div>

    <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

    <form v-else @submit.prevent="updateAuftrag()" autocomplete="off">
      <h3>Status</h3>
      <div class="row mb-3">
        <div class="col-3">
          <select class="form-select" v-model="editAuftrag.auftragStatusid">
            <option :value="0">Auftragstatus festlegen</option>
            <option v-for="status in auftragstatus" :key="status.id" :value="status.id">{{ status.status }}</option>
          </select>
        </div>
      </div>

      <h3>Kunde</h3>
      <div class="row mb-3">
        <div class="col">
          <label for="kunde-nr" class="form-label">Kunden Nr.</label>
          <AutocompleteInputSelect :id="'kunde-nr'" ref="kundeIdInputRef" :select-list="kundenNrList" :inputValue="kundeInput.id" @set-value="setKundeById" />
        </div>

        <div class="col">
          <label for="kunde-firma" class="form-label">Firma</label>
          <AutocompleteInputSelect
            :id="'kunde-firma'"
            ref="kundeFirmaInputRef"
            :select-list="kundenFirmaList"
            :inputValue="kundeInput.firma"
            @set-value="setKundeByFirma"
          />
        </div>

        <div class="col">
          <label for="kunde-name" class="form-label">Name</label>
          <AutocompleteInputSelect
            :id="'kunde-name'"
            ref="kundeNameInputRef"
            :select-list="kundenNameList"
            :inputValue="kundeInput.name"
            @set-value="setKundeByName"
          />
        </div>
      </div>

      <hr class="my-4" />

      <h3>Auslieferung</h3>
      <div class="row mb-3">
        <div class="col-3">
          <label for="date-input" class="form-label">Auslieferungsdatum</label>
          <input type="date" class="form-control" id="date-input" v-model="auslieferdatumDateConverter" />
        </div>

        <div class="col-3">
          <label for="lagerort-input" class="form-label">Lagerort</label>
          <AutocompleteInputSelect
            id="lagerort-input"
            ref="lagerortInputRef"
            :select-list="lagerorteNameList"
            :inputValue="selectedLagerort.ort"
            @set-value="setLagerortByName"
          />
        </div>

        <div class="col-3" v-if="editAuftrag.lagerortid != null && editAuftrag.lagerortid != 0">
          <label for="lagerbereich-input" class="form-label">Lagerbereich</label>
          <AutocompleteInputSelect
            id="lagerbereich-input"
            ref="lagerbereichInputRef"
            :select-list="lagerbereicheNameList"
            :inputValue="selectedLagerort.bereich"
            @set-value="setLagerbereichByName"
          />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-3">
          <label for="liefer-date-input" class="form-label">Lieferdatum</label>
          <input type="date" class="form-control" id="liefer-date-input" v-model="lieferdatumDateConverter" />
        </div>

        <div class="col-3">
          <label for="rechnung-date-input" class="form-label">Rechnungsdatum</label>
          <input type="date" class="form-control" id="rechnung-date-input" v-model="rechnungsdatumDateConverter" />
        </div>
      </div>

      <hr class="my-4" />

      <div class="mb-3">
        <label for="Notizen" class="form-label">Notizen</label>
        <textarea class="form-control" rows="3" v-model="editAuftrag.notizen"></textarea>
      </div>

      <h3>Positionen</h3>

      <div class="row mb-2">
        <div class="col fw-bold">ArtikelNr</div>
        <div class="col fw-bold">Artikel</div>
        <div class="col fw-bold">Anzahl</div>
        <div class="col fw-bold">Einheit</div>
      </div>

      <EditAuftragPosRow v-for="(pos, index) in editPositionen" :key="index" :pos="pos" :index="index" />

      <button type="button" class="btn btn-secondary" @click="addPosition()"><font-awesome-icon icon="plus" /> Position hinzufügen</button>

      <div v-if="!loading && errorMsg != ''" class="alert alert-danger mt-5">
        {{ errorMsg }}
      </div>

      <div class="d-flex justify-content-end py-3">
        <button type="button" @click="router.back()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
        <button type="submit" class="btn btn-success">Speichern</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, reactive, onMounted, ref } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { Auftrag, AuftragPos, EditAuftragPos } from '@/models/AuftragModels';
  import { Kunde } from '@/models/KundeModels';
  import { Lagerort, Lagerbereich } from '@/models/LagerModels';

  import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';
  import { ActionTypes as KundeActionTypes } from '@/store/modules/Kunde/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
  import EditAuftragPosRow from '@/components/Auftrag/EditAuftragPosRow.vue';
  import { Artikel } from '@/models/ArtikelModels';
  //#endregion

  const { id } = defineProps({
    id: {
      type: String,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const loading = computed(() => store.getters.status.createAuftragLoading);
  const errorMsg = computed(() => store.getters.status.auftragErrorMsg);

  // const artikel = computed(() => store.getters.artikel);

  const auftrag = computed(() => store.getters.auftraege.find((a: Auftrag) => a.id == parseInt(id)));
  const editAuftrag = ref(auftrag.value != null ? (JSON.parse(JSON.stringify(auftrag.value)) as Auftrag) : new Auftrag());

  const auftragstatus = computed(() => store.getters.auftragstatus);

  //#region Datumsfelder
  const auslieferdatumDateConverter = computed({
    get() {
      if (editAuftrag.value.auslieferungsdatum) {
        const datum = new Date(editAuftrag.value.auslieferungsdatum);
        return datum.getFullYear() + '-' + ('0' + (datum.getMonth() + 1)).slice(-2) + '-' + ('0' + datum.getDate()).slice(-2);
      } else {
        return '';
      }
    },

    set(newValue) {
      if (newValue == '') {
        newValue = new Date().toISOString();
      }
      editAuftrag.value.auslieferungsdatum = new Date(newValue);
    },
  });

  const lieferdatumDateConverter = computed({
    get() {
      if (editAuftrag.value.lieferdatum) {
        const datum = new Date(editAuftrag.value.lieferdatum);
        return datum.getFullYear() + '-' + ('0' + (datum.getMonth() + 1)).slice(-2) + '-' + ('0' + datum.getDate()).slice(-2);
      } else {
        return '';
      }
    },

    set(newValue) {
      if (newValue == '') {
        newValue = new Date().toISOString();
      }
      editAuftrag.value.lieferdatum = new Date(newValue);
    },
  });

  const rechnungsdatumDateConverter = computed({
    get() {
      if (editAuftrag.value.rechnungsdatum) {
        const datum = new Date(editAuftrag.value.rechnungsdatum);
        return datum.getFullYear() + '-' + ('0' + (datum.getMonth() + 1)).slice(-2) + '-' + ('0' + datum.getDate()).slice(-2);
      } else {
        return '';
      }
    },

    set(newValue) {
      if (newValue == '') {
        newValue = new Date().toISOString();
      }
      editAuftrag.value.rechnungsdatum = new Date(newValue);
    },
  });
  //#endregion

  //#region Lager
  const lagerorte = computed(() => store.getters.lagerorte);
  const lagerorteNameList = computed(() => lagerorte.value.map((obj: Lagerort) => obj['name']));

  const lagerbereiche = computed(() => store.getters.lagerbereiche.filter((l: Lagerbereich) => l.lagerortid == editAuftrag.value.lagerortid));
  const lagerbereicheNameList = computed(() => lagerbereiche.value.map((obj: Lagerbereich) => obj['name']));

  const selectedLagerort = reactive({ ort: '', bereich: '' });
  const lagerortInputRef = ref();
  const lagerbereichInputRef = ref();

  function initLager(lagerortid: number | null, lagerbereichid: number | null) {
    if (lagerortid) {
      const lagerort = lagerorte.value.find((l: Lagerort) => l.id == lagerortid);
      if (lagerort != null) {
        lagerortInputRef.value.inputvalue = lagerort.name;
      }
    }

    if (lagerbereichid) {
      const lagerbereich = lagerbereiche.value.find((l: Lagerbereich) => l.id == lagerbereichid);
      if (lagerbereich != null) {
        lagerbereichInputRef.value.inputvalue = lagerbereich.name;
      }
    }
  }

  function setLagerortByName(name: string) {
    const lagerort = lagerorte.value.find((l: Lagerort) => l.name == name);
    if (lagerort != null) {
      editAuftrag.value.lagerortid = lagerort.id;
    }
  }

  function setLagerbereichByName(name: string) {
    const lagerbereich = lagerbereiche.value.find((l: Lagerbereich) => l.name == name);
    if (lagerbereich != null) {
      editAuftrag.value.lagerbereichid = lagerbereich.id;
    }
  }
  //#endregion

  function updateAuftrag() {
    editAuftrag.value.positionen = new Array<AuftragPos>();

    for (var pos of editPositionen.value) {
      var artikel = store.getters.artikel.find((a: Artikel) => a.artikelNr == pos.artikelnr);
      if (artikel) {
        var newpos = new AuftragPos();
        newpos.id = pos.id;
        newpos.auftragid = editAuftrag.value.id;
        newpos.position = pos.position;
        newpos.anzahl = pos.anzahl;
        newpos.einheitid = pos.einheitid;

        newpos.artikelid = artikel.id;

        var auftragPos = auftrag.value?.positionen.find((p: AuftragPos) => p.id == pos.id);
        if (auftragPos) {
          newpos.anzahlVersandt = auftragPos.anzahlVersandt;
        }

        editAuftrag.value.positionen.push(newpos);
      }
    }

    if (auftrag.value) {
      store.dispatch(AuftragActionTypes.UpdateAuftrag, editAuftrag.value).then(() => {
        if (errorMsg.value == '') {
          returnToMain();
        }
      });
    }
  }

  function returnToMain() {
    clearInputs();
    router.back();
    // router.push({ name: 'Auftraege' });
  }

  function clearInputs() {
    editAuftrag.value = auftrag.value != null ? JSON.parse(JSON.stringify(auftrag.value)) : new Auftrag();
    editPositionen.value = new Array<EditAuftragPos>();
  }

  //#region Kunde
  const kunden = computed(() => store.getters.kunden);
  const kundenNrList = computed(() => kunden.value.map((obj: Kunde) => obj['id'].toString()));
  const kundenFirmaList = computed(() => kunden.value.map((obj: Kunde) => obj['firma']));
  const kundenNameList = computed(() => kunden.value.map((obj: Kunde) => obj['vorname'] + ' ' + obj['name']));

  const kundeInput = reactive({
    id: '',
    firma: '',
    name: '',
  });

  const kundeIdInputRef = ref();
  const kundeFirmaInputRef = ref();
  const kundeNameInputRef = ref();

  function initKundeById(id: string) {
    const kunde = kunden.value.find((k: Kunde) => k.id == parseInt(id));

    if (kunde != null) {
      kundeIdInputRef.value.inputvalue = kunde.id.toString();
      kundeFirmaInputRef.value.inputvalue = kunde.firma;
      kundeNameInputRef.value.inputvalue = `${kunde.vorname} ${kunde.name}`;
    }
  }

  function setKundeById(id: string) {
    const kunde = kunden.value.find((k: Kunde) => k.id == parseInt(id));

    if (kunde != null) {
      setKunde(kunde);
      kundeFirmaInputRef.value.inputvalue = kunde.firma;
      kundeNameInputRef.value.inputvalue = `${kunde.vorname} ${kunde.name}`;
    }
  }

  function setKundeByFirma(firma: string) {
    const kunde = kunden.value.find((k: Kunde) => k.firma == firma);

    if (kunde != null) {
      setKunde(kunde);
      kundeIdInputRef.value.inputvalue = kunde.id.toString();
      kundeNameInputRef.value.inputvalue = `${kunde.vorname} ${kunde.name}`;
    }
  }

  function setKundeByName(name: string) {
    const kunde = kunden.value.find((k: Kunde) => `${k.vorname} ${k.name}` == name);

    if (kunde != null) {
      setKunde(kunde);
      kundeIdInputRef.value.inputvalue = kunde.id.toString();
      kundeFirmaInputRef.value.inputvalue = kunde.firma;
    }
  }

  function setKunde(kunde: Kunde) {
    editAuftrag.value.kundeid = kunde.id;
  }
  //#endregion

  //#region Positionen
  const editPositionen = ref(new Array<EditAuftragPos>());

  function initPositionen() {
    for (var pos of editAuftrag.value.positionen) {
      const newEditPos = new EditAuftragPos(pos.position);
      const art = store.getters.artikel.find((a: Artikel) => a.id == pos.artikelid);
      newEditPos.init(pos, art ? art.artikelNr : '', art ? art.titel : '');
      editPositionen.value.push(newEditPos);
    }
  }

  function addPosition() {
    editPositionen.value.push(new EditAuftragPos(editPositionen.value.length));
  }
  //#endregion

  function loadData() {
    if (store.getters.auftragstatus.length == 0) {
      store.dispatch(AuftragActionTypes.GetAuftragStatus, undefined);
    }

    if (store.getters.kunden.length == 0) {
      store.dispatch(KundeActionTypes.GetKunde, undefined);
    }

    if (store.getters.artikel.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikel, undefined);
    }
  }

  onMounted(() => {
    loadData();
    initKundeById(editAuftrag.value.kundeid.toString());
    initLager(editAuftrag.value.lagerortid, editAuftrag.value.lagerbereichid);
    initPositionen();
  });
</script>
