<template>
  <div class="row mb-3">
    <div class="col-3">
      <AutocompleteInputSelect
        :id="'position-artikelnr-' + index"
        ref="positionArtikelnrInputRef"
        :select-list="artikelNummerList"
        :inputValue="artikelnummer"
        :index="index"
        @set-value="setPositionByArtikelnr"
      />
    </div>

    <div class="col">
      <AutocompleteInputSelect
        :id="'position-artikeltitel-' + index"
        ref="positionArtikeltitelInputRef"
        :select-list="artikelTitelList"
        :inputValue="artikeltitel"
        :index="index"
        @set-value="setPositionByArtikeltitel"
      />
    </div>

    <div class="col-3">
      <div class="input-group">
        <input type="number" class="form-control" :id="'pos-anzahl-' + index" v-model="pos.anzahl" :aria-describedby="'pos-anzahl-einheit-' + index" />
        <span class="input-group-text" :id="'pos-anzahl-einheit-' + index">{{ getEinheitName(pos.einheitid) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed, ref } from 'vue';
  import { useStore } from '@/store';

  import { Artikel } from '@/models/ArtikelModels';
  import { LieferungPos } from '@/models/LieferungModels';

  import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
  //#endregion

  const props = defineProps({
    pos: {
      type: Object as PropType<LieferungPos>,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  });

  const store = useStore();

  const artikelnummer = ref('');
  const artikeltitel = ref('');

  const artikel = computed(() => store.getters.artikel.filter((a: Artikel) => a.artikelartid != 2));
  const artikelTitelList = computed(() => artikel.value.map((obj: Artikel) => obj['titel']));
  const artikelNummerList = computed(() => artikel.value.map((obj: Artikel) => obj['artikelNr']));

  function getEinheitName(einheitid: number) {
    const einheit = store.getters.artikeleinheiten.find((e: any) => e.id == einheitid);
    return einheit != null ? einheit.name : '-';
  }

  const positionArtikeltitelInputRef = ref();
  const positionArtikelnrInputRef = ref();

  function setPositionByArtikelnr(artikelnummer: string) {
    const art = artikel.value.find((a: Artikel) => a.artikelNr == artikelnummer);

    if (art != null) {
      setPositionArtikel(art);
      (positionArtikeltitelInputRef.value as typeof AutocompleteInputSelect).inputvalue = art.titel;
    }
  }

  function setPositionByArtikeltitel(artikeltitel: string) {
    const art = artikel.value.find((a: Artikel) => a.titel == artikeltitel);

    if (art != null) {
      setPositionArtikel(art);
      (positionArtikelnrInputRef.value as typeof AutocompleteInputSelect).inputvalue = art.artikelNr;
    }
  }

  function setPositionArtikel(artikel: Artikel) {
    console.log('setPositionArtikel');
    props.pos.artikelid = artikel.id;

    artikelnummer.value = artikel.artikelNr;
    artikeltitel.value = artikel.titel;

    props.pos.anzahl = artikel.anzahl;
    props.pos.einheitid = artikel.einheitid || 0;
  }
</script>
