import { Auftrag, AuftragStatus } from '@/models/AuftragModels';

export type State = {
  auftraege: Array<Auftrag>;
  auftragstatus: Array<AuftragStatus>;
};

export const state: State = {
  auftraege: sessionStorage.getItem('auftraege') != null ? JSON.parse(sessionStorage.auftraege) : new Array<Auftrag>(),
  auftragstatus: sessionStorage.getItem('auftragstatus') != null ? JSON.parse(sessionStorage.auftragstatus) : new Array<AuftragStatus>(),
};
