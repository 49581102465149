import { RootState } from '@/store';

import { GetterTree } from 'vuex';
import { State } from './state';
import { Auftrag, AuftragStatus } from '@/models/AuftragModels';

export type Getters = {
  auftraege(state: State): Array<Auftrag>;
  auftragstatus(state: State): Array<AuftragStatus>;
};

export const getters: GetterTree<State, RootState> & Getters = {
  auftraege(state: State) {
    return state.auftraege;
  },

  auftragstatus(state: State) {
    return state.auftragstatus;
  },
};
