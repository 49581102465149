<template>
  <div class="row mx-1 mb-1 py-3 shadow-sm" :class="{ 'bg-success text-white': pos.geliefert == 1, 'bg-white text-dark': pos.geliefert == 0 }">
    <div class="col-2 my-auto">{{ artikel?.artikelNr }}</div>
    <div class="col-5 my-auto">{{ artikel?.titel }}</div>
    <div class="col-2 my-auto">{{ pos.anzahl }}</div>
    <div class="col-3 my-auto">{{ artikel?.einheit?.name }}</div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed } from 'vue';
  import { useStore } from '@/store';

  import { Artikel } from '@/models/ArtikelModels';

  import { LieferungPos } from '@/models/LieferungModels';
  //#endregion

  const props = defineProps({
    pos: {
      type: Object as PropType<LieferungPos>,
      required: true,
    },
  });

  const store = useStore();

  const artikel = computed(() => store.getters.artikel.find((a: Artikel) => a.id == props.pos.artikelid));
</script>
