<template>
  <h1>Dashboard</h1>

  <LoadingOverlay v-if="loading" />
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, onMounted } from 'vue';
  import { useStore } from '@/store';

  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';
  import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';

  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  //#endregion

  const store = useStore();
  const loading = computed(
    () =>
      store.getters.status.getLagerortLoading ||
      store.getters.status.getLagerbereichLoading ||
      store.getters.status.getLagerreiheLoading ||
      store.getters.status.getLagerplatzLoading ||
      store.getters.status.getLagerbereichartLoading ||
      store.getters.status.getArtikelLoading ||
      store.getters.status.getBestandLoading
  );

  function loadWMSData() {
    // Lager
    if (store.getters.lagerorte.length === 0) {
      store.dispatch(LagerActionTypes.GetLagerorte, undefined);
    }

    if (store.getters.lagerbereiche.length === 0) {
      store.dispatch(LagerActionTypes.GetLagerbereiche, undefined);
    }

    if (store.getters.lagerreihen.length === 0) {
      store.dispatch(LagerActionTypes.GetLagerreihen, undefined);
    }

    if (store.getters.lagerplaetze.length === 0) {
      store.dispatch(LagerActionTypes.GetLagerplaetze, undefined);
    }

    if (store.getters.lagerbereicharten.length === 0) {
      store.dispatch(LagerActionTypes.GetLagerbereicharten, undefined);
    }
    // Artikel
    if (store.getters.artikel.length === 0) {
      store.dispatch(ArtikelActionTypes.GetArtikel, undefined);
    }

    // Bestand
    if (store.getters.bestand.length === 0) {
      store.dispatch(BestandActionTypes.GetBestand, undefined);
    }
  }

  onMounted(() => {
    loadWMSData();
  });
</script>
