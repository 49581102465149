export class Auftrag {
  id: number;
  kundeid: number;
  auftragStatusid: number;
  notizen: string;
  auslieferungsdatum: Date | null;
  lieferdatum: Date | null;
  rechnungsdatum: Date | null;
  lagerortid: number | null;
  lagerbereichid: number | null;
  isDeleted: number;
  positionen: Array<AuftragPos>;

  constructor() {
    this.id = 0;
    this.kundeid = 0;
    this.auftragStatusid = 1;
    this.notizen = '';
    this.auslieferungsdatum = null;
    this.lieferdatum = null;
    this.rechnungsdatum = null;
    this.lagerortid = null;
    this.lagerbereichid = null;
    this.isDeleted = 0;
    this.positionen = new Array<AuftragPos>();
  }
}

export class AuftragPos {
  id: number;
  auftragid: number;
  artikelid: number;
  position: number;
  anzahl: number;
  einheitid: number;
  anzahlVersandt: number;
  isDeleted: number;
  benutzerId: number;

  constructor() {
    this.id = 0;
    this.auftragid = 0;
    this.artikelid = 0;
    this.position = 1;
    this.anzahl = 0;
    this.einheitid = 0;
    this.anzahlVersandt = 0;
    this.isDeleted = 0;
    this.benutzerId = 0;
  }
}

export class EditAuftragPos {
  id: number;
  artikelnr: string;
  artikeltitel: string;
  anzahl: number;
  einheitid: number;
  position: number;

  constructor(position: number) {
    this.id = 0;
    this.artikelnr = '';
    this.artikeltitel = '';
    this.anzahl = 1;
    this.einheitid = 0;
    this.position = position;
  }

  init(data: AuftragPos, artikelnr: string, artikeltitel: string) {
    this.id = data.id;
    this.artikelnr = artikelnr;
    this.artikeltitel = artikeltitel;
    this.anzahl = data.anzahl;
    this.einheitid = data.einheitid;
    this.position = data.position;
  }
}

export class GetAuftraegeRequest {
  kundeid: string;
  auslieferungsdatum: string;
  lagerortid: string;
  lagerbereichid: string;

  constructor() {
    this.kundeid = '';
    this.auslieferungsdatum = '';
    this.lagerortid = '';
    this.lagerbereichid = '';
  }
}

export class AuftragStatus {
  id: number;
  status: string;

  constructor() {
    this.id = 0;
    this.status = '';
  }
}
