<template>
  <div
    class="row mx-1 mb-2 py-2 shadow-sm"
    :class="{
      'bg-white text-dark': lieferung.lieferungStatusid == 1,
      'bg-warning text-dark': lieferung.lieferungStatusid == 2,
      'bg-success text-white': lieferung.lieferungStatusid == 3,
    }"
  >
    <div class="col-1 my-auto">{{ lieferung.id }}</div>
    <div class="col-1 my-auto">{{ lieferung.lieferantid }}</div>
    <div class="col-3 my-auto">{{ lieferant?.firma }}</div>
    <div class="col-2 my-auto">{{ lagerort?.name }}</div>
    <div class="col-2 my-auto">{{ lagerbereich?.name }}</div>
    <div class="col-2 my-auto">{{ lieferdatumConverter }}</div>
    <div class="col-1 my-auto">
      <button class="btn btn-primary m-0 float-end dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <font-awesome-icon icon="pen-to-square" class="fa-sm" />
      </button>

      <ul class="dropdown-menu m-0 p-0 rounded-0">
        <li class="dropdown-item" role="button" @click="openWarenanlieferung()">Lieferung annehmen</li>
        <li class="dropdown-item" role="button" @click="openWarenzugangDetails()">Details</li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { Lieferung } from '@/models/LieferungModels';
  import { Lieferant } from '@/models/LieferantModels';
  import { Lagerbereich, Lagerort } from '@/models/LagerModels';
  //#endregion

  const props = defineProps({
    lieferung: {
      type: Object as PropType<Lieferung>,
      required: true,
    },
  });

  const router = useRouter();
  const store = useStore();

  const lieferant = computed(() => store.getters.lieferanten.find((l: Lieferant) => l.id == props.lieferung.lieferantid));

  const lieferdatumConverter = computed(() => {
    if (props.lieferung.lieferdatum == null) {
      return '';
    }
    const lieferdatum: Date = new Date(props.lieferung.lieferdatum.toString());

    return ('0' + lieferdatum.getDate()).slice(-2) + '.' + ('0' + (lieferdatum.getMonth() + 1)).slice(-2) + '.' + lieferdatum.getFullYear();
  });

  const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == props.lieferung.lagerortid));
  const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == props.lieferung.lagerbereichid));

  function openWarenzugangDetails() {
    router.push({ name: 'DetailsWarenzugang', params: { id: props.lieferung.id } });
  }

  function openWarenanlieferung() {
    router.push({ name: 'Warenanlieferung', params: { id: props.lieferung.id } });
  }
</script>
