<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm auftrag-row">
    <div class="col-2 my-auto">
      {{ kommission.id }}
    </div>

    <div class="col-2 my-auto">
      {{ kommission.auftragid }}
    </div>

    <div class="col-1 my-auto">
      {{ kommission.kommissionStatusid }}
    </div>
    
    <div class="col-2 my-auto">
      {{ kommission.bereitstellungsdatum }}
    </div>
    
    <div class="col-2 my-auto">
      {{ kommission.auslieferungsdatum }}
    </div>

    <div class="col-2"></div>

    <div class="col-1 my-auto">
      <button class="btn btn-primary m-0 float-end" @click="openKommissionDetails()">
        <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { Auftrag, AuftragStatus } from "@/models/AuftragModels";
import { Kunde } from "@/models/KundeModels";
import { Kommission, KommissionStatus } from "@/models/KommissionModels";

export default defineComponent({
  name: "KommissionRow",

  props: {
    kommission: {
      type: Object as PropType<Kommission>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const router = useRouter()

    // const kunde = computed(() => store.getters.kunden.find((k: Kunde) => k.id == props.auftrag.kundeid))
    const kommissionstatus = computed(() => store.getters.kommissiontatus.find((p: KommissionStatus) => p.id == props.kommission.kommissionStatusid))

    return {
      router,
      // kunde,
      kommissionstatus
    }
  },

  methods: {
    openKommissionDetails () {
      this.router.push({name: "DetailsKommission", params: {id: this.kommission.id}})
    }
  }
})

</script>

<style scoped>
.kunde-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
}
</style>