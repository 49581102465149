<template>
  <h1>Bestand hinzufügen</h1>

  <form @submit.prevent="createNeuerBestand" autocomplete="off">
    <div class="mb-3">
      <label for="artikel-titel" class="form-label">Artikel</label>
      <AutocompleteInputSelect
        :id="'artikel-titel'"
        ref="artikelTitelInputRef"
        :select-list="artikelNameList"
        :inputValue="selectedArtikel?.titel"
        @set-value="setArtikelByTitel"
      />
    </div>

    <div class="mb-3">
      <label for="artikel-nummer" class="form-label">Artikel Nr.</label>
      <AutocompleteInputSelect
        :id="'artikel-nummer'"
        ref="artikelnummerInputRef"
        :select-list="artikelNummerList"
        @set-value="setArtikelByArtikelnummer"
        :inputValue="selectedArtikel.artikelNr"
      />
    </div>

    <hr class="my-4" />

    <div class="mb-3">
      <label for="anzahl" class="form-label">Anzahl</label>

      <div class="input-group">
        <input type="number" v-model="neuerBestand.anzahl" class="form-control" id="anzahl" aria-describedby="einheit" />
        <span class="input-group-text" id="einheit">{{ selectedArtikel?.einheit?.name }}</span>
      </div>
    </div>

    <hr class="my-4" />

    <div class="mb-3">
      <label for="barcode" class="form-label">Barcode</label>
      <input type="text" class="form-control" id="barcode" v-model="neuerBestand.barcode" />
    </div>

    <div class="mb-3">
      <label for="lagerort" class="form-label">Lagerort</label>
      <select class="form-select" id="lagerorte" aria-label="Default select example" v-model="neuerBestand.lagerortid">
        <option :value="0">Lagerort auswählen...</option>
        <option v-for="lagerort in lagerorte" :key="lagerort.id" :value="lagerort.id">{{ lagerort.name }}</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="wareneingangbereiche" class="form-label">Wareneingangbereich</label>
      <select class="form-select" id="wareneingangbereiche" aria-label="Default select example" v-model="neuerBestand.lagerbereichid">
        <option :value="0">Wareneingangbereich auswählen...</option>
        <option v-for="lagerbereich in lagerbereiche" :key="lagerbereich.id" :value="lagerbereich.id">{{ lagerbereich.name }}</option>
      </select>
    </div>

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Hinzufügen</button>
    </div>
  </form>

  <LoadingOverlay v-if="loading" />
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';

  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';

  import { CreateArtikelBestandRequest } from '@/models/BestandModels';
  import { Artikel } from '@/models/ArtikelModels';
  import { Lagerbereich } from '@/models/LagerModels';
  //#endregion

  const store = useStore();
  const router = useRouter();

  const loading = computed(() => store.getters.status.createBestandLoading);
  const errorMsg = computed(() => store.getters.status.bestandErrorMsg);

  const neuerBestand = ref(new CreateArtikelBestandRequest());

  //#region Selection Data
  const lagerorte = computed(() => store.getters.lagerorte);
  const lagerbereiche = computed(() => store.getters.lagerbereiche.filter((e: Lagerbereich) => e.lagerortid == neuerBestand.value.lagerortid));

  const selectedArtikel = ref<Artikel>(new Artikel());
  const artikel = computed(() => store.getters.artikel.filter((a: Artikel) => a.artikelartid != 2));
  const artikelNameList = computed(() => artikel.value.map((obj: Artikel) => obj['titel']));
  const artikelNummerList = computed(() => artikel.value.map((obj: Artikel) => obj['artikelNr']));

  const artikelnummerInputRef = ref();
  const artikelTitelInputRef = ref();

  function setArtikelByTitel(artikeltitel: string) {
    selectedArtikel.value = new Artikel();
    const art = artikel.value.find((a: Artikel) => a.titel == artikeltitel);

    if (art != null) {
      setArtikel(art);
      (artikelnummerInputRef.value as typeof AutocompleteInputSelect).inputvalue = art.artikelNr;
    }
  }

  function setArtikelByArtikelnummer(artikelnummer: string) {
    selectedArtikel.value = new Artikel();
    const art = artikel.value.find((a: Artikel) => a.artikelNr == artikelnummer);

    if (art != null) {
      setArtikel(art);

      (artikelTitelInputRef.value as typeof AutocompleteInputSelect).inputvalue = art.titel;
    }
  }

  function setArtikel(art: Artikel) {
    selectedArtikel.value = art;
  }
  //#endregion

  function clearInputs() {
    neuerBestand.value = new CreateArtikelBestandRequest();
    selectedArtikel.value = new Artikel();
  }

  function cancel() {
    clearInputs();
    router.push({ name: 'Bestand' });
  }

  function createNeuerBestand() {
    if (selectedArtikel.value.id != 0) {
      neuerBestand.value.artikelid = selectedArtikel.value.id;
      neuerBestand.value.artikelnr = selectedArtikel.value.artikelNr;

      store.dispatch(BestandActionTypes.CreateArtikelBestand, neuerBestand.value).then(() => {
        if (errorMsg.value == '') {
          cancel();
        }
      });
    }
  }

  onMounted(() => {
    clearInputs();

    if (artikel.value.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikel, undefined);
    }

    if (lagerorte.value.length == 0) {
      store.dispatch(LagerActionTypes.GetLagerorte, undefined);
      store.dispatch(LagerActionTypes.GetLagerbereiche, undefined);
      store.dispatch(LagerActionTypes.GetLagerbereicharten, undefined);
      store.dispatch(LagerActionTypes.GetLagerreihen, undefined);
      store.dispatch(LagerActionTypes.GetLagerplaetze, undefined);
    }
  });
</script>
