import { MutationTree } from 'vuex';
import { State } from './state';
import { Artikel, ArtikelEinheit, Artikelart, Tag } from '@/models/ArtikelModels';

export enum MutationTypes {
  SetArtikel = 'SETARTIKEL',
  AddArtikel = 'ADDARTIKEL',
  AddOrUpdateArtikelRange = 'ADDORUPDATEARTIKELRANGE',
  UpdateArtikel = 'UPDATEARTIKEL',
  DeleteArtikel = 'DELETEARTIKEL',

  SetArtikelart = 'SETARTIKELART',

  SetArtikelEinheit = 'SETARTIKELEINHEIT',

  SetTag = 'SETTAG',
  AddTag = 'ADDTAG',
  UpdateTag = 'UPDATETAG',
  DeleteTag = 'DELETETAG',
}

export type Mutations = {
  [MutationTypes.SetArtikel](state: State, artikel: Array<Artikel>): void;
  [MutationTypes.AddArtikel](state: State, artikel: Artikel): void;
  [MutationTypes.AddOrUpdateArtikelRange](state: State, artikel: Array<Artikel>): void;
  [MutationTypes.UpdateArtikel](state: State, artikel: Artikel): void;
  [MutationTypes.DeleteArtikel](state: State, artikelid: number): void;

  [MutationTypes.SetArtikelart](state: State, data: Array<Artikelart>): void;

  [MutationTypes.SetArtikelEinheit](state: State, data: Array<ArtikelEinheit>): void;

  [MutationTypes.SetTag](state: State, tag: Array<Tag>): void;
  [MutationTypes.AddTag](state: State, tag: Tag): void;
  [MutationTypes.UpdateTag](state: State, tag: Tag): void;
  [MutationTypes.DeleteTag](state: State, tagid: number): void;
};

export const mutations: MutationTree<State> & Mutations = {
  // #region Artikel
  [MutationTypes.SetArtikel](state: State, artikel: Array<Artikel>) {
    state.artikel = artikel;
    sessionStorage.setItem('artikel', JSON.stringify(state.artikel));
  },

  [MutationTypes.AddArtikel](state: State, artikel: Artikel) {
    state.artikel.push(artikel);
    sessionStorage.setItem('artikel', JSON.stringify(state.artikel));
  },

  [MutationTypes.AddOrUpdateArtikelRange](state: State, artikel: Array<Artikel>) {
    for (var art of artikel) {
      const index = state.artikel.findIndex((a) => a.id === art.id);
      if (index == -1) {
        state.artikel.push(art);
      } else {
        state.artikel.splice(index, 1, art);
      }
    }
    sessionStorage.setItem('artikel', JSON.stringify(state.artikel));
  },

  [MutationTypes.UpdateArtikel](state: State, artikel: Artikel) {
    // if (artikel.ownerId == 0) {
    const art = state.artikel.find((a) => a.id === artikel.id);
    if (art != null) {
      const index = state.artikel.indexOf(art);
      state.artikel.splice(index, 1, artikel);
    }
    // }

    // else {
    //   const parent = state.artikel.find(a => a.id === artikel.ownerId);
    //   if (parent != null && parent.varianten.length > 0) {
    //     const parentIndex = state.artikel.indexOf(parent)
    //     const art = parent.varianten.find(a => a.id == artikel.id);
    //     if (art != null) {
    //       const index = state.artikel.indexOf(art)
    //       state.artikel[parentIndex].varianten.splice(index, 1, artikel)
    //     }
    //     else {
    //       state.artikel[parentIndex].varianten.push(artikel)
    //     }
    //   }
    // }

    sessionStorage.setItem('artikel', JSON.stringify(state.artikel));
  },

  [MutationTypes.DeleteArtikel](state: State, artikelid: number) {
    const artikel = state.artikel.find((a) => a.id === artikelid);
    if (artikel != null && artikel.ownerId == 0) {
      const index = state.artikel.indexOf(artikel);
      state.artikel.splice(index, 1);
    }

    // else {
    //   state.artikel.forEach(art => {
    //     const artikel = art.varianten.find(a => a.id === artikelid)
    //     if (artikel != null) {
    //       const index = state.artikel.indexOf(artikel)
    //       art.varianten.splice(index, 1)
    //     }
    //   })
    // }

    sessionStorage.setItem('artikel', JSON.stringify(state.artikel));
  },
  //#endregion

  //#region Artikelart
  [MutationTypes.SetArtikelart](state: State, data: Array<Artikelart>) {
    state.artikelarten = data;
    sessionStorage.setItem('artikelarten', JSON.stringify(state.artikelarten));
  },
  //#endregion

  //#region ArtikelEinheit
  [MutationTypes.SetArtikelEinheit](state: State, data: Array<ArtikelEinheit>) {
    console.log(data);
    state.artikeleinheiten = data;
    sessionStorage.setItem('artikeleinheiten', JSON.stringify(state.artikeleinheiten));
  },
  //#endregion

  //#region Tag
  [MutationTypes.SetTag](state: State, data: Array<Tag>) {
    state.tags = data;
    sessionStorage.setItem('tags', JSON.stringify(state.tags));
  },

  [MutationTypes.AddTag](state: State, data: Tag) {
    state.tags.push(data);
    sessionStorage.setItem('tags', JSON.stringify(state.tags));
  },

  [MutationTypes.UpdateTag](state: State, data: Tag) {
    const tag = state.tags.find((a) => a.id === data.id);
    if (tag != null) {
      const index = state.tags.indexOf(tag);
      state.tags.splice(index, 1, data);
    }

    sessionStorage.setItem('tags', JSON.stringify(state.tags));
  },

  [MutationTypes.DeleteTag](state: State, dataid: number) {
    const tag = state.tags.find((a) => a.id === dataid);
    if (tag != null) {
      const index = state.tags.indexOf(tag);
      state.tags.splice(index, 1);
    }

    sessionStorage.setItem('tags', JSON.stringify(state.tags));
  },
  //#endregion
};
