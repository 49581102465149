<template>
  <div class="d-flex justify-content-between">
    <h1>Warenlieferung</h1>
  </div>

  <LoadingSpinner v-if="updateLieferungLoading || createBestandLoading" text="... Daten werden geladen ..." />

  <div v-else>
    <div class="row">
      <div class="col-4">
        <h3>Lieferung</h3>
        <div class="row">
          <div class="col">
            <strong>Lieferant ID</strong><br /><span>{{ lieferant?.id }}</span>
          </div>
          <div class="col">
            <strong>Lieferant</strong><br /><span>{{ lieferant?.firma }}</span>
          </div>
          <div class="col">
            <strong>Lieferdatum</strong><br /><span>{{ lieferdatumConverter }}</span>
          </div>
        </div>

        <hr class="my-4" />

        <h3>Positionen</h3>
        <div class="row mx-1 my-3">
          <div class="col-2 fw-bold">Art Nr.</div>
          <div class="col-5 fw-bold">Artikel</div>
          <div class="col-2 fw-bold">Anzahl</div>
          <div class="col-3 fw-bold">Einheit</div>
        </div>

        <WarenzugangPosRow v-for="pos in lieferung?.positionen" :key="pos.id" :pos="pos" class="cursor-pointer" @click="setGeliefert(pos)" />
      </div>

      <div class="col-8">
        <h3>Lager</h3>
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="lagerort" class="form-label">Lagerort</label>
              <select class="form-select" id="lagerorte" aria-label="Default select example" v-model="selectedLagerort">
                <option :value="0">Lagerort auswählen...</option>
                <option v-for="lagerort in lagerorte" :key="lagerort.id" :value="lagerort">{{ lagerort.name }}</option>
              </select>
            </div>
          </div>

          <div class="col">
            <div class="mb-3">
              <label for="lagerbereich" class="form-label">Lagerbereich</label>
              <select class="form-select" id="lagerbereich" aria-label="Default select example" v-model="selectedLagerbereich">
                <option :value="0">Lagerort auswählen...</option>
                <option v-for="bereich in lagerbereiche.filter((l: Lagerbereich) => l.lagerortid == selectedLagerort?.id)" :key="bereich.id" :value="bereich">
                  {{ bereich.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <hr class="my-4" />

        <h3>Geliefert</h3>
        <div class="row mx-1 my-3">
          <div class="col-1 fw-bold">Art Nr.</div>
          <div class="col-3 fw-bold">Artikel</div>
          <div class="col-1 fw-bold">Barcode</div>
          <div class="col-2 fw-bold">Anzahl/Einheit</div>
          <div class="col-2 fw-bold">Lager</div>
          <div class="col-1 fw-bold">Bereich</div>
          <div class="col-1 fw-bold">Reihe</div>
          <div class="col-1 fw-bold">Platz</div>
        </div>

        <DisplayBestandRow v-for="(bestand, index) in geliefert" :key="bestand.id" :bestand="bestand" class="cursor-pointer" @click="setNotGeliefert(index)" />
      </div>
    </div>

    <div v-if="lieferungErrorMsg != '' || bestandErrorMsg != ''" class="alert alert-danger my-4">
      Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
    </div>

    <hr v-else class="my-4" />

    <div class="d-flex mx-3 justify-content-end">
      <router-link :to="{ name: 'Warenzugang' }" class="btn btn-secondary me-2">Abbrechen</router-link>
      <button class="btn btn-primary" @click="saveChanges()">Speichern</button>
    </div>

    <div v-if="lieferung == null && positionen.length == 0" class="alert alert-info mt-5">Es wurden keine Lieferungen gefunden.</div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, onMounted, PropType, ref } from 'vue';
  import { useStore } from '@/store';
  import { v4 as uuidv4 } from 'uuid';

  import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';
  import { ActionTypes as LagerReservierungActionTypes } from '@/store/modules/LagerReservierung/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import WarenzugangPosRow from '@/components/Bestand/WarenzugangPosRow.vue';
  import DisplayBestandRow from '@/components/Bestand/DisplayBestandRow.vue';

  import { ArtikelBestand, ArtikelBestandEigenschaft, BestandTag } from '@/models/BestandModels';
  import { Lieferung, LieferungPos } from '@/models/LieferungModels';
  import { Lieferant } from '@/models/LieferantModels';
  import { Artikel } from '@/models/ArtikelModels';
  import { Lagerbereich, Lagerort } from '@/models/LagerModels';
  import { useRouter } from 'vue-router';

  const { id } = defineProps({
    id: {
      type: String as PropType<string>,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const updateLieferungLoading = computed(() => store.getters.status.updateLieferungLoading);
  const lieferungErrorMsg = computed(() => store.getters.status.lieferungErrorMsg);

  const createBestandLoading = computed(() => store.getters.status.createBestandLoading);
  const bestandErrorMsg = computed(() => store.getters.status.bestandErrorMsg);

  const lieferung = computed(() => store.getters.lieferungen.find((l: Lieferung) => l.id == parseInt(id)));

  const lieferdatumConverter = computed(() => {
    if (lieferung.value?.lieferdatum == null) {
      return '';
    }
    const lieferdatum: Date = new Date(lieferung.value?.lieferdatum.toString());

    return ('0' + lieferdatum.getDate()).slice(-2) + '.' + ('0' + (lieferdatum.getMonth() + 1)).slice(-2) + '.' + lieferdatum.getFullYear();
  });

  const artikel = computed(() => store.getters.artikel);
  const positionen = computed(() => store.getters.bestand.filter((b: ArtikelBestand) => b.lieferungid == parseInt(id)));
  const geliefert = ref(new Array<ArtikelBestand>());

  positionen.value.forEach((pos: ArtikelBestand) => {
    if (pos.geliefert == 1) {
      geliefert.value.push(pos);
    }
  });

  const lieferant = computed(() => store.getters.lieferanten.find((l: Lieferant) => l.id == lieferung.value?.lieferantid));

  const selectedLagerort = ref<Lagerort>();
  const lagerorte = computed(() => store.getters.lagerorte);
  const lieferungLagerort = computed(() => lagerorte.value.find((l: Lagerort) => l.id == lieferung.value?.lagerortid));
  if (lieferungLagerort.value != null) {
    selectedLagerort.value = lieferungLagerort.value;
  }

  const selectedLagerbereich = ref<Lagerbereich>();
  const lagerbereiche = computed(() => store.getters.lagerbereiche.filter((l: Lagerbereich) => l.lagerbereichartid == 5));
  const lieferungLagerbereich = computed(() => lagerbereiche.value.find((l: Lagerbereich) => l.id == lieferung.value?.lagerbereichid));
  if (lieferungLagerbereich.value != null) {
    selectedLagerbereich.value = lieferungLagerbereich.value;
  }

  function setGeliefert(position: LieferungPos) {
    const bestand = new ArtikelBestand();

    const posArtikel = artikel.value.find((a: Artikel) => a.id == position.artikelid);
    if (posArtikel != null) {
      bestand.artikelnummer = posArtikel.artikelNr;
      bestand.artikeltitel = posArtikel.titel;

      for (var eigenschaft of posArtikel.eigenschaften) {
        bestand.artikeldaten.push(new ArtikelBestandEigenschaft(eigenschaft.key, eigenschaft.value));
      }

      for (var vareigenschaft of posArtikel.variantenEigenschaften) {
        bestand.artikeldaten.push(new ArtikelBestandEigenschaft(vareigenschaft.key, vareigenschaft.value));
      }
    }

    bestand.lieferungid = lieferung.value?.id || 0;
    bestand.lieferungposid = position.id;
    bestand.artikelid = position.artikelid;

    bestand.anzahl = position.anzahl;
    // bestand.einheit = position.einheit;
    bestand.einheitid = position.einheitid;

    if (posArtikel) {
      for (let tag of posArtikel.tags) {
        const bestandTag = new BestandTag();
        bestandTag.artikelbestandid = bestand.id;
        bestandTag.tagid = tag.tagid;

        bestand.tags.push(bestandTag);
      }
    }

    bestand.barcode = uuidv4();
    bestand.lieferdatum = lieferung.value?.lieferdatum || new Date();
    bestand.geliefert = 1;

    if (selectedLagerort.value != null) {
      bestand.lagerortid = selectedLagerort.value.id;
    }

    if (selectedLagerbereich.value != null) {
      bestand.lagerbereichid = selectedLagerbereich.value.id;
    }

    bestand.lagerreiheid = null;
    bestand.lagerplatzid = null;

    geliefert.value.push(bestand);

    if (geliefert.value.filter((a: ArtikelBestand) => a.artikelid == position.artikelid).length >= position.anzahl) {
      position.geliefert = 1;
    }
  }

  function setNotGeliefert(index: number) {
    var bestand = geliefert.value[index];
    var position = lieferung.value?.positionen.find((p: LieferungPos) => p.artikelid == bestand.artikelid);

    geliefert.value.splice(index, 1);

    if (position != null && geliefert.value.filter((a: ArtikelBestand) => a.artikelid == position?.artikelid).length < position.anzahl) {
      position.geliefert = 0;
    }
  }

  async function saveChanges() {
    if (lieferung.value == null) {
      return;
    }

    const lieferungAnzahlPaletten = lieferung.value?.positionen.reduce((sum: number, current: LieferungPos) => sum + current.anzahl, 0);
    if (geliefert.value.length == lieferungAnzahlPaletten) {
      lieferung.value.lieferungStatusid = 3;
    }

    await store.dispatch(BestandActionTypes.UpdateLieferung, lieferung.value);

    if (geliefert.value.length > 0) {
      const data = new Array<ArtikelBestand>();
      for (var best of geliefert.value) {
        if (best.id == 0) {
          data.push(best);
        }
      }

      if (data.length > 0) {
        await store.dispatch(BestandActionTypes.CreateBestaende, geliefert.value);
        await store.dispatch(LagerReservierungActionTypes.GetLagerReservierung, undefined);
      }
    }

    if (lieferungErrorMsg.value == '' && bestandErrorMsg.value == '') {
      router.push({ name: 'Warenzugang' });
    }
  }

  onMounted(async () => {
    if (lieferung.value?.lieferungStatusid == 1) {
      lieferung.value.lieferungStatusid = 2;
      await store.dispatch(BestandActionTypes.UpdateLieferung, lieferung.value);
    }
  });
</script>

<style scoped>
  .cursor-pointer:hover {
    cursor: pointer;
  }
</style>
