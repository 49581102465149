<template>
  <div class="row py-2 mb-2 mx-1 shadow-sm" :class="bgColor">
    <div class="col-2 my-auto">
      {{ importArtikel.artikel.artikelNr }}
    </div>

    <div class="col my-auto">
      {{ importArtikel.artikel.titel }}
    </div>

    <div v-if="importArtikel.existing" class="col-2 my-auto">
      <select class="form-select" aria-label="Task auswählen" v-model="importArtikel.task">
        <option :value="0">Ignorieren</option>
        <option :value="1">Daten übernehmen</option>
        <option :value="2">Kopie erstellen</option>
      </select>
    </div>

    <div class="col-1 my-auto text-end">
      <EditImportArtikelModal :artikel="importArtikel.artikel" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, PropType } from 'vue';

  import { ImportArtikel } from '@/models/ArtikelModels';

  import EditImportArtikelModal from './EditImportArtikelModal.vue';
  import { watch } from 'vue';
  //#endregion

  const { importArtikel } = defineProps({
    importArtikel: {
      type: Object as PropType<ImportArtikel>,
      required: true,
    },
  });

  watch(
    importArtikel.artikel,
    () => {
      if (importArtikel.artikel.titel == '') {
        importArtikel.hasError = true;
      } else {
        importArtikel.hasError = false;
      }
    },
    { immediate: true }
  );

  const bgColor = computed(() => {
    if (importArtikel.hasError) {
      return 'bg-danger text-white';
    } else if (importArtikel.existing) {
      return 'bg-warning';
    } else {
      return 'bg-white';
    }
  });
</script>
