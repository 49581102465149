<template>
  <h1>Artikel bearbeiten</h1>
  <LoadingSpinner v-if="loading" text="... der Artikel wird angelegt ..." />

  <form v-else @submit.prevent="updateCurrentArtikel">
    <div class="mb-3">
      <label for="artikel-titel" class="form-label">Titel</label>
      <input type="text" v-model="updateArtikel.artikel.titel" class="form-control" id="artikel-titel" />
    </div>

    <div class="mb-3">
      <label for="artikel-nr" class="form-label">Artikelnummer</label>
      <input type="text" v-model="updateArtikel.artikel.artikelNr" class="form-control" id="artikel-nr" />
    </div>

    <div class="mb-3">
      <label for="anzahl" class="form-label">Anzahl</label>
      <input type="number" v-model="updateArtikel.artikel.anzahl" class="form-control" id="anzahl" />
    </div>

    <div class="mb-3">
      <select class="form-select" v-model="updateArtikel.artikel.einheitid">
        <option v-for="ae in artikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="artikel-beschreibung" class="form-label">Beschreibung</label>
      <textarea class="form-control" v-model="updateArtikel.artikel.beschreibung" id="artikel-beschreibung" rows="3"></textarea>
    </div>

    <p class="fw-bold">Bestand Einstellung</p>
    <div class="form-check mb-3">
      <input class="form-check-input" type="checkbox" v-model="updateArtikel.artikel.inProdZusammenfuehren" id="in-prod-zusammenfuehren" />
      <label class="form-check-label" for="in-prod-zusammenfuehren"> Bestand in Produktion zusammenführen </label>
    </div>

    <hr class="my-3" />

    <!-- EIGENSCHAFTEN -->
    <div class="mb-3">
      <p class="fw-bold">Eigenschaften</p>

      <div class="row w-50 mb-3 ms-4">
        <div class="col-3">
          <span>Eigenschaft</span>
        </div>

        <div class="col-7">
          <label class="form-label">Wert</label>
        </div>

        <div class="col-2"></div>
      </div>

      <div v-for="(eigenschaft, index) in updateArtikel.artikel.eigenschaften" :key="index" class="row w-50 mb-3 ms-4">
        <div class="col-3">
          <div>
            <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required />
          </div>
        </div>

        <div class="col-7">
          <div>
            <input type="text" v-model="eigenschaft.value" class="form-control" :id="'value-' + index" placeholder="Rot" required />
          </div>
        </div>
        <div class="col-2">
          <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEigenschaft(index)"><font-awesome-icon icon="trash" /></button>
        </div>
      </div>

      <button type="button" class="btn btn-secondary ms-4" @click="addEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
    </div>

    <hr class="my-3" />

    <!-- Tags -->
    <div class="my-3">
      <p class="fw-bold">Tags</p>

      <div class="d-flex flex-row mb-3 flex-wrap">
        <p v-for="tag in updateArtikel.artikel.tags" :key="tag.id" class="lead me-2">
          <span class="badge text-bg-primary">{{ tags.find((t) => t.id == tag.tagid)?.key }}</span>
        </p>
      </div>

      <ArtikelTagSelectModal :tags="updateArtikel.artikel.tags.map((t) => t.tagid)" @set-selected-tags="setSelectedTags" />
    </div>

    <hr class="my-3" />

    <!-- VARIANTENEIGENSCHAFTEN -->
    <div v-if="artikel != null && artikel.variantenEigenschaften.length > 0" class="mb-3">
      <p class="fw-bold">Varianteneigenschaften</p>

      <div class="row w-50 mb-3 ms-4">
        <div class="col-3">
          <span>Eigenschaft</span>
        </div>

        <div class="col-4">
          <label class="form-label">Wert</label>
        </div>

        <div class="col-3">
          <label class="form-label">Artikelnummererweiterung</label>
        </div>

        <div class="col-2"></div>
      </div>

      <div v-for="(eigenschaft, index) in updateArtikel.artikel.variantenEigenschaften" :key="index" class="row w-50 mb-3 ms-4">
        <div class="col-3">
          <div>
            <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" required />
          </div>
        </div>

        <div class="col-4">
          <div>
            <input type="text" v-model="eigenschaft.value" class="form-control" :id="'value-' + index" required />
          </div>
        </div>

        <div class="col-3">
          <div>
            <input type="text" v-model="eigenschaft.artikelnummerextension" class="form-control" :id="'artikelnummerextension-' + index" required />
          </div>
        </div>

        <div class="col-2">
          <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVariantenEigenschaft(index)">
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>

      <button type="button" class="btn btn-secondary ms-4" @click="addVariantenEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>

      <hr class="my-3" />
    </div>

    <!-- VERBUNDKARTIKEL -->
    <div class="mb-3" v-if="updateArtikel.artikel.artikelartid == 3">
      <hr class="my-3" />
      <p class="fw-bold">Artikel</p>

      <div class="row mb-3">
        <div class="col-5">
          <span>Artikel</span>
        </div>

        <div class="col-3">
          <span>Anzahl</span>
        </div>

        <div class="col-3">
          <span>Einheit</span>
        </div>

        <div class="col-1"></div>
      </div>

      <div v-for="(artikel, index) in updateArtikel.artikel.verbundArtikel" :key="artikel.id" class="row mb-3">
        <div class="col-5">
          <!-- <input type="text" class="form-control" :id="'artikel'"> -->
          <AutocompletInputSelect
            :id="'artikel-titel-' + index"
            ref="artikelTitelInput"
            :select-list="artikelNameList"
            :inputValue="updateArtikel.verbundArtikel[index] != null ? updateArtikel.verbundArtikel[index].titel : ''"
            :index="index"
            @set-value="setVerbundartikelByName"
          />
        </div>

        <div class="col-3">
          <input type="number" class="form-control" v-model="artikel.anzahl" />
        </div>

        <div class="col-3">
          <select class="form-select" v-model="artikel.artikelEinheitid">
            <option value="0">Einheit auswählen...</option>
            <option v-for="einheit in artikeleinheiten" :value="einheit.id">
              {{ artikeleinheiten.find((a) => a.id == einheit.id)?.name }}
            </option>
          </select>
        </div>

        <div class="col-1">
          <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVerbundArtikel(index)"><font-awesome-icon icon="trash" /></button>
        </div>
      </div>

      <button type="button" class="btn btn-secondary" @click="addVerbundArtikel()"><font-awesome-icon icon="plus" /> Artikel hinzufügen</button>
    </div>

    <hr class="my-3" />

    <div v-if="errorMsg != ''" class="alert alert-danger">Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.</div>

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Änderungen speichern</button>
    </div>
  </form>

  <hr class="my-5" />

  <p class="fw-bold">Artikel löschen</p>
  <button type="button" class="btn btn-danger" @click="deleteCurrentArtikel"><font-awesome-icon icon="trash" /> Artikel löschen</button>
</template>

<script lang="ts" setup>
  import { computed, onMounted, reactive } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { ActionTypes } from '@/store/modules/Artikel/actions';
  import { v4 as uuidv4 } from 'uuid';

  import { Artikel, ArtikelVariantenEigenschaft, ArtikelEigenschaft, VerbundArtikel, ArtikelTag } from '@/models/ArtikelModels';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  // import ArtikelvarianteRow from '@/components/Artikel/ArtikelvarianteRow.vue';
  import AutocompletInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
  import ArtikelTagSelectModal from '@/components/Artikel/ArtikelTagSelectModal.vue';

  const props = defineProps({
    id: {
      type: String,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const artikel = computed(() => store.getters.artikel.find((a) => a.id == parseInt(props.id)));
  const artikelarten = computed(() => store.getters.artikelarten);
  const artikeleinheiten = computed(() => store.getters.artikeleinheiten);

  const tags = computed(() => store.getters.tags);

  const loading = computed(() => store.getters.status.updateArtikelLoading);
  const errorMsg = computed(() => store.getters.status.errorMsg);

  const artikelListe = computed(() => store.getters.artikel);
  const artikelNameList = computed(() => artikelListe.value.filter((a) => a.artikelartid == 1).map((obj: Artikel) => `${obj['titel']}`));

  const updateArtikel = reactive({
    artikel: new Artikel(),
    defaulteinheit: -1,
    verbundArtikel: new Array<Artikel>(),
  });

  if (artikel.value != null) {
    updateArtikel.artikel = JSON.parse(JSON.stringify(artikel.value));
  }

  if (artikel.value?.artikelartid == 3) {
    for (var art of artikel.value.verbundArtikel) {
      var va = artikelListe.value.find((a) => a.id == art.verbundArtikelid);
      if (va != null) {
        updateArtikel.verbundArtikel.push(va);
      }
    }
  }

  function setSelectedTags(data: Array<number>) {
    // updateArtikel.artikel.tags = data;
    const newTags = new Array<ArtikelTag>();
    for (var value of data) {
      const index = updateArtikel.artikel.tags.findIndex((t) => t.tagid == value);
      if (index == -1) {
        const newValue = new ArtikelTag();
        newValue.artikelid = updateArtikel.artikel.id;
        newValue.tagid = value;
        newTags.push(newValue);
      } else {
        newTags.push(updateArtikel.artikel.tags[index]);
      }
    }

    updateArtikel.artikel.tags = newTags;
  }

  function addEigenschaft() {
    updateArtikel.artikel.eigenschaften.push(new ArtikelEigenschaft());
  }

  function removeEigenschaft(index: number) {
    updateArtikel.artikel.eigenschaften.splice(index, 1);
  }

  function addVariantenEigenschaft() {
    if (artikel != null) updateArtikel.artikel.variantenEigenschaften.push(new ArtikelVariantenEigenschaft(0, updateArtikel.artikel.id, uuidv4(), '', '', ''));
  }

  function removeVariantenEigenschaft(index: number) {
    updateArtikel.artikel.variantenEigenschaften.splice(index, 1);
  }

  function addVerbundArtikel() {
    updateArtikel.artikel.verbundArtikel.push(new VerbundArtikel());
  }

  function removeVerbundArtikel(index: number) {
    updateArtikel.artikel.verbundArtikel.splice(index, 1);
  }

  //#region Methods
  function clearData() {
    updateArtikel.artikel = new Artikel();
    updateArtikel.defaulteinheit = -1;
    updateArtikel.verbundArtikel = new Array<Artikel>();
  }

  function cancel() {
    clearData();
    router.push({ name: 'ArtikelDetails', params: { id: props.id } });
  }

  function setVerbundartikelByName(name: string, index: number) {
    const artikel = artikelListe.value.find((a) => a.titel == name);
    if (artikel != null) {
      if (updateArtikel.verbundArtikel[index] == null) {
        updateArtikel.verbundArtikel.push(artikel);
      } else {
        updateArtikel.verbundArtikel.splice(index, 1, artikel);
      }

      updateArtikel.artikel.verbundArtikel[index].verbundArtikelid = artikel.id;
      updateArtikel.artikel.verbundArtikel[index].anzahl = 0;
      updateArtikel.artikel.verbundArtikel[index].artikelEinheitid = 0;
    }
  }

  function updateCurrentArtikel() {
    if (artikel.value != null) {
      store.dispatch(ActionTypes.UpdateArtikel, updateArtikel.artikel).then(() => {
        if (errorMsg.value == '') {
          cancel();
        }
      });
    }
  }

  function deleteCurrentArtikel() {
    if (artikel.value != null) {
      store.dispatch(ActionTypes.DeleteArtikel, artikel.value.id).then(() => {
        if (errorMsg.value == '') {
          clearData();
          router.push({ name: 'Artikel' });
        }
      });
    }
  }

  onMounted(() => {
    if (artikelarten.value.length == 0) {
      store.dispatch(ActionTypes.GetArtikelarten, undefined);
    }
  });
</script>

<style scoped>
  .footerbar {
    position: absolute;
    bottom: 0;
  }
</style>
