import { RootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';
import ApiService from '@/services/ApiService';

import { ActionTypes as CommonActionTypes } from '../Common/actions';

// import { ArtikelBestand, ArtikelBestandVerbrauchRequest } from "@/models/BestandModels";
import { Kunde } from '@/models/KundeModels';

export enum ActionTypes {
  GetKunde = 'GETKUNDE',
  CreateKunde = 'CREATEKUNDE',
  UpdateKunde = 'UPDATEKUNDE',
  DeleteKunde = 'DELETEKUNDE',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.GetKunde](context: ActionArguments): Promise<void>;
  [ActionTypes.CreateKunde](context: ActionArguments, data: Kunde): Promise<void>;
  [ActionTypes.UpdateKunde](context: ActionArguments, data: Kunde): Promise<void>;
  [ActionTypes.DeleteKunde](context: ActionArguments, id: number): Promise<void>;
};

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Kunde
  async [ActionTypes.GetKunde]({ commit, dispatch, rootGetters }: ActionArguments) {
    const status = rootGetters.status;
    status.getKundeLoading = true;
    status.kundeErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllKunde()
      .then((res) => {
        const data: Array<Kunde> = res.data;
        commit(MutationTypes.SetKunde, data);
      })
      .catch((error) => {
        status.kundeErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getKundeLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateKunde]({ commit, dispatch, rootGetters }: ActionArguments, data: Kunde) {
    const status = rootGetters.status;
    status.createKundeLoading = true;
    status.kundeErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createKunde(data)
      .then((res) => {
        const data: Kunde = res.data;
        commit(MutationTypes.AddKunde, data);
      })
      .catch((error) => {
        status.kundeErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createKundeLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateKunde]({ commit, dispatch, rootGetters }: ActionArguments, data: Kunde) {
    const status = rootGetters.status;
    status.updateKundeLoading = true;
    status.kundeErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateKunde(data)
      .then((res) => {
        const data: Kunde = res.data;
        commit(MutationTypes.UpdateKunde, data);
      })
      .catch((error) => {
        status.kundeErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateKundeLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DeleteKunde]({ commit, dispatch, rootGetters }: ActionArguments, id: number) {
    const status = rootGetters.status;
    status.deleteKundeLoading = true;
    status.kundeErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteKunde(id)
      .then((res) => {
        commit(MutationTypes.DeleteKunde, id);
      })
      .catch((error) => {
        status.kundeErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteKundeLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
  //#endregion
};
