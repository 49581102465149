<template>
  <div class="d-flex justify-content-between">
    <h1>Warenzugänge</h1>

    <button class="btn" @click="reloadLieferung()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex my-3">
    <router-link :to="{ name: 'CreateWarenzugang' }" class="btn btn-primary me-2"><font-awesome-icon icon="plus" /> Hinzufügen</router-link>
    <button class="btn btn-secondary me-2 disabled">Button 2</button>
    <button class="btn btn-secondary me-2 disabled">Button 3</button>
    <button class="btn btn-secondary me-2 disabled">Button 4</button>
  </div>

  <LoadingSpinner v-if="getLieferungLoading" text="... das Lager wird geladen ..." />

  <div v-else-if="lieferungErrorMsg != ''" class="alert alert-danger mt-5">Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.</div>

  <div v-if="lieferungen.length > 0">
    <h3>Lieferungen</h3>

    <div class="row mx-1 my-3">
      <div class="col-1 fw-bold">Lieferung Nr.</div>
      <div class="col-1 fw-bold">Lieferant Nr.</div>
      <div class="col-3 fw-bold">Lieferant</div>
      <div class="col-2 fw-bold">Lagerort</div>
      <div class="col-2 fw-bold">Lagerbereich</div>
      <div class="col-2 fw-bold">Lieferdatum</div>
      <div class="col-1 fw-bold"></div>
    </div>

    <WarenzugangRow v-for="lieferung in lieferungen" :key="lieferung.id" :lieferung="lieferung" />
  </div>

  <div v-if="warenzugangBestand.length > 0">
    <hr class="my-4" />

    <h3>Einzel-Warenzugänge</h3>

    <div class="row mx-1 my-3">
      <div class="col-1 fw-bold">Art Nr.</div>
      <div class="col-3 fw-bold">Artikel</div>
      <div class="col-1 fw-bold">Barcode</div>
      <div class="col-1 fw-bold">Anzahl</div>
      <div class="col-1 fw-bold">Einheit</div>
      <div class="col-2 fw-bold">Lager</div>
      <div class="col-1 fw-bold">Lagerbereich</div>
      <div class="col-1 fw-bold">Reihe</div>
      <div class="col-1 fw-bold">Platz</div>
    </div>

    <DisplayBestandRow v-for="bestand in warenzugangBestand" :key="bestand.id" :bestand="bestand" />
  </div>

  <div v-if="!getLieferungLoading && lieferungErrorMsg == '' && lieferungen.length == 0 && warenzugangBestand.length == 0" class="alert alert-info mt-5">
    Es wurden keine Lieferungen gefunden.
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, onMounted } from 'vue';
  import { useStore } from '@/store';
  import { ActionTypes as ArtikelbestandActionTypes } from '@/store/modules/Bestand/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import WarenzugangRow from '@/components/Bestand/WarenzugangRow.vue';
  import DisplayBestandRow from '@/components/Bestand/DisplayBestandRow.vue';

  import { ArtikelBestand } from '@/models/BestandModels';
  //#endregion

  const store = useStore();

  const getLieferungLoading = computed(() => store.getters.status.getLieferungLoading);
  const lieferungErrorMsg = computed(() => store.getters.status.lieferungErrorMsg);

  const lieferungen = computed(() => store.getters.lieferungen);

  const lieferungstatus = computed(() => store.getters.lieferungstatus);
  function loadLieferungstatus() {
    store.dispatch(ArtikelbestandActionTypes.GetLieferungStatus, undefined);
  }

  // const warenzugangBestand = computed(() => store.getters.bestand.filter((b: ArtikelBestand) => b.geliefert == 0 && (b.lieferungid == null || b.lieferungid == 0)))
  const warenzugangBestand = computed(() => store.getters.bestand.filter((b: ArtikelBestand) => b.geliefert == 0));

  function reloadLieferung() {
    store.dispatch(ArtikelbestandActionTypes.GetLieferung, undefined);
  }

  onMounted(() => {
    reloadLieferung();

    if (lieferungstatus.value.length == 0) {
      loadLieferungstatus();
    }
  });
</script>
