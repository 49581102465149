<template>
  <div class="row mx-1 mb-1 py-3 bg-white shadow-sm border" :class="{ 'border-warning': bestand != null, 'border-info': lieferungpos != null }">
    <div class="col-2 my-auto">{{ artikel?.artikelNr }}</div>
    <div class="col-5 my-auto">{{ artikel?.titel }}</div>
    <div v-if="bestand != null" class="col-2 my-auto">{{ bestand.anzahl }}</div>
    <div v-else class="col-2 my-auto">{{ lieferungpos?.anzahl }}</div>
    <div class="col-3 my-auto">{{ einheit?.name }}</div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue';
  import { useStore } from 'vuex';

  import { ArtikelBestand } from '@/models/BestandModels';
  // import { Lagerort, Lagerbereich, Lagerreihe, Lagerplatz } from '@/models/LagerModels';
  import { Artikel, ArtikelEinheit } from '@/models/ArtikelModels';
  import { LagerReservierung } from '@/models/LagerReservierungModels';
  import { Lieferung, LieferungPos } from '@/models/LieferungModels';

  const props = defineProps({
    lagerreservierung: {
      type: Object as PropType<LagerReservierung>,
      required: true,
    },
  });

  const store = useStore();

  const bestand = computed(() => {
    if (props.lagerreservierung.bestandid != null && props.lagerreservierung.bestandid != 0) {
      return store.getters.bestand.find((b: ArtikelBestand) => b.id == props.lagerreservierung.bestandid);
    }
    return null;
  });

  const lieferungpos = computed(() => {
    if (props.lagerreservierung.lieferungPosid != null && props.lagerreservierung.lieferungPosid != 0) {
      const lieferungen = store.getters.lieferungen;
      let pos = new LieferungPos();
      lieferungen.forEach((lieferung: Lieferung) => {
        lieferung.positionen.forEach((lieferPos: LieferungPos) => {
          if (lieferPos.id == props.lagerreservierung.lieferungPosid) {
            pos = lieferPos;
          }
        });
      });
      return pos;
    }

    return null;
  });

  const artikel = computed(() => {
    if (bestand.value != null) {
      return store.getters.artikel.find((a: Artikel) => a.id == bestand.value?.artikelid);
    } else if (lieferungpos.value != null) {
      return store.getters.artikel.find((a: Artikel) => a.id == lieferungpos.value?.artikelid);
    } else {
      return null;
    }
  });

  const einheit = computed(() => {
    if (bestand.value != null) {
      return store.getters.artikeleinheiten.find((e: ArtikelEinheit) => e.id == bestand.value?.einheitid);
    } else if (lieferungpos.value != null) {
      return store.getters.artikeleinheiten.find((e: ArtikelEinheit) => e.id == lieferungpos.value?.einheitid);
    } else {
      return null;
    }
  });

  // const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == props.lagerreservierung.lagerortid));
  // const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == props.lagerreservierung.lagerbereichid));
  // const lagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == props.lagerreservierung.lagerreiheid));
  // const lagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == props.lagerreservierung.lagerplatzid));
</script>
