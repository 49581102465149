<template>
  <div class="py-2 mb-2 shadow-sm bg-white">
    <div class="row mx-1">
      <div class="col-2 my-auto">
        <strong>#{{ artikel.artikelNr }}</strong>
      </div>

      <div class="col-4 my-auto">
        {{ artikel.titel }}
      </div>

      <div class="col-2 my-auto">
        {{ verbundartikel.anzahl }}
      </div>

      <div class="col-2 my-auto">
        {{ einheit.name }}
      </div>


      <div class="col-2 my-auto">
        <button type="button" class="btn btn-primary float-end" @click="openArtikelDetails()" title="Artikeldetails">
          <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { Artikel, ArtikelEinheit, VerbundArtikel } from "@/models/ArtikelModels";
import { useStore } from "vuex";

export default defineComponent({
  name: "DisplayVerbundArtikelRow",
  props: {
    verbundartikel: {
      type: Object as PropType<VerbundArtikel>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },

  setup (props) {
    const store = useStore()
    const router = useRouter()

    const artikel = computed(() => store.getters.artikel.find((a: Artikel) => a.id == props.verbundartikel.verbundArtikelid))
    const einheit = computed(() => store.getters.artikeleinheiten.find((e: ArtikelEinheit) => e.id == props.verbundartikel.artikelEinheitid))

    return {
      router,
      artikel,
      einheit
    }
  },

  methods: {
    openArtikelDetails () {
      this.router.push({name: "ArtikelDetails", params: {id: this.verbundartikel.verbundArtikelid}})
    },
  }
})

</script>

<style scoped>
.display-artikel-row {
  background-color: #dfe9f8 !important;
}
/* .display-artikel-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
} */
</style>