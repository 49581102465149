<template>
  <div class="d-flex justify-content-between">
    <h1>Aufträge</h1>

    <button class="btn" @click="reloadAuftraege()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <router-link :to="{ name: 'CreateAuftrag' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
  </div>

  <LoadingSpinner v-if="loading" text="... Aufträge werden geladen ..." />

  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Aufträge aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="auftraege.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-2 fw-bold">Auftrag</div>
      <div class="col-2 fw-bold">Firma</div>
      <div class="col-3 fw-bold">Name</div>
      <div class="col-2 fw-bold">Status</div>
      <div class="col-3 fw-bold">Auslieferung</div>
    </div>

    <div v-for="auftrag in auftraege" :key="auftrag.id">
      <AuftragRow :auftrag="auftrag" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && auftraege.length == 0" class="alert alert-info mt-5">Es wurden keine Aufträge gefunden.</div>
</template>

<script lang="ts" setup>
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from '@/store';

  import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';
  import { ActionTypes as KundeActionTypes } from '@/store/modules/Kunde/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import AuftragRow from '@/components/Auftrag/AuftragRow.vue';

  import { GetAuftraegeRequest } from '@/models/AuftragModels';

  const store = useStore();

  const auftraege = computed(() => store.getters.auftraege);

  const loading = computed(() => store.getters.status.getAuftragLoading);
  const errorMsg = computed(() => store.getters.status.auftragErrorMsg);

  const filter = ref(new GetAuftraegeRequest());

  function loadAuftragData() {
    // filter.value.kundeid = '10000001';
    // filter.value.lagerortid = '1';
    // filter.value.lagerbereichid = '60';
    // filter.value.auslieferungsdatum = new Date().toISOString();

    store.dispatch(AuftragActionTypes.GetAllAuftraege, filter.value);
    store.dispatch(AuftragActionTypes.GetAuftragStatus, undefined);

    if (store.getters.kunden.length == 0) {
      store.dispatch(KundeActionTypes.GetKunde, undefined);
    }
  }

  function reloadAuftraege() {
    store.dispatch(AuftragActionTypes.GetAllAuftraege, filter.value);
  }

  onMounted(() => {
    loadAuftragData();
  });
</script>
