<template>
  <div v-if="auftrag == null" class="alert alert-warning">Ungültige Auftrag Nr.: {{ id }}</div>

  <div v-else>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToAuftraege()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>Details</h1>
      <router-link :to="{ name: 'EditAuftrag', params: { id: id } }" class="btn" title="Auftrag bearbeiten"><font-awesome-icon icon="pen" /></router-link>
    </div>

    <div class="row mb-3">
      <div class="col-3">
        <p><strong>Auftrag Nr.</strong>: {{ auftrag.id }}</p>
      </div>

      <div class="col-3">
        <p><strong>Status</strong>: {{ auftragstatus?.status }}</p>
      </div>

      <div class="col-3">
        <p><strong>Lagerort</strong>: {{ lagerort ? lagerort.name : '-' }}</p>
      </div>

      <div class="col">
        <p><strong>Lagerbereich</strong>: {{ lagerbereich ? lagerbereich.name : '-' }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <p><strong>Auslieferung</strong>: {{ auftrag.auslieferungsdatum ? dateToDateConverter(auftrag.auslieferungsdatum) : '-' }}</p>
      </div>

      <div class="col-3">
        <p><strong>Lieferdatum</strong>: {{ auftrag.lieferdatum ? dateToDateConverter(auftrag.lieferdatum) : '-' }}</p>
      </div>

      <div class="col-3">
        <p><strong>Rechnungsdatum</strong>: {{ auftrag.rechnungsdatum ? dateToDateConverter(auftrag.rechnungsdatum) : '-' }}</p>
      </div>
    </div>

    <hr class="my-4" />

    <h3>Kunde</h3>
    <div class="row mb-4">
      <div class="col">
        <h4>Kontakt</h4>
        <p><strong>Firma</strong>: {{ kunde?.firma }}</p>
        <p><strong>Name</strong>: {{ kunde?.vorname }} {{ kunde?.name }}</p>
        <p><strong>E-Mail</strong>: {{ kunde?.email }}</p>
        <p><strong>Tel</strong>: {{ kunde?.tel }}</p>
      </div>

      <div class="col">
        <h4>Lieferadresse</h4>
        <p><strong>Straße</strong>: {{ kunde?.lieferstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde?.lieferplz }} {{ kunde?.lieferort }}</p>
        <p><strong>Land</strong>: {{ kunde?.lieferland }}</p>
      </div>

      <div class="col">
        <h4>Rechnungsadresse</h4>
        <p><strong>Straße</strong>: {{ kunde?.rechnungstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde?.rechnungplz }} {{ kunde?.rechnungort }}</p>
        <p><strong>Land</strong>: {{ kunde?.rechnungland }}</p>
      </div>
    </div>

    <hr class="my-4" />

    <h3>Positionen</h3>

    <div class="row mx-1 mb-2">
      <div class="col-1"><strong>Artikel Nr.</strong></div>
      <div class="col-2"><strong>Artikel </strong></div>
      <div class="col-2"><strong>Anzahl </strong></div>
      <div class="col-2"><strong>Einheit </strong></div>
    </div>

    <div v-for="pos in auftrag.positionen">
      <AuftragPosRow :auftragpos="pos" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { useDateConverter } from '@/utils/date_converter';

  import { Kunde } from '@/models/KundeModels';
  import { Auftrag, AuftragStatus } from '@/models/AuftragModels';

  import AuftragPosRow from '@/components/Auftrag/AuftragPosRow.vue';

  const { id } = defineProps({
    id: {
      type: String,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const { dateToDateConverter } = useDateConverter();

  const auftrag = computed(() => store.getters.auftraege.find((a: Auftrag) => a.id == parseInt(id)));

  const kunde = computed(() => store.getters.kunden.find((k: Kunde) => k.id == auftrag.value?.kundeid));
  const auftragstatus = computed(() => store.getters.auftragstatus.find((p: AuftragStatus) => p.id == auftrag.value?.auftragStatusid));

  const lagerort = computed(() => store.getters.lagerorte.find((l) => l.id == auftrag.value?.lagerortid));
  const lagerbereich = computed(() => store.getters.lagerbereiche.find((l) => l.id == auftrag.value?.lagerbereichid));

  function returnToAuftraege() {
    router.push({ name: 'Auftraege' });
  }
</script>
