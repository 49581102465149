<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm auftrag-row">
    <div class="col-2 my-auto">{{ umfuhr.id }}</div>
    <div class="col-2 my-auto">{{ vonlager?.name }}</div>
    <div class="col-2 my-auto">{{ zulager?.name }}</div>
    <div class="col-1 my-auto">{{ umfuhrstatus?.status}}</div>
    <div class="col-2 my-auto">{{ umfuhr.bereitstellungsdatum }}</div>
    <div class="col-1 my-auto">{{ umfuhr.umfuhrdatum }}</div>
    <div class="col-1 my-auto">{{ umfuhr.ankunftsdatum }}</div>
    <div class="col-1 my-auto">
      <button class="btn btn-primary m-0 float-end" @click="openUmfuhrDetails()">
        <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { Auftrag, AuftragStatus } from "@/models/AuftragModels";
import { Kunde } from "@/models/KundeModels";
import { Umfuhr, UmfuhrStatus } from "@/models/UmfuhrModels";
import { Lagerort } from "@/models/LagerModels";

export default defineComponent({
  name: "UmfuhrRow",

  props: {
    umfuhr: {
      type: Object as PropType<Umfuhr>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const router = useRouter()

    const umfuhrstatus = computed(() => store.getters.umfuhrstatus.find((p: UmfuhrStatus) => p.id == props.umfuhr.umfuhrStatusid))
    const vonlager = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == props.umfuhr.vonlagerid))
    const zulager = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == props.umfuhr.zulagerid))

    return {
      router,
      umfuhrstatus,
      vonlager,
      zulager
    }
  },

  methods: {
    openUmfuhrDetails () {
      this.router.push({name: "DetailsUmfuhr", params: {id: this.umfuhr.id}})
    }
  }
})

</script>

<style scoped>
.kunde-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
}
</style>