<template>
  <button class="btn btn-outline-secondary" @click="showModal()"><font-awesome-icon icon="table-cells" /> Daten anzeigen</button>

  <div
    class="modal fade p-0"
    tabindex="-1"
    aria-labelledby="importDataModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="importDataModalRef"
  >
    <div class="modal-dialog modal-xl text-start">
      <div class="modal-content">
        <div class="modal-header bg-secondary text-white d-flex justify-content-between p-2">
          <h5 class="m-0 p-0">Daten</h5>

          <button type="button" class="btn-close" @click="closeModal()"></button>
        </div>

        <div class="modal-body text-dark">
          <table class="table table-striped">
            <thead>
              <tr>
                <th v-for="(titel, index) in header" :key="index" scope="col">{{ titel }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) in data" :key="index">
                <td v-for="(col, idx) in row" :key="idx">{{ col }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary ms-3" @click="closeModal()">Fertig</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, ref } from 'vue';
  import { Modal } from 'bootstrap';

  //#endregion

  const { header, data } = defineProps({
    header: {
      type: Object as PropType<string[]>,
      required: true,
    },
    data: {
      type: Object as PropType<{ [key: string]: string }[]>,
      required: true,
    },
  });

  // console.log(header, data);

  const importDataModalRef = ref();
  function showModal() {
    if (importDataModalRef.value) {
      new Modal(importDataModalRef.value).show();
    }
  }

  function closeModal() {
    Modal.getInstance(importDataModalRef.value)?.hide();
  }
</script>

<style scoped>
  .modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }
</style>
