<template>
  <div class="row mx-1 py-2 mb-2 bg-white shadow-sm">
    <div class="col-4 my-auto">{{ bestandBewegung.beschreibung }}</div>

    <div class="col-1 my-auto">{{ bestandBewegung.operator }} {{ bestandBewegung.anzahl }} {{ einheit?.name }}</div>
    <div class="col-1 my-auto">{{ bestandBewegung.gesamtanzahl }} {{ einheit?.name }}</div>

    <div class="col-2 my-auto">{{ bestandBewegung.auftragid }}</div>
    <div class="col-2 my-auto">{{ bestandBewegung.produktionsauftragid }}</div>

    <div class="col-2 my-auto">{{ dateConverter }}</div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed } from 'vue';
  import { useStore } from '@/store';

  import { ArtikelBestandBewegung } from '@/models/BestandModels';
  // import { Lagerort, Lagerbereich, Lagerreihe, Lagerplatz } from '@/models/LagerModels';
  import { ArtikelEinheit } from '@/models/ArtikelModels';
  //#endregion

  const props = defineProps({
    bestandBewegung: {
      type: Object as PropType<ArtikelBestandBewegung>,
      required: true,
    },
  });

  const store = useStore();

  const dateConverter = computed(() => {
    if (props.bestandBewegung.adate == null) {
      return '';
    }
    const datum: Date = new Date(props.bestandBewegung.adate.toString());

    return (
      ('0' + datum.getDate()).slice(-2) +
      '.' +
      ('0' + (datum.getMonth() + 1)).slice(-2) +
      '.' +
      datum.getFullYear() +
      ' ' +
      ('0' + (datum.getHours() + 1)).slice(-2) +
      ':' +
      ('0' + (datum.getMinutes() + 1)).slice(-2) +
      ':' +
      ('0' + (datum.getSeconds() + 1)).slice(-2)
    );
  });

  const einheit = computed(() => store.getters.artikeleinheiten.find((e: ArtikelEinheit) => e.id == props.bestandBewegung.einheitid));

  // const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == props.bestandBewegung.lagerortid));
  // const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == props.bestandBewegung.lagerbereichid));
  // const lagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == props.bestandBewegung.lagerreiheid));
  // const lagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == props.bestandBewegung.lagerplatzid));
</script>
