<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm artikel-row" @click="openArtikelDetails()">
    <div class="col-2 my-auto">
      {{ artikel.artikel.artikelNr }}
    </div>

    <div class="col-5 my-auto">
      {{ artikel.artikel.titel }}
    </div>

    <div class="col-3 my-auto">
      <div v-for="tag in artikel.artikel.tags" :key="tag.id" class="badge text-bg-primary me-1">{{ getTagKeyById(tag.tagid) }}</div>
    </div>

    <div class="col-1 d-flex justify-content-end align-items-center">
      <font-awesome-icon v-if="artikel.artikel.artikelartid == 1" icon="box" />
      <font-awesome-icon v-if="artikel.artikel.artikelartid == 2" icon="boxes-stacked" />
      <font-awesome-icon v-if="artikel.artikel.artikelartid == 3" icon="boxes-packing" />
    </div>

    <div class="col-1 my-auto">
      <button class="btn btn-primary m-0 float-end">
        <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useRouter } from 'vue-router';
  import { PropType } from 'vue';
  import { ArtikelTree, Tag } from '@/models/ArtikelModels';
  import { useStore } from '@/store';

  const { artikel } = defineProps({
    artikel: {
      type: Object as PropType<ArtikelTree>,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  function getTagKeyById(id: number): string {
    const tag = store.getters.tags.find((t: Tag) => t.id == id);
    return tag ? tag.key : '-';
  }

  function openArtikelDetails() {
    router.push({ name: 'ArtikelDetails', params: { id: artikel.artikel.id } });
  }
</script>

<style scoped>
  .artikel-row:hover {
    cursor: pointer;
    color: #2a579b;
    background-color: #dfe9f8 !important;
  }
</style>
