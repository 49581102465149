<template>
  <h1>Artikel erstellen</h1>
  <LoadingSpinner v-if="loading" text="... der Artikel wird angelegt ..." />

  <form v-else @submit.prevent="createArtikel">
    <!-- SELECT TEMPLATE -->
    <div class="row mb-3">
      <div class="col-4">
        <label for="artikel-template" class="form-label">Artikel Template auswählen</label>
        <select class="form-select" id="artikel-template" v-model="selectedArtikeltemplate" @change="setArtikeltemplate()">
          <option :value="null">Kein Template...</option>
          <option v-for="template in artikeltemplates" :key="template.id" :value="template">
            {{ template.titel }}
          </option>
        </select>
      </div>
    </div>

    <hr class="my-3" />

    <!-- SELECT ARTIKELART -->
    <div class="row mb-3">
      <div class="col-4">
        <label for="artikelart" class="form-label">Artikelart auswählen</label>
        <select class="form-select" id="artikel-template" v-model="newArtikel.artikel.artikelartid">
          <option v-for="artikelart in artikelarten" :key="artikelart.id" :value="artikelart.id">
            {{ artikelart.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="mb-3">
      <label for="artikel-titel" class="form-label">Titel</label>
      <input type="text" v-model="newArtikel.artikel.titel" class="form-control" id="artikel-titel" @input="newArtikelEditArtikeltitel()" required />
    </div>

    <div class="mb-3">
      <label for="artikel-nr" class="form-label">Artikelnummer</label>
      <input type="text" v-model="newArtikel.artikel.artikelNr" class="form-control" id="artikel-nr" @input="newArtikelEditArtikelnummer()" />
    </div>

    <div class="mb-3">
      <label for="anzahl" class="form-label">Anzahl</label>
      <input type="number" v-model="newArtikel.artikel.anzahl" class="form-control" id="anzahl" />
    </div>

    <div class="mb-3">
      <select class="form-select" v-model="newArtikel.artikel.einheitid">
        <option v-for="ae in artikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="artikel-beschreibung" class="form-label">Beschreibung</label>
      <textarea class="form-control" v-model="newArtikel.artikel.beschreibung" id="artikel-beschreibung" rows="3"></textarea>
    </div>

    <p class="fw-bold">Bestand Einstellung</p>
    <div class="form-check mb-3">
      <input class="form-check-input" type="checkbox" v-model="newArtikel.artikel.inProdZusammenfuehren" id="in-prod-zusammenfuehren" />
      <label class="form-check-label" for="in-prod-zusammenfuehren"> Bestand in Produktion zusammenführen </label>
    </div>

    <hr class="my-3" />

    <!-- EIGENSCHAFTEN -->
    <div class="mb-3">
      <p class="fw-bold">Eigenschaften</p>

      <div class="row w-50 mb-3">
        <div class="col-4">
          <div>
            <span>Eigenschaft</span>
          </div>
        </div>

        <div class="col-8">
          <div>
            <label class="form-label">Wert</label>
          </div>
        </div>
      </div>

      <div v-for="(eigenschaft, index) in newArtikel.artikel.eigenschaften" :key="index">
        <div class="row w-50 mb-3">
          <div class="col-3">
            <div>
              <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required />
            </div>
          </div>

          <div class="col-7">
            <div>
              <input type="text" v-model="eigenschaft.value" class="form-control" :id="'value-' + index" placeholder="Rot" required />
            </div>
          </div>
          <div class="col-2">
            <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEigenschaft(index)">
              <font-awesome-icon icon="trash" />
            </button>
          </div>
        </div>
      </div>

      <div>
        <button type="button" class="btn btn-secondary" @click="addEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
      </div>
    </div>

    <hr class="my-3" />

    <!-- TAGS -->
    <div class="my-3">
      <p class="fw-bold">Tags</p>

      <div class="d-flex flex-row mb-3 flex-wrap">
        <p v-for="tag in newArtikel.artikel.tags" :key="tag.id" class="lead me-2">
          <span class="badge text-bg-primary">{{ tags.find((t) => t.id == tag.tagid)?.key }}</span>
        </p>
      </div>

      <ArtikelTagSelectModal :tags="newArtikel.artikel.tags.map((t) => t.tagid)" @set-selected-tags="setSelectedTags" />
    </div>

    <hr class="my-3" />

    <!-- VARIANTENEIGENSCHAFTEN -->
    <div class="mb-3" v-if="newArtikel.artikel.artikelartid == 2">
      <hr class="my-3" />
      <p class="fw-bold">Varianten</p>

      <div class="mb-3">
        <p class="fw-bold mb-3">Varianten Eigenschaften</p>

        <div class="row mb-3">
          <div class="col-2">
            <span>Eigenschaft</span>
          </div>

          <div class="col-8">
            <span>Werte</span>
          </div>

          <div class="col-2"></div>
        </div>

        <div v-for="(eigenschaft, index) in newArtikel.variantenEigenschaften" :key="index">
          <div class="row mb-3">
            <div class="col-2">
              <div>
                <input
                  type="text"
                  v-model="eigenschaft.key"
                  class="form-control"
                  :id="'key-' + index"
                  placeholder="Farbe"
                  required
                  @input="variantenEigenschaftEditKey(index)"
                />
              </div>
            </div>

            <div class="col-8">
              <div class="row">
                <div class="col-5">Wert</div>
                <div class="col-5">Artikelnummer Erweiterung</div>
                <div class="col-2"></div>
              </div>

              <div class="row mb-2" v-for="(wert, windex) in eigenschaft.values" :key="'key-' + index + '-' + windex">
                <div class="col-5">
                  <input
                    type="text"
                    class="form-control"
                    v-model="wert.value"
                    placeholder="Rot"
                    required
                    @input="variantenEigenschaftEditValue(index, windex)"
                  />
                </div>

                <div class="col-5">
                  <input
                    type="text"
                    class="form-control"
                    v-model="wert.artikelnummerextension"
                    placeholder="04"
                    @input="variantenEigenschaftEditArtikelnummerextionsion(index, windex)"
                  />
                </div>

                <div class="col-2">
                  <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVarianteEigenschaftValue(index, windex)">
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>

              <div>
                <button type="button" class="btn btn-outline-secondary" @click="addVarianteEigenschaftValue(index)">
                  <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
                </button>
              </div>
            </div>

            <div class="col-2">
              <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVarianteEigenschaft(index)">
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>

        <div>
          <button type="button" class="btn btn-secondary" @click="addVarianteEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
        </div>
      </div>

      <div v-if="newArtikel.variantenEigenschaften.length > 0" class="mb-3" v>
        <div class="btn-group mb-3" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-outline-primary" @click="generateArtikelVarianten()">Varianten generieren</button>
          <button type="button" class="btn btn-outline-primary" @click="addArtikelVariante()">Variante hinzufügen</button>
          <button type="button" class="btn btn-outline-primary" @click="deleteAllArtikelVarianten()">Alle löschen</button>
        </div>

        <div class="mb-3">
          <div v-for="(variante, index) in newArtikel.varianten" :key="index" class="row">
            <div class="col-11">
              <ArtikelvarianteRow
                :artikelnummer="newArtikel.artikel.artikelNr"
                :artikeltitel="newArtikel.artikel.titel"
                :artikel="variante"
                :varianten-eigenschaften="newArtikel.variantenEigenschaften"
                :index="index"
              />
            </div>
            <div class="col-1">
              <button type="button" class="btn text-secondary" title="Entfernen" @click="removeArtikelVariante(index)">
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- VERBUNDARTIKEL -->
    <div class="mb-3" v-if="newArtikel.artikel.artikelartid == 3">
      <hr class="my-3" />
      <p class="fw-bold">Artikel</p>

      <div class="row mb-3">
        <div class="col-5">
          <span>Artikel</span>
        </div>

        <div class="col-3">
          <span>Anzahl</span>
        </div>

        <div class="col-3">
          <span>Einheit</span>
        </div>

        <div class="col-1"></div>
      </div>

      <div v-for="(artikel, index) in newArtikel.artikel.verbundArtikel" :key="artikel.id" class="row mb-3">
        <div class="col-5">
          <!-- <input type="text" class="form-control" :id="'artikel'"> -->
          <AutocompletInputSelect
            :id="'artikel-titel-' + index"
            ref="artikelTitelInput"
            :select-list="artikelNameList"
            :inputValue="''"
            :index="index"
            @set-value="setVerbundartikelByName"
          />
        </div>

        <div class="col-3">
          <input type="number" class="form-control" v-model="artikel.anzahl" />
        </div>

        <div class="col-3">
          <select class="form-select" v-model="artikel.artikelEinheitid">
            <option value="0">Einheit auswählen...</option>
            <option v-for="einheit in artikeleinheiten" :value="einheit.id">
              {{ artikeleinheiten.find((a) => a.id == einheit.id)?.name }}
            </option>
          </select>
        </div>

        <div class="col-1">
          <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVerbundArtikel(index)">
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>

      <button type="button" class="btn btn-secondary" @click="addVerbundArtikel()"><font-awesome-icon icon="plus" /> Artikel hinzufügen</button>
    </div>

    <div v-if="errorMsg != ''" class="alert alert-danger">Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.</div>

    <hr class="my-4" />

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>
  </form>

  <hr />

  <code>
    {{ JSON.stringify(newArtikel.artikel, null, 2) }}
  </code>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, ref, reactive, onMounted } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { ActionTypes as ArtikeltemplateActionTypes } from '@/store/modules/Artikeltemplates/actions';
  import { v4 as uuidv4 } from 'uuid';

  import { Artikel, ArtikelEigenschaft, ArtikelVariantenEigenschaft, CreateVariantenArtikelRequest, VerbundArtikel, ArtikelTag } from '@/models/ArtikelModels';
  import { EditVariantenEigenschaft, EditVariantenEigenschaftValue } from '@/models/ArtikelModels';
  import { Artikeltemplate } from '@/models/ArtikeltemplateModels';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import ArtikelvarianteRow from '@/components/Artikel/ArtikelvarianteRow.vue';
  import AutocompletInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
  import ArtikelTagSelectModal from '@/components/Artikel/ArtikelTagSelectModal.vue';
  //#endregion

  const store = useStore();
  const router = useRouter();

  const loading = computed(() => store.getters.status.createArtikelLoading);
  const errorMsg = computed(() => store.getters.status.artikelErrorMsg);

  const artikelarten = computed(() => store.getters.artikelarten);
  const artikeleinheiten = computed(() => store.getters.artikeleinheiten);

  const tags = computed(() => store.getters.tags);

  const artikeltemplates = computed(() => store.getters.artikeltemplates);
  const selectedArtikeltemplate = ref<Artikeltemplate | null>(null);

  const artikel = computed(() => store.getters.artikel);
  const artikelNameList = computed(() => artikel.value.filter((a) => a.artikelartid == 1).map((obj: Artikel) => `${obj['titel']}`));

  const newArtikel = reactive({
    artikel: new Artikel(),
    variantenEigenschaften: new Array<EditVariantenEigenschaft>(),
    defaulteinheit: -1,
    varianten: new Array<Artikel>(),
    verbundArtikel: new Array<Artikel>(),
  });

  function setSelectedTags(data: Array<number>) {
    newArtikel.artikel.tags = new Array<ArtikelTag>();
    for (var value of data) {
      const newValue = new ArtikelTag();
      newValue.artikelid = newArtikel.artikel.id;
      newValue.tagid = value;
      newArtikel.artikel.tags.push(newValue);
    }

    if (newArtikel.varianten.length > 0) {
      for (var variante of newArtikel.varianten) {
        variante.tags = newArtikel.artikel.tags;
      }
    }
  }

  //#region Eigenschaften
  function addEigenschaft() {
    newArtikel.artikel.eigenschaften.push(new ArtikelEigenschaft());
  }

  function removeEigenschaft(index: number) {
    newArtikel.artikel.eigenschaften.splice(index, 1);
  }

  function addVarianteEigenschaft() {
    newArtikel.variantenEigenschaften.push(new EditVariantenEigenschaft(''));

    if (newArtikel.varianten.length > 0) {
      const index = newArtikel.variantenEigenschaften.length - 1;
      newArtikel.varianten.forEach((variante) => {
        var uuid = uuidv4();
        variante.variantenEigenschaften.push(new ArtikelVariantenEigenschaft(0, 0, uuid, newArtikel.variantenEigenschaften[index].key, '', ''));
      });
    }
  }

  function removeVarianteEigenschaft(index: number) {
    if (newArtikel.varianten.length > 0) {
      const eigenschaft = newArtikel.variantenEigenschaften[index];
      newArtikel.varianten.forEach((variante) => {
        const vindex = variante.variantenEigenschaften.findIndex((v) => v.key == eigenschaft.key);
        variante.variantenEigenschaften.splice(vindex, 1);

        // Artikelnummer anpassen
        let newArtikelnummer = newArtikel.artikel.artikelNr;
        let newArtikeltitel = newArtikel.artikel.titel;
        variante.variantenEigenschaften.forEach((v) => {
          newArtikelnummer += v.artikelnummerextension;
          newArtikeltitel += ' ' + v.value;
        });
        variante.artikelNr = newArtikelnummer;
        variante.titel = newArtikeltitel;
      });
    }

    newArtikel.variantenEigenschaften.splice(index, 1);
  }

  function addVarianteEigenschaftValue(varianteindex: number) {
    newArtikel.variantenEigenschaften[varianteindex].values.push(new EditVariantenEigenschaftValue(uuidv4(), '', ''));
  }

  function removeVarianteEigenschaftValue(varianteindex: number, index: number) {
    newArtikel.variantenEigenschaften[varianteindex].values.splice(index, 1);
  }
  //#endregion

  //#region Variantenartikel
  function getAllCombinations(input: Array<EditVariantenEigenschaft>): Array<any> {
    const eigenschaften: any = {};
    for (let eigenschaft of input) {
      eigenschaften[eigenschaft.key] = [];
      for (var option of eigenschaft.values) {
        eigenschaften[eigenschaft.key].push(new EditVariantenEigenschaftValue(option.identifier, option.value, option.artikelnummerextension));
      }
    }

    const keys = Object.keys(eigenschaften);
    const combinations: Array<any> = [];

    function generateCombinations(index: number, currentCombination: any) {
      if (index == keys.length) {
        combinations.push({ ...currentCombination });
        return;
      }

      const key = keys[index];
      const values = eigenschaften[key];

      for (const value of values) {
        currentCombination[key] = value;
        generateCombinations(index + 1, currentCombination);
      }
    }

    generateCombinations(0, {});

    return combinations;
  }

  function generateArtikelVarianten() {
    newArtikel.varianten = new Array<Artikel>();

    const combinations = getAllCombinations(newArtikel.variantenEigenschaften);

    combinations.forEach((comp) => {
      var variante = new Artikel();

      variante.titel = newArtikel.artikel.titel;
      variante.artikelNr = newArtikel.artikel.artikelNr;
      variante.eigenschaften = newArtikel.artikel.eigenschaften;

      variante.anzahl = newArtikel.artikel.anzahl;
      variante.einheit = newArtikel.artikel.einheit;
      variante.einheitid = newArtikel.artikel.einheitid;

      variante.tags = newArtikel.artikel.tags;
      variante.beschreibung = newArtikel.artikel.beschreibung;
      variante.isVariation = 1;

      for (const key in comp) {
        const eigenschaft = new ArtikelVariantenEigenschaft(0, 0, comp[key].identifier, key, comp[key].value, comp[key].artikelnummerextension);
        variante.variantenEigenschaften.push(eigenschaft);
        variante.artikelNr += eigenschaft.artikelnummerextension;
        variante.titel += ' ' + eigenschaft.value;
      }

      newArtikel.varianten.push(variante);
    });
  }

  function addArtikelVariante() {
    var variante = new Artikel();
    variante.titel = newArtikel.artikel.titel;
    variante.artikelNr = newArtikel.artikel.artikelNr;

    variante.anzahl = newArtikel.artikel.anzahl;
    variante.einheit = newArtikel.artikel.einheit;
    variante.einheitid = newArtikel.artikel.einheitid;

    variante.tags = newArtikel.artikel.tags;
    variante.isVariation = 1;
    variante.eigenschaften = newArtikel.artikel.eigenschaften;

    for (var prop of newArtikel.variantenEigenschaften) {
      variante.variantenEigenschaften.push(new ArtikelVariantenEigenschaft(0, 0, '', prop.key, '', ''));
    }

    newArtikel.varianten.push(variante);
  }

  function removeArtikelVariante(index: number) {
    newArtikel.varianten.splice(index, 1);
  }

  function deleteAllArtikelVarianten() {
    newArtikel.varianten = new Array<Artikel>();
  }
  //#endregion

  //#region Verbundartikel
  function addVerbundArtikel() {
    newArtikel.artikel.verbundArtikel.push(new VerbundArtikel());
  }

  function removeVerbundArtikel(index: number) {
    newArtikel.artikel.verbundArtikel.splice(index, 1);
  }
  //#endregion

  function newArtikelEditArtikeltitel() {
    if (newArtikel.varianten.length > 0) {
      newArtikel.varianten.forEach((variante) => {
        let newArtikeltitel = newArtikel.artikel.titel;
        variante.variantenEigenschaften.forEach((v) => {
          newArtikeltitel += ' ' + v.value;
        });
        variante.titel = newArtikeltitel;
      });
    }
  }

  function newArtikelEditArtikelnummer() {
    if (newArtikel.varianten.length > 0) {
      newArtikel.varianten.forEach((variante) => {
        let newArtikelnummer = newArtikel.artikel.artikelNr;
        variante.variantenEigenschaften.forEach((v) => {
          newArtikelnummer += v.artikelnummerextension;
        });
        variante.artikelNr = newArtikelnummer;
      });
    }
  }

  function variantenEigenschaftEditKey(index: number) {
    if (newArtikel.varianten.length > 0) {
      newArtikel.varianten.forEach((variante) => {
        variante.variantenEigenschaften[index].key = newArtikel.variantenEigenschaften[index].key;
      });
    }
  }

  function variantenEigenschaftEditValue(index: number, vindex: number) {
    if (newArtikel.varianten.length > 0) {
      newArtikel.varianten.forEach((variante) => {
        if (variante.variantenEigenschaften[index].identifier == newArtikel.variantenEigenschaften[index].values[vindex].identifier) {
          variante.variantenEigenschaften[index].value = newArtikel.variantenEigenschaften[index].values[vindex].value;

          // Titel anpassen
          let newTitel = newArtikel.artikel.titel;
          variante.variantenEigenschaften.forEach((v) => {
            newTitel += ' ' + v.value;
          });
          variante.titel = newTitel;
        }
      });
    }
  }

  function variantenEigenschaftEditArtikelnummerextionsion(index: number, vindex: number) {
    if (newArtikel.varianten.length > 0) {
      newArtikel.varianten.forEach((variante) => {
        if (variante.variantenEigenschaften[index].identifier == newArtikel.variantenEigenschaften[index].values[vindex].identifier) {
          variante.variantenEigenschaften[index].artikelnummerextension = newArtikel.variantenEigenschaften[index].values[vindex].artikelnummerextension;

          // Artikelnummer anpassen
          let newArtikelnummer = newArtikel.artikel.artikelNr;
          variante.variantenEigenschaften.forEach((v) => {
            newArtikelnummer += v.artikelnummerextension;
          });
          variante.artikelNr = newArtikelnummer;
        }
      });
    }
  }

  function setArtikeltemplate() {
    if (selectedArtikeltemplate == null) {
      clearInputs();
    } else {
      const template = JSON.parse(JSON.stringify(selectedArtikeltemplate.value)) as Artikeltemplate;
      console.log(template);
      newArtikel.artikel.titel = template.artikeltitel;
      newArtikel.artikel.artikelartid = template.artikelartid;
      newArtikel.artikel.artikelNr = template.artikelnummer;

      newArtikel.artikel.anzahl = template.anzahl;
      newArtikel.artikel.einheit = template.einheit;
      newArtikel.artikel.einheitid = template.einheitid;

      newArtikel.artikel.einheitid = template.einheitid;
      newArtikel.artikel.beschreibung = template.artikelbeschreibung;

      newArtikel.artikel.inProdZusammenfuehren = template.inProdZusammenfuehren;

      for (var tag of template.tags) {
        var newTag = new ArtikelTag();
        newTag.artikelid = newArtikel.artikel.id;
        newTag.tagid = tag.tagid;
        newArtikel.artikel.tags.push(newTag);
      }

      newArtikel.artikel.eigenschaften = JSON.parse(JSON.stringify(template.eigenschaften)) as Array<ArtikelEigenschaft>;
      for (var eigen1 of newArtikel.artikel.eigenschaften) {
        eigen1.id = 0;
      }

      // Varianteneigenschaften
      // const uniquekeys: Array<string> = [...new Set<string>(template.variantenEigenschaften.map((i: ArtikeltemplateVariantenEigenschaft) => i.key))];
      // for (var key of uniquekeys) {
      //   const eve = new EditVariantenEigenschaft(key);
      //   const eigenschaften = template.variantenEigenschaften.filter((e: ArtikeltemplateVariantenEigenschaft) => e.key == key);
      //   for (var eigen of eigenschaften) {
      //     eve.values.push(new EditVariantenEigenschaftValue(eigen.identifier, eigen.value, eigen.artikelnummerextension));
      //   }

      //   newArtikel.variantenEigenschaften.push(eve);
      // }

      newArtikel.varianten = new Array<Artikel>();
    }
  }

  function setVerbundartikelByName(name: string, index: number) {
    const art = artikel.value.find((a) => a.titel == name);
    if (art != null) {
      if (newArtikel.verbundArtikel[index] == null) {
        newArtikel.verbundArtikel.push(art);
      } else {
        newArtikel.verbundArtikel.splice(index, 1, art);
      }

      newArtikel.artikel.verbundArtikel[index].verbundArtikelid = art.id;
    }
  }

  function clearInputs() {
    newArtikel.artikel = new Artikel();
    newArtikel.variantenEigenschaften = new Array<EditVariantenEigenschaft>();
    newArtikel.defaulteinheit = -1;
    newArtikel.varianten = new Array<Artikel>();
    newArtikel.verbundArtikel = new Array<Artikel>();
  }

  function cancel() {
    clearInputs();
    router.push({ name: 'Artikel' });
  }

  function createArtikel() {
    // add varianteneigenschaften
    if (newArtikel.variantenEigenschaften.length > 0) {
      for (var eigenschaft of newArtikel.variantenEigenschaften) {
        for (var value of eigenschaft.values) {
          newArtikel.artikel.variantenEigenschaften.push(
            new ArtikelVariantenEigenschaft(0, 0, value.identifier, eigenschaft.key, value.value, value.artikelnummerextension)
          );
        }
      }
    }

    if (newArtikel.artikel.artikelartid == 2) {
      // for (var variante of this.newArtikel.varianten) {

      // }
      var data = new CreateVariantenArtikelRequest(newArtikel.artikel, newArtikel.varianten);
      store.dispatch(ArtikelActionTypes.CreateVariantenArtikel, data).then(() => {
        if (errorMsg.value == '') {
          cancel();
        }
      });
    }
    // if (this.newArtikel.artikel.artikelartid == 1) {
    else {
      store.dispatch(ArtikelActionTypes.CreateArtikel, newArtikel.artikel).then(() => {
        if (errorMsg.value == '') {
          cancel();
        }
      });
    }
  }

  onMounted(() => {
    if (artikeltemplates.value.length == 0) {
      store.dispatch(ArtikeltemplateActionTypes.GetArtikeltemplate, undefined);
    }

    if (artikelarten.value.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikelarten, undefined);
    }

    if (artikeleinheiten.value.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikelEinheit, undefined);
    }
  });
</script>

<style scoped>
  .footerbar {
    position: absolute;
    bottom: 0;
  }

  code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
</style>
