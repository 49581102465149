<template>
  <div class="d-flex justify-content-between">
    <h1>Artikel</h1>

    <button class="btn" @click="reloadArtikel()">
      <font-awesome-icon icon="rotate" />
    </button>
  </div>

  <div class="row">
    <div class="col">
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="select-tags-button" data-bs-toggle="dropdown" aria-expanded="false">
          Tags filtern...
        </button>
        <div class="dropdown-menu p-0" aria-labelledby="select-tags-button">
          <div v-for="(tag, index) in tags.sort((a: Tag, b: Tag) => a.key.localeCompare(b.key))" :key="index" class="dropdown-checkbox px-1 py-2 border-bottom">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" :id="'selected-herkunft-item-' + index" v-model="selectedTags" :value="tag.id" />
              <label class="form-check-label" :for="'selected-herkunft-item-' + index">{{ tag.key }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="input-group input-group-sm my-auto">
        <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche..." />
        <span class="input-group-text">
          <font-awesome-icon icon="magnifying-glass" class="p-1" />
        </span>
      </div>
    </div>

    <div class="col d-flex justify-content-end">
      <router-link :to="{ name: 'CreateArtikel' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
      <router-link :to="{ name: 'ImportArtikel' }" class="btn btn-outline-secondary ms-3"><font-awesome-icon icon="plus" /> Artikel importieren</router-link>
    </div>
  </div>

  <LoadingSpinner v-if="loading" text="... Artikel werden geladen ..." />

  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Artikel aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="artikel.length > 0" class="mt-4">
    <div class="row mx-1 mb-2 bg-white shadow-sm">
      <div class="col-2 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Artikel Nr.</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'artikelnrasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'artikelnrdesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('artikelnrasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'artikelnrasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('artikelnrdesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'artikelnrdesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-5 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Artikel</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-5 py-2"><strong>Tags</strong></div>
    </div>
    <div v-for="art in sortedArtikel" :key="art.artikel.id">
      <ArtikelRow :artikel="art" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && artikel.length == 0" class="alert alert-info mt-5">Es wurden keine Artikel gefunden.</div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from '@/store';

  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { ActionTypes as ArtikeltemplateActionTypes } from '@/store/modules/Artikeltemplates/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import ArtikelRow from '@/components/Artikel/ArtikelRow.vue';

  import { ArtikelTag, ArtikelTree, Tag } from '@/models/ArtikelModels';
  //#endregion

  const store = useStore();

  const loading = computed(() => store.getters.status.getArtikelLoading);
  const errorMsg = computed(() => store.getters.status.artikelErrorMsg);

  const tags = computed(() => store.getters.tags);
  const selectedTags = ref<Array<number>>([]);

  const searchString = ref('');

  const sortByValue = ref('artikelnrasc');
  function sortBy(param: string) {
    sortByValue.value = param;
  }

  const artikel = computed(() => store.getters.artikelTree);
  const sortedArtikel = computed(() => {
    return artikel.value
      .filter((a: ArtikelTree) =>
        selectedTags.value.length > 0 ? selectedTags.value.every((tagId: number) => a.artikel.tags.some((tag: ArtikelTag) => tag.tagid == tagId)) : true
      )
      .filter((a: ArtikelTree) => {
        return (
          a.artikel.artikelNr.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          a.artikel.titel.trim().toLowerCase().includes(searchString.value.trim().toLowerCase())
        );
      })
      .sort((a: ArtikelTree, b: ArtikelTree) => {
        if (sortByValue.value == 'titelasc' || sortByValue.value == 'titeldesc') {
          var comariosn = a.artikel.titel.trim().toLowerCase().localeCompare(b.artikel.titel.trim().toLowerCase());
          if (sortByValue.value == 'titeldesc') {
            comariosn = -1 * comariosn;
          }
          return comariosn;
        }
        if (sortByValue.value == 'artikelnrasc' || sortByValue.value == 'artikelnrdesc') {
          var comariosn = a.artikel.artikelNr.trim().toLowerCase().localeCompare(b.artikel.artikelNr.trim().toLowerCase());
          if (sortByValue.value == 'artikelnrdesc') {
            comariosn = -1 * comariosn;
          }
          return comariosn;
        }

        return 0;
      });
  });

  function reloadArtikel() {
    store.dispatch(ArtikelActionTypes.GetArtikel, undefined);
  }

  onMounted(() => {
    if (artikel.value.length == 0) {
      reloadArtikel();
    }

    if (store.getters.artikeltemplates.length == 0) {
      store.dispatch(ArtikeltemplateActionTypes.GetArtikeltemplate, undefined);
    }

    if (store.getters.tags.length == 0) {
      store.dispatch(ArtikelActionTypes.GetTags, undefined);
    }

    if (store.getters.artikeleinheiten.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikelEinheit, undefined);
    }
  });
</script>

<style scoped>
  .table-col:hover {
    background: rgb(216, 217, 222);
    cursor: pointer;
  }

  .table-sort-icon {
    font-size: 0.7em;
  }

  .select-sort:hover {
    cursor: pointer;
  }
</style>
