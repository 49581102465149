<template>
  <section class="mb-4 border border-secondary py-2">
    <div class="row bg-white shadow-sm py-2 px-1 mx-2 mb-3">
      <div class="col-4">
        <label :for="'neuer-lagerbereich-name-' + index" class="form-label">Bereich Name</label>
        <input type="text" class="form-control" :id="'neuer-lagerbereich-name-' + index" v-model="lagerbereich.name" required>
      </div>
      
      <div class="col-2">
        <label :for="'neuer-lagerbereich-art-' + index" class="form-label">Lagerart</label>
        <select class="form-select" :id="'neuer-lagerbereich-art-' + index" v-model="lagerbereich.lagerbereichartid" @change="clearSuccessors()">
          <!-- <option value="0">Bereich</option> -->
          <option v-for="lagerbereichart in lagerbereicharten" :key="lagerbereichart.id" :value="lagerbereichart.id">{{ lagerbereichart.name }}</option>
        </select>
      </div>

      <div class="col-6">
        <label :for="'neuer-lagerbereich-beschreibung-' + index" class="form-label">Beschreibung</label>
        <textarea class="form-control" :id="'neuer-lagerbereich-beschreibung-' + index" rows="3" v-model="lagerbereich.beschreibung"></textarea>
      </div>
    </div>

    <div class="ms-4">
      <CreateLagerbereichRow v-for="(bereich, index) in lagerbereich.lagerbereiche" :key="'lagerbereich-' + index" :lagerbereich="bereich" :index="index" :lagerortid="lagerortid" />
      
      <div v-if="lagerbereich.lagerbereichartid == 2 || lagerbereich.lagerbereichartid == 3 || lagerbereich.lagerbereichartid == 4" class="ms-4">
        <CreateLagerReiheRow v-for="(lagerreihe, idx) in lagerbereich.lagerreihen" :key="'lagerreihe-' + idx" :lagerreihe="lagerreihe" :index="idx" />
      </div>


      <button v-if="lagerbereich.lagerbereichartid == 1" type="button" class="btn btn-secondary" @click="addUnterbereich()"><font-awesome-icon icon="plus" /> {{ lagerbereich.name }} Unterbereich hinzufügen</button>
      <button v-else type="button" class="btn btn-secondary" @click="addLagerreihe()"><font-awesome-icon icon="plus" /> Lagerreihe hinzufügen</button>
      <!-- <button v-if="lagerbereich.lagerbereichartid == 2" type="button" class="btn btn-secondary" @click="addLagerreihe()"><font-awesome-icon icon="plus" /> {{ lagerbereich.name }} Lagerreihe hinzufügen</button>
      <button v-if="lagerbereich.lagerbereichartid == 3" type="button" class="btn btn-secondary disabled" @click="addUnterbereich()"><font-awesome-icon icon="plus" /> {{ lagerbereich.name }} Hochregallager Regal hinzufügen</button>
      <button v-if="lagerbereich.lagerbereichartid == 4" type="button" class="btn btn-secondary disabled" @click="addUnterbereich()"><font-awesome-icon icon="plus" /> {{ lagerbereich.name }} Verschieberegal Regal hinzufügen</button> -->
    </div>

  </section>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { CreateLagerbereichRequest, CreateLagerreiheRequest, Lagerbereich, Lagerbereichart, Lagerreihe } from '@/models/LagerModels'

import CreateLagerReiheRow from '@/components/Lager/Lager/CreateLagerReiheRow.vue'
import { useStore } from 'vuex';

export default defineComponent({
  name: "CreateLagerbereichRow",

  components: {
    CreateLagerReiheRow
  },

  props: {
    lagerbereich: {
      type: Object as PropType<CreateLagerbereichRequest>,
      required: true
    },

    index: {
      type: Number,
      required: true
    },

    lagerortid: {
      type: Number,
      required: true
    }
  },

  setup (props) {
    const store = useStore();

    const lagerbereicharten = computed(() => store.getters.lagerbereicharten);
    // lagerbereicharten.push(new Lagerbereichart(1, "Lager"));
    // lagerbereicharten.push(new Lagerbereichart(2, "Hochregallager"));
    // lagerbereicharten.push(new Lagerbereichart(3, "Verschieberegalanlage"));

    function addUnterbereich() {
      props.lagerbereich.lagerbereiche.push(new CreateLagerbereichRequest(props.lagerortid))
    }

    function addLagerreihe() {
      props.lagerbereich.lagerreihen.push(new CreateLagerreiheRequest(props.lagerbereich.id))
    }

    function clearSuccessors() {
      props.lagerbereich.lagerbereiche = new Array<CreateLagerbereichRequest>();
      props.lagerbereich.lagerreihen = new Array<CreateLagerreiheRequest>();
    }

    return {
      store,
      lagerbereicharten,
      addUnterbereich,
      addLagerreihe,
      clearSuccessors
    }
  }
});
</script>