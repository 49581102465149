<template>
  <div class="mb-2 bg-white shadow-sm">
    <div class="row mx-1 py-2">
      <div class="col-1 my-auto">{{ bestand.artikelnummer }}</div>
      <div class="col-2 my-auto text-truncate" :title="bestand.artikeltitel">{{ bestand.artikeltitel }}</div>
      <div class="col-2 my-auto">{{ bestand.barcode }}</div>

      <div class="col-2 my-auto d-flex flex-wrap">
        <span class="border border-dark py-1 px-2 me-1">{{ bestand.anzahl }} {{ bestand.einheit?.name }}</span>
      </div>

      <div class="col-1 my-auto">{{ lagerort?.name }}</div>
      <div class="col-1 my-auto">{{ lagerbereich?.name }}</div>
      <div class="col-1 my-auto">{{ lagerreihe?.reihe }}</div>
      <div class="col-1 my-auto">{{ lagerplatz?.platz }}</div>
      <div class="col-1 my-auto">
        <button class="btn btn-primary m-0 float-end dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <font-awesome-icon icon="pen-to-square" class="fa-sm" />
        </button>

        <ul class="dropdown-menu m-0 p-0 rounded-0">
          <li class="dropdown-item" @click="showBestandUmlagernModal()">
            <span v-if="lagerbereich?.lagerbereichartid == 5 || lagerbereich?.lagerbereichartid == 7" role="button">Einlagern</span>
            <span v-else role="button">Umlagern</span>
          </li>

          <li class="dropdown-item" role="button" @click="openBestandDetails()">Details</li>
        </ul>
      </div>
    </div>

    <div v-if="lagerReservierung != null" class="row mx-1 py-1 text-success">
      <div class="col-1 my-auto"></div>
      <div class="col-3 my-auto"></div>
      <div class="col-1 my-auto"></div>
      <div class="col-1 my-auto"></div>
      <div class="col-1 my-auto">Reservierung:</div>
      <div class="col-1 my-auto">{{ lagerReservierungLagerort?.name }}</div>
      <div class="col-1 my-auto">{{ lagerReservierungLagerbereich?.name }}</div>
      <div class="col-1 my-auto">{{ lagerReservierungLagerreihe?.reihe }}</div>
      <div class="col-1 my-auto">{{ lagerReservierungLagerplatz?.platz }}</div>
      <div class="col-1 my-auto"></div>
    </div>
  </div>

  <BestandUmlagernModal :bestand="bestand" ref="umlagernModalRef" />
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed, ref } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { ArtikelBestand } from '@/models/BestandModels';
  import { Lagerort, Lagerbereich, Lagerreihe, Lagerplatz } from '@/models/LagerModels';
  import { LagerReservierung } from '@/models/LagerReservierungModels';
  import BestandUmlagernModal from './BestandUmlagernModal.vue';
  //#endregion

  const { bestand } = defineProps({
    bestand: {
      type: Object as PropType<ArtikelBestand>,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == bestand.lagerortid));
  const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == bestand.lagerbereichid));
  const lagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == bestand.lagerreiheid));
  const lagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == bestand.lagerplatzid));

  const lagerReservierung = computed(() => store.getters.lagerReservierungen.find((lr: LagerReservierung) => lr.bestandid == bestand.id));
  const lagerReservierungLagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == lagerReservierung.value?.lagerortid));
  const lagerReservierungLagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == lagerReservierung.value?.lagerbereichid));
  const lagerReservierungLagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == lagerReservierung.value?.lagerreiheid));
  const lagerReservierungLagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == lagerReservierung.value?.lagerplatzid));

  const umlagernModalRef = ref<typeof BestandUmlagernModal>();
  function showBestandUmlagernModal() {
    if (umlagernModalRef.value) {
      umlagernModalRef.value.showModal();
    }
  }

  function openBestandDetails() {
    router.push({ name: 'DetailsBestand', params: { id: bestand.id } });
  }
</script>
