import { MutationTree } from 'vuex';
import { State } from './state';
import { Auftrag, AuftragStatus } from '@/models/AuftragModels';

export enum MutationTypes {
  SetAuftrag = 'SETAUFTRAG',
  AddOrUpdateAuftrag = 'ADDORUPDATEAUFTRAG',
  AddOrUpdateAuftraege = 'ADDORUPDATEAUFTRAEGE',
  DeleteAuftrag = 'DELETEAUFTRAGD',

  SetAuftragStatus = 'SETAUFTRAGSTATUS',
  AddAuftragStatus = 'ADDAUFTRAGSTATUS',
  UpdateAuftragStatus = 'UPDATEAUFTRAGSTATUS',
  DeleteAuftragStatus = 'DELETEAUFTRAGDSTATUS',
}

export type Mutations = {
  [MutationTypes.SetAuftrag](state: State, data: Array<Auftrag>): void;
  [MutationTypes.AddOrUpdateAuftrag](state: State, data: Auftrag): void;
  [MutationTypes.AddOrUpdateAuftraege](state: State, data: Array<Auftrag>): void;
  [MutationTypes.DeleteAuftrag](state: State, id: number): void;

  [MutationTypes.SetAuftragStatus](state: State, data: Array<AuftragStatus>): void;
  [MutationTypes.AddAuftragStatus](state: State, data: AuftragStatus): void;
  [MutationTypes.UpdateAuftragStatus](state: State, data: AuftragStatus): void;
  [MutationTypes.DeleteAuftragStatus](state: State, id: number): void;
};

export const mutations: MutationTree<State> & Mutations = {
  //#region Auftrag
  [MutationTypes.SetAuftrag](state: State, data: Array<Auftrag>) {
    state.auftraege = data;
    sessionStorage.setItem('auftraege', JSON.stringify(state.auftraege));
  },

  [MutationTypes.AddOrUpdateAuftrag](state: State, data: Auftrag) {
    const index = state.auftraege.findIndex((a: Auftrag) => a.id === data.id);
    if (index != -1) {
      state.auftraege.splice(index, 1, data);
    } else {
      state.auftraege.push(data);
    }

    sessionStorage.setItem('auftraege', JSON.stringify(state.auftraege));
  },

  [MutationTypes.AddOrUpdateAuftraege](state: State, data: Array<Auftrag>) {
    for (var auftrag of data) {
      const index = state.auftraege.findIndex((a: Auftrag) => a.id === auftrag.id);
      if (index != -1) {
        state.auftraege.splice(index, 1, auftrag);
      } else {
        state.auftraege.push(auftrag);
      }
    }

    sessionStorage.setItem('auftraege', JSON.stringify(state.auftraege));
  },

  [MutationTypes.DeleteAuftrag](state: State, id: number) {
    const olddata = state.auftraege.find((a) => a.id === id);
    if (olddata != null) {
      const index = state.auftraege.indexOf(olddata);
      state.auftraege.splice(index, 1);
    }

    sessionStorage.setItem('auftraege', JSON.stringify(state.auftraege));
  },
  //#endregion

  //#region AuftragStatus
  [MutationTypes.SetAuftragStatus](state: State, data: Array<AuftragStatus>) {
    state.auftragstatus = data;
    sessionStorage.setItem('auftragstatus', JSON.stringify(state.auftragstatus));
  },

  [MutationTypes.AddAuftragStatus](state: State, data: AuftragStatus) {
    state.auftragstatus.push(data);
    sessionStorage.setItem('auftragstatus', JSON.stringify(state.auftragstatus));
  },

  [MutationTypes.UpdateAuftragStatus](state: State, data: AuftragStatus) {
    const olddata = state.auftragstatus.find((a) => a.id === data.id);
    if (olddata != null) {
      const index = state.auftragstatus.indexOf(olddata);
      state.auftragstatus.splice(index, 1, data);
    }

    sessionStorage.setItem('auftragstatus', JSON.stringify(state.auftragstatus));
  },

  [MutationTypes.DeleteAuftragStatus](state: State, id: number) {
    const olddata = state.auftragstatus.find((a) => a.id === id);
    if (olddata != null) {
      const index = state.auftragstatus.indexOf(olddata);
      state.auftragstatus.splice(index, 1);
    }

    sessionStorage.setItem('auftragstatus', JSON.stringify(state.auftragstatus));
  },
  //#endregion
};
