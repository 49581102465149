import { ArtikelEinheit } from './ArtikelModels';

export class ArtikelBestand {
  id: number;
  lieferungid: number | null;
  lieferungposid: number | null;

  auftragid: number | null;
  auftragposid: number | null;
  auslieferungsdatum: Date | null;

  artikelid: number;
  artikelnummer: string;
  artikeltitel: string;
  artikeldaten: Array<ArtikelBestandEigenschaft>;

  anzahl: number;
  einheit: ArtikelEinheit | null;
  einheitid: number | null;

  tags: Array<BestandTag>;
  barcode: string;
  lieferdatum: Date;
  geliefert: number;

  inProdZusammenfuehren: boolean;

  inTransport: boolean;
  lagerortid: number | null;
  lagerbereichid: number | null;
  lagerreiheid: number | null;
  lagerplatzid: number | null;

  constructor() {
    this.id = 0;
    this.lieferungid = null;
    this.lieferungposid = null;

    this.auftragid = null;
    this.auftragposid = null;
    this.auslieferungsdatum = null;

    this.artikelid = 0;
    this.artikelnummer = '';
    this.artikeltitel = '';
    this.artikeldaten = new Array<ArtikelBestandEigenschaft>();

    this.anzahl = 1;
    this.einheit = null;
    this.einheitid = null;

    this.tags = new Array<BestandTag>();
    this.barcode = '';
    this.lieferdatum = new Date();
    this.geliefert = 0;

    this.inProdZusammenfuehren = false;

    this.inTransport = false;
    this.lagerortid = null;
    this.lagerbereichid = null;
    this.lagerreiheid = null;
    this.lagerplatzid = null;
  }
}

export class BestandTag {
  id: number;
  artikelbestandid: number;
  tagid: number;

  constructor() {
    this.id = 0;
    this.artikelbestandid = 0;
    this.tagid = 0;
  }
}

export class ArtikelBestandEigenschaft {
  id: number;
  artikelbestandid: number;
  key: string;
  value: string;

  constructor(key: string, value: string) {
    this.id = 0;
    this.artikelbestandid = 0;
    this.key = key;
    this.value = value;
  }
}

export class ArtikelBestandBewegung {
  id: number;
  beschreibung: string;

  artikelid: number;
  artikelbestandid: number;

  operator: string;
  anzahl: number;
  gesamtanzahl: number;
  einheitid: number;

  vonLagerortid: number | null;
  vonLagerbereichid: number | null;
  vonLagerreiheid: number | null;
  vonLagerplatzid: number | null;

  zuLagerortid: number | null;
  zuLagerbereichid: number | null;
  zuLagerreiheid: number | null;
  zuLagerplatzid: number | null;

  auftragid: number | null;
  produktionsauftragid: number | null;

  adate: Date;

  benutzerId: number;

  constructor() {
    this.id = 0;
    this.beschreibung = '';

    this.artikelid = 0;
    this.artikelbestandid = 0;

    this.operator = '';
    this.anzahl = 0;
    this.gesamtanzahl = 0;
    this.einheitid = 0;

    this.vonLagerortid = null;
    this.vonLagerbereichid = null;
    this.vonLagerreiheid = null;
    this.vonLagerplatzid = null;

    this.zuLagerortid = null;
    this.zuLagerbereichid = null;
    this.zuLagerreiheid = null;
    this.zuLagerplatzid = null;

    this.auftragid = null;
    this.produktionsauftragid = null;

    this.adate = new Date();

    this.benutzerId = 0;
  }
}

export class ArtikelBestandAuslagernRequest {
  anzahl: number;
  artikelbestandid: number | null;
  barcode: string;
  artikelnr: string;
  lagerortid: number | null;
  lagerbereichid: number | null;

  constructor() {
    this.anzahl = 1;
    this.artikelbestandid = null;
    this.barcode = '';
    this.artikelnr = '';
    this.lagerortid = null;
    this.lagerbereichid = null;
  }
}

export class ArtikelBestandUmlagernRequest {
  artikelbestandid: number | null;
  barcode: string;
  anzahl: number | null;
  neuerBarcode: string;
  inTranport: boolean;

  artikelnr: string;
  vonlagerortid: number | null;
  vonlagerbereichid: number | null;
  vonlagerreiheid: number | null;
  vonlagerplatzid: number | null;

  lagerortid: number | null;
  lagerbereichid: number | null;
  lagerreiheid: number | null;
  lagerplatzid: number | null;

  constructor() {
    this.artikelbestandid = null;
    this.barcode = '';
    this.anzahl = null;
    this.neuerBarcode = '';
    this.inTranport = false;

    this.artikelnr = '';
    this.vonlagerortid = null;
    this.vonlagerbereichid = null;
    this.vonlagerreiheid = null;
    this.vonlagerplatzid = null;

    this.lagerortid = null;
    this.lagerbereichid = null;
    this.lagerreiheid = null;
    this.lagerplatzid = null;
  }
}

export interface ArtikelBestandUmlagernResponse {
  error: boolean;
  message: string;
  bestaende: Array<ArtikelBestand>;
}

export class CreateArtikelBestandRequest {
  artikelid: number | null;
  artikelnr: string;
  barcode: string;
  anzahl: number;
  lagerortid: number | null;
  lagerbereichid: number | null;

  constructor() {
    this.artikelid = null;
    this.artikelnr = '';
    this.barcode = '';
    this.anzahl = 0;
    this.lagerortid = null;
    this.lagerbereichid = null;
  }
}
