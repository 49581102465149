<template>
  <div class="py-2 mb-2 shadow-sm bg-white shadow-sm">
    <div class="row mx-1">
      <div class="col-1 my-auto">
        <strong>#{{ artikel.artikelNr }}</strong>
      </div>

      <div class="col-2 my-auto">
        {{ artikel.titel }}
      </div>

      <div class="col-8 my-auto">
        <div class="d-flex justify-content-start">
          <div v-for="(eigenschaft, index) in artikel.variantenEigenschaften" :key="index" class="me-2">
            <select class="form-select" v-model="artikel.variantenEigenschaften[index]" @change="editArtikeldata()">
              <option :value="{ identifier: '', key: eigenschaft.key, value: '', artikelnummerextension: '' }">{{ eigenschaft.key }} auswählen...</option>
              <option v-for="(value, windex) in eigenschaftenOptions[eigenschaft.key]" :key="windex" :value="value">{{ value['value'] }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-1 my-auto">
        <button
          type="button"
          class="btn btn-primary m-0 float-end"
          data-bs-toggle="collapse"
          :data-bs-target="'#artikeldetails-' + index"
          aria-expanded="false"
          :aria-controls="'artikeldetails-' + index"
        >
          <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
        </button>
      </div>
    </div>

    <div class="collapse px-2" :id="'artikeldetails-' + index">
      <hr class="my-3" />
      <p><strong>Titel:</strong> {{ artikel.titel }}</p>

      <!-- Tags -->
      <!-- <div class="my-3">
        <p class="fw-bold">Tags</p>

        <div class="d-flex flex-row mb-3 flex-wrap">
          <p v-for="tag in artikel.tags" :key="tag.id" class="lead me-2">
            <span class="badge text-bg-primary">{{ tags.find((t: Tag) => t.id == tag.tagid)?.key }}</span>
          </p>
        </div>
      </div> -->

      <p>
        <strong>Beschreibung:</strong>
      </p>
      <pre>{{ artikel.beschreibung }}</pre>

      <code>
        {{ JSON.stringify(artikel, null, 2) }}
      </code>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed } from 'vue';
  // import { useStore } from '@/store';

  import { UpdateArtikelRequest, ArtikelVariantenEigenschaft } from '@/models/ArtikelModels';
  import { EditVariantenEigenschaft } from '@/models/ArtikelModels';
  //#endregion

  const props = defineProps({
    artikelnummer: {
      type: String,
      required: true,
    },
    artikeltitel: {
      type: String,
      required: true,
    },
    artikel: {
      type: Object as PropType<UpdateArtikelRequest>,
      required: true,
    },
    variantenEigenschaften: {
      type: Object as PropType<Array<EditVariantenEigenschaft>>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  });

  // const store = useStore();

  // const tags = computed(() => store.getters.tags);

  function getAllCombinations(input: Array<EditVariantenEigenschaft>): any {
    const eigenschaften: any = {};
    for (let eigenschaft of input) {
      eigenschaften[eigenschaft.key] = new Array<ArtikelVariantenEigenschaft>();
      eigenschaft.values.forEach((val) => {
        eigenschaften[eigenschaft.key].push(new ArtikelVariantenEigenschaft(0, 0, val.identifier, eigenschaft.key, val.value, val.artikelnummerextension));
      });
    }

    return eigenschaften;
  }

  const eigenschaftenOptions = computed(() => getAllCombinations(props.variantenEigenschaften));

  function editArtikeldata() {
    let newArtikelnummer = props.artikelnummer;
    let newArtikeltitel = props.artikeltitel;

    props.artikel.variantenEigenschaften.forEach((variante) => {
      newArtikelnummer += variante.artikelnummerextension;
      newArtikeltitel += ' ' + variante.value;
    });

    props.artikel.artikelNr = newArtikelnummer;
    props.artikel.titel = newArtikeltitel;
  }
</script>

<style scoped>
  .artikel-row:hover {
    cursor: pointer;
    color: #2a579b;
    background-color: #dfe9f8 !important;
  }

  code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
</style>
