<template>
  <div class="d-flex justify-content-between">
    <h1>Bestand</h1>

    <button class="btn" @click="reloadBestand()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex my-3">
    <router-link :to="{ name: 'CreateBestand' }" class="btn btn-primary me-2"><font-awesome-icon icon="plus" /> Hinzufügen</router-link>
    <!-- <button class="btn btn-secondary me-2 disabled">Button 2</button>
    <button class="btn btn-secondary me-2 disabled">Button 3</button>
    <button class="btn btn-secondary me-2 disabled">Button 4</button> -->
  </div>

  <div v-if="errorMsg != ''" class="alert alert-danger mt-5">Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.</div>

  <div class="d-flex my-3 p-2 bg-white shadow justify-content-between">
    <div class="d-flex">
      <select class="form-select me-3" aria-label="Lagerort" v-model="selectedLagerort">
        <option :value="null">Alle Standorte</option>
        <option v-for="lagerort in lagerorte" :key="lagerort.id" :value="lagerort">{{ lagerort.name }}</option>
      </select>

      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Tags auswählen...</button>
        <div class="dropdown-menu p-0">
          <div v-for="tag in tags" :key="tag.id" class="dropdown-checkbox px-1 py-2 border-bottom">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" :value="tag" :id="'tag-select-' + tag.id" v-model="selectedTags" />
              <label class="form-check-label" :for="'tag-select-' + tag.id">
                {{ tag.key }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="input-group w-50">
      <input type="text" class="form-control" placeholder="Suche" aria-label="Suche" aria-describedby="suche" v-model="filter" />
      <span class="input-group-text" id="basic-addon1"><font-awesome-icon icon="magnifying-glass" /></span>
    </div>
  </div>

  <div v-if="artikelBestand.length > 0">
    <div class="bg-white shadow mb-2">
      <div class="row px-2">
        <div class="col-2 table-col py-2">
          <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="fw-bold me-1">ArtikelNr</span>
            <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'artikelnrasc'" class="text-secondary table-sort-icon" />
            <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'artikelnrdesc'" class="text-secondary table-sort-icon" />

            <ul class="dropdown-menu m-0 p-0 rounded-0">
              <li class="dropdown-item select-sort" @click="sortBy('artikelnrasc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'artikelnrasc'" />
                  </div>
                  <div class="col">0-9</div>
                </div>
              </li>
              <li class="dropdown-item select-sort" @click="sortBy('artikelnrdesc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'artikelnrdesc'" />
                  </div>
                  <div class="col">9-0</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-3 table-col py-2">
          <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="fw-bold me-1">Artikel</span>
            <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'artikelasc'" class="text-secondary table-sort-icon" />
            <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'artikeldesc'" class="text-secondary table-sort-icon" />

            <ul class="dropdown-menu m-0 p-0 rounded-0">
              <li class="dropdown-item select-sort" @click="sortBy('artikelasc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'artikelasc'" />
                  </div>
                  <div class="col">A-Z</div>
                </div>
              </li>
              <li class="dropdown-item select-sort" @click="sortBy('artikeldesc')">
                <div class="row">
                  <div class="col-2">
                    <font-awesome-icon icon="check" v-show="sortByValue == 'artikeldesc'" />
                  </div>
                  <div class="col">Z-A</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6 fw-bold my-auto">Anzahl/Einheit</div>
        <div class="col-1"></div>
      </div>
    </div>

    <ArtikelnummerBestandRow
      v-for="group in sortedAndFilteredGroupedArtikelBestand"
      :key="group.artikelid"
      :artikelnummer="group.artikelnr"
      :bestaende="group.bestand"
    />
  </div>

  <div v-else-if="!loading && errorMsg == '' && artikelBestand.length == 0" class="alert alert-info mt-5">Es wurden keine Bestände gefunden.</div>

  <LoadingOverlay v-if="loading" />
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from '@/store';
  import { ActionTypes as ArtikelbestandActionTypes } from '@/store/modules/Bestand/actions';
  import { ActionTypes as LagerReservierungActionTypes } from '@/store/modules/LagerReservierung/actions';

  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  // import BestandRow from '@/components/Bestand/BestandRow.vue';
  import ArtikelnummerBestandRow from '@/components/Bestand/ArtikelnummerBestandRow.vue';

  import { Tag } from '@/models/ArtikelModels';
  import { ArtikelBestand } from '@/models/BestandModels';
  import { Lagerort } from '@/models/LagerModels';
  //#endregion

  const store = useStore();

  const loading = computed(
    () =>
      store.getters.status.getBestandLoading ||
      store.getters.status.createBestandLoading ||
      store.getters.status.updateBestandLoading ||
      store.getters.status.deleteBestandLoading
  );
  const errorMsg = computed(() => store.getters.status.bestandErrorMsg);

  const lagerorte = computed(() => store.getters.lagerorte);
  const tags = computed(() => store.getters.tags);

  const selectedLagerort = ref<Lagerort | null>(null);
  const selectedTags = ref<Array<Tag>>([]);

  const artikelBestand = computed(() => {
    if (selectedLagerort.value != null && selectedTags.value.length == 0) {
      return store.getters.bestand.filter((a: ArtikelBestand) => a.geliefert > 0 && a.lagerortid == selectedLagerort.value?.id);
    } else if (selectedLagerort.value == null && selectedTags.value.length > 0) {
      return store.getters.bestand.filter(
        (a: ArtikelBestand) => a.geliefert > 0 && a.tags.map((t) => t.tagid).some((t) => selectedTags.value.map((t) => t.id).includes(t))
      );
    } else if (selectedLagerort.value != null && selectedTags.value.length > 0) {
      return store.getters.bestand.filter(
        (a: ArtikelBestand) =>
          a.geliefert > 0 &&
          a.lagerortid == selectedLagerort.value?.id &&
          a.tags.map((t) => t.tagid).some((t) => selectedTags.value.map((t) => t.id).includes(t))
      );
    } else {
      return store.getters.bestand.filter((a: ArtikelBestand) => a.geliefert > 0);
    }
  });

  const groupedArtikelBestand = computed(() => {
    const result = new Array<{ artikelid: number; artikelnr: string; artikel: string; bestand: Array<ArtikelBestand> }>();

    for (var bestand of artikelBestand.value) {
      const index = result.findIndex((r) => r.artikelid == bestand.artikelid);
      if (index == -1) {
        result.push({
          artikelid: bestand.artikelid,
          artikelnr: bestand.artikelnummer,
          artikel: bestand.artikeltitel,
          bestand: new Array<ArtikelBestand>(bestand),
        });
      } else {
        result[index].bestand.push(bestand);
      }
    }

    return result;
  });

  const sortByValue = ref('artikelnrasc');
  // const sortAsc = ref(true);
  const filter = ref('');

  function sortBy(param: string) {
    sortByValue.value = param;
  }

  const sortedAndFilteredGroupedArtikelBestand = computed(() => {
    return groupedArtikelBestand.value
      .filter(
        (a) =>
          a.artikelnr.trim().toLowerCase().includes(filter.value.trim().toLowerCase()) ||
          a.artikel.trim().toLowerCase().includes(filter.value.trim().toLowerCase())
      )
      .sort((a, b) => {
        if (sortByValue.value == 'artikelnrdesc' || sortByValue.value == 'artikelnrasc') {
          var artikelnrComparison = a.artikelnr.trim().toLowerCase().localeCompare(b.artikelnr.trim().toLowerCase());
          if (sortByValue.value == 'artikelnrdesc') {
            artikelnrComparison = -1 * artikelnrComparison;
          }

          if (artikelnrComparison == 0) {
            var artikelComparison = a.artikel.toString().localeCompare(b.artikel.toString());
            return artikelComparison;
          }

          return artikelnrComparison;
        } else if (sortByValue.value == 'artikeldesc' || sortByValue.value == 'artikelasc') {
          var artikelComparison = a.artikel.trim().toLowerCase().localeCompare(b.artikel.trim().toLowerCase());
          if (sortByValue.value == 'artikeldesc') {
            artikelComparison *= -1;
          }

          if (artikelComparison == 0) {
            var artikelnrComparison = a.artikelnr.toString().localeCompare(b.artikelnr.toString());
            return artikelnrComparison;
          }

          return artikelComparison;
        }

        return 0;
      });
  });

  function reloadBestand() {
    store.dispatch(ArtikelbestandActionTypes.GetBestand, undefined);
    store.dispatch(LagerReservierungActionTypes.GetLagerReservierung, undefined);
  }

  onMounted(() => {
    reloadBestand();
  });
</script>

<style scoped>
  .table-col:hover {
    background: rgb(216, 217, 222);
  }

  .table-sort-icon {
    font-size: 0.7em;
  }

  .select-sort:hover {
    cursor: pointer;
  }
</style>
