<template>
  <div v-if="kunde == null" class="alert alert-warning">Ungültige Kunden Nr.: {{ id }}</div>

  <div v-else>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToKunden()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>Details</h1>
      <button type="button" class="btn" @click="openEditKunde()" title="Kunde bearbeiten"><font-awesome-icon icon="pen" /></button>
    </div>

    <p><strong>Firma</strong>: {{ kunde.firma }}</p>
    <p><strong>Name</strong>: {{ kunde.vorname }} {{ kunde.name }}</p>
    <p><strong>E-Mail</strong>: {{ kunde.email }}</p>
    <p><strong>Tel</strong>: {{ kunde.tel }}</p>

    <hr class="my-4" />

    <div class="row mb-4">
      <div class="col">
        <h4>Lieferadresse</h4>
        <p><strong>Straße</strong>: {{ kunde.lieferstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde.lieferplz }} {{ kunde.lieferort }}</p>
        <p><strong>Land</strong>: {{ kunde.lieferland }}</p>
      </div>

      <div class="col">
        <h4>Rechnungsadresse</h4>
        <p><strong>Straße</strong>: {{ kunde.rechnungstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde.rechnungplz }} {{ kunde.rechnungort }}</p>
        <p><strong>Land</strong>: {{ kunde.rechnungland }}</p>
      </div>
    </div>

    <hr class="my-4" />

    <h3>Aufträge</h3>

    <div v-if="auftraege.length > 0" class="mt-2">
      <div class="row mx-1 mb-2">
        <div class="col-2"><strong>Auftrag Nr.</strong></div>
        <div class="col-2"><strong>Firma</strong></div>
        <div class="col-3"><strong>Name</strong></div>
        <div class="col-1"><strong>Status</strong></div>
        <div class="col-2"><strong>Lieferdatum</strong></div>
        <div class="col-1"><strong>Rechnungsdatum</strong></div>
        <div class="col-1"></div>
      </div>

      <div v-for="auftrag in auftraege" :key="auftrag.id">
        <AuftragRow :auftrag="auftrag" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, onMounted } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';

  import { Kunde } from '@/models/KundeModels';
  import { Auftrag } from '@/models/AuftragModels';

  import AuftragRow from '@/components/Auftrag/AuftragRow.vue';

  const props = defineProps({
    id: {
      type: String,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const kunde = computed(() => store.getters.kunden.find((k: Kunde) => k.id == parseInt(props.id)));

  const auftraege = computed(() => store.getters.auftraege.filter((a: Auftrag) => a.kundeid == parseInt(props.id)));

  function returnToKunden() {
    router.push({ name: 'Kunden' });
  }

  function openEditKunde() {
    router.push({ name: 'EditKunde', params: { id: props.id } });
  }

  onMounted(() => {
    if (kunde.value) {
      store.dispatch(AuftragActionTypes.GetAllKundeAuftraege, kunde.value.id);
    }
  });
</script>
