import { RootState } from '@/store';

import { GetterTree } from 'vuex';
import { State } from './state';
import { ArtikelBestand, ArtikelBestandBewegung } from '@/models/BestandModels';
import { Lieferung, LieferungStatus } from '@/models/LieferungModels';

export type Getters = {
  bestand(state: State): Array<ArtikelBestand>;
  lieferungen(state: State): Array<Lieferung>;
  lieferungstatus(state: State): Array<LieferungStatus>;
  bestandBewegungen(state: State): Array<ArtikelBestandBewegung>;
};

export const getters: GetterTree<State, RootState> & Getters = {
  bestand(state: State) {
    return state.bestand;
  },

  lieferungen(state: State) {
    return state.lieferungen;
  },

  lieferungstatus(state: State) {
    return state.lieferungstatus;
  },

  bestandBewegungen(state: State) {
    return state.bestandBewegungen;
  },
};
