<template>
  <div class="row bg-white shadow-sm py-2 px-1 mx-2 mb-3">
    <div class="col-2 my-auto">
      <strong>Reihe: </strong> {{ lagerreihe.reihe }}
    </div>
    
    <div class="col">
      <label :for="'neue-lagerreihe-anzahl-plaetze-' + index" class="form-label">Anzahl Plätze {{ anzahlPlaetze }}</label>
      <input type="number" class="form-control" :id="'neue-lagerreihe-anzahl-plaetze-' + index" v-model="anzahlPlaetze" @input="generateLagerplaetze()">
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { CreateLagerreiheRequest, Lagerreihe, Lagerplatz } from '@/models/LagerModels';

export default defineComponent({
  name: "CreatLagerReihe",

  props: {
    lagerreihe: {
      type: Object as PropType<CreateLagerreiheRequest>,
      required: true
    },

    index: {
      type: Number,
      required: true
    },
  },

  setup (props) {
    props.lagerreihe.reihe = props.index + 1

    const anzahlPlaetze = ref(0)

    function generateLagerplaetze() {
      props.lagerreihe.lagerplaetze = new Array<Lagerplatz>();

      for (let i = 0; i < anzahlPlaetze.value; i++) {
        props.lagerreihe.lagerplaetze.push(new Lagerplatz(props.lagerreihe.id, i + 1))
      }
    }

    return {
      anzahlPlaetze,
      generateLagerplaetze
    }
  }
});
</script>
