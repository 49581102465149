<template>
  <div class="d-flex justify-content-between">
    <h1>Neuer Warenzugang</h1>
  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <form v-else @submit.prevent="createWarenzugang()" autocomplete="off">
    <h3>Lieferant</h3>
    <div class="row mb-3">
      <div class="col">
        <label for="lieferant-nr" class="form-label">Lieferant Nr.</label>
        <AutocompleteInputSelect
          :id="'lieferant-nr'"
          ref="lieferantIdInputRef"
          :select-list="lieferantenNrList"
          :inputValue="kundeInput.id"
          @set-value="setLieferantById"
        />
      </div>

      <div class="col">
        <label for="lieferant-firma" class="form-label">Firma</label>
        <AutocompleteInputSelect
          :id="'lieferant-firma'"
          ref="lieferantFirmaInputRef"
          :select-list="lieferantenFirmaList"
          :inputValue="kundeInput.firma"
          @set-value="setLieferantByFirma"
        />
      </div>

      <div class="col">
        <label for="lieferant-name" class="form-label">Name</label>
        <AutocompleteInputSelect
          :id="'lieferant-name'"
          ref="lieferantNameInputRef"
          :select-list="lieferantenNameList"
          :inputValue="kundeInput.name"
          @set-value="setLieferantByName"
        />
      </div>
    </div>

    <div class="mb-3">
      <label for="Notizen" class="form-label">Notizen</label>
      <textarea class="form-control" rows="3" v-model="neueLieferung.lieferung.notizen"></textarea>
    </div>

    <hr class="my-4" />
    <h3>Lieferdatum</h3>
    <div class="mb-3">
      <label for="date-input" class="form-label">Erwartetes Lieferdatum</label>
      <input type="date" class="form-control" id="date-input" v-model="lieferdatumDateConverter" />
    </div>

    <hr class="my-4" />
    <h3>Ziellager</h3>
    <div class="row mb-3">
      <div class="col-4">
        <div class="mb-3">
          <label for="lagerort-input" class="form-label">Lagerort</label>
          <AutocompleteInputSelect
            id="lagerort-input"
            ref="lagerortInput"
            :select-list="lagerorteNameList"
            :inputValue="selectedLagerortName"
            @set-value="setLagerortByName"
          />
        </div>
      </div>

      <div class="col-4">
        <div class="mb-3">
          <label for="lagerbereich-input" class="form-label">Lagerbereich</label>
          <AutocompleteInputSelect
            id="lagerbereich-input"
            ref="lagerbereichInput"
            :select-list="lagerbereicheNameList"
            :inputValue="selectedLagerbereichName"
            @set-value="setLagerbereichByName"
          />
        </div>
      </div>
    </div>

    <hr class="my-4" />

    <h3>Positionen</h3>

    <div class="row mb-2">
      <div class="col-3 fw-bold">Artikel Nr.</div>
      <div class="col fw-bold">Artikel</div>
      <div class="col-3 fw-bold">Anzahl/Einheit</div>
    </div>

    <CreateWarenzugangPosRow v-for="(pos, index) in neueLieferung.lieferung.positionen" :key="index" :pos="pos" :index="index" />

    <button type="button" class="btn btn-secondary" @click="addPosition()"><font-awesome-icon icon="plus" /> Position hinzufügen</button>

    <div v-if="!loading && errorMsg != ''" class="alert alert-danger mt-5">
      {{ errorMsg }}
    </div>

    <LoadingSpinner v-if="loading" />

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="returnToMain()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>
  </form>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, reactive, ref, onMounted } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';
  import { ActionTypes as LieferantActionTypes } from '@/store/modules/Lieferant/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
  import CreateWarenzugangPosRow from '@/components/Bestand/CreateWarenzugangPosRow.vue';

  import { Lieferant } from '@/models/LieferantModels';
  import { CreateLieferungRequest, LieferungPos } from '@/models/LieferungModels';
  import { Lagerbereich, Lagerort } from '@/models/LagerModels';
  //#endregion

  const store = useStore();
  const router = useRouter();

  const neueLieferung = reactive({
    lieferung: new CreateLieferungRequest(),
  });

  const lieferdatumDateConverter = computed({
    get() {
      return (
        neueLieferung.lieferung.lieferdatum.getFullYear() +
        '-' +
        ('0' + (neueLieferung.lieferung.lieferdatum.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + neueLieferung.lieferung.lieferdatum.getDate()).slice(-2)
      );
    },

    set(newValue) {
      if (newValue == '') {
        newValue = new Date().toISOString();
      }
      neueLieferung.lieferung.lieferdatum = new Date(newValue);
    },
  });

  const lieferanten = computed(() => store.getters.lieferanten);
  const lieferantenNrList = computed(() => lieferanten.value.map((obj: Lieferant) => obj['id'].toString()));
  const lieferantenFirmaList = computed(() => lieferanten.value.map((obj: Lieferant) => obj['firma']));
  const lieferantenNameList = computed(() => lieferanten.value.map((obj: Lieferant) => obj['vorname'] + ' ' + obj['name']));

  const lagerorte = computed(() => store.getters.lagerorte);
  const lagerorteNameList = computed(() => lagerorte.value.map((obj: Lagerort) => obj['name']));
  const selectedLagerortName = ref('');

  const lagerbereiche = computed(() => store.getters.lagerbereiche);
  const lagerbereicheNameList = computed(() => lagerbereiche.value.map((obj: Lagerbereich) => obj['name']));
  const selectedLagerbereichName = ref('');

  const kundeInput = reactive({
    id: '',
    firma: '',
    name: '',
  });

  const loading = computed(() => store.getters.status.createLieferungLoading);
  const errorMsg = computed(() => store.getters.status.lieferungErrorMsg);

  function loadData() {
    store.dispatch(AuftragActionTypes.GetAuftragStatus, undefined);
    store.dispatch(LieferantActionTypes.GetLieferant, undefined);
    store.dispatch(ArtikelActionTypes.GetArtikel, undefined);
  }

  function createWarenzugang() {
    store.dispatch(BestandActionTypes.CreateLieferung, neueLieferung.lieferung).then(() => {
      if (errorMsg.value == '') {
        returnToMain();
      }
    });
  }

  function addPosition() {
    neueLieferung.lieferung.positionen.push(new LieferungPos());
  }

  function returnToMain() {
    clearInputs();
    router.push({ name: 'Warenzugang' });
  }

  function clearInputs() {
    neueLieferung.lieferung = new CreateLieferungRequest();
  }

  const lieferantFirmaInputRef = ref();
  const lieferantNameInputRef = ref();
  const lieferantIdInputRef = ref();

  function setLieferantById(id: string) {
    console.log(id);
    const lieferant = lieferanten.value.find((k: Lieferant) => k.id == parseInt(id));
    console.log(lieferant);

    if (lieferant != null) {
      setLieferant(lieferant);
      (lieferantFirmaInputRef.value as typeof AutocompleteInputSelect).inputvalue = lieferant.firma;
      (lieferantNameInputRef.value as typeof AutocompleteInputSelect).inputvalue = `${lieferant.vorname} ${lieferant.name}`;
    }
  }

  function setLieferantByFirma(firma: string) {
    const lieferant = lieferanten.value.find((k: Lieferant) => k.firma == firma);

    if (lieferant != null) {
      setLieferant(lieferant);
      (lieferantIdInputRef.value as typeof AutocompleteInputSelect).inputvalue = lieferant.id.toString();
      (lieferantNameInputRef.value as typeof AutocompleteInputSelect).inputvalue = `${lieferant.vorname} ${lieferant.name}`;
    }
  }

  function setLieferantByName(name: string) {
    const lieferant = lieferanten.value.find((k: Lieferant) => `${k.vorname} ${k.name}` == name);

    if (lieferant != null) {
      setLieferant(lieferant);
      (lieferantIdInputRef.value as typeof AutocompleteInputSelect).inputvalue = lieferant.id.toString();
      (lieferantFirmaInputRef.value as typeof AutocompleteInputSelect).inputvalue = lieferant.firma;
    }
  }

  function setLieferant(lieferant: Lieferant) {
    neueLieferung.lieferung.lieferantid = lieferant.id;
  }

  function setLagerortByName(name: string) {
    const lagerort = lagerorte.value.find((l: Lagerort) => l.name == name);
    if (lagerort != null) {
      neueLieferung.lieferung.lagerortid = lagerort.id;
    }
  }

  function setLagerbereichByName(name: string) {
    const lagerbereich = lagerbereiche.value.find((l: Lagerbereich) => l.name == name);
    if (lagerbereich != null) {
      neueLieferung.lieferung.lagerbereichid = lagerbereich.id;
    }
  }

  onMounted(() => {
    loadData();
  });
</script>
