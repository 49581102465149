<template>
  <div class="d-flex justify-content-between">
    <h1>Neue Kommission</h1>
  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <form v-else @submit.prevent="createKommission()" autocomplete="off">
    <h3>Auftrag</h3>
    <div class="row mb-3">
      <div class="col">
        <label for="auftrag-nr" class="form-label">Auftrag Nr.</label>
        <AutocompleteInputSelect
          :id="'auftrag-nr'"
          ref="auftargIdInput"
          :select-list="auftragNrList"
          :inputValue="auftragNrInput.auftragnr"
          @set-value="setAuftragById"
        />
      </div>
    </div>

    <div v-if="selectedAuftragKunde != null" class="mb-4">
      <h5>Kunde</h5>
      <p><strong>Firma:</strong> {{ selectedAuftragKunde.firma }}</p>
      <p><strong>Name:</strong> {{ selectedAuftragKunde.vorname }} {{ selectedAuftragKunde.name }}</p>
      <p><strong>Straße:</strong> {{ selectedAuftragKunde.lieferstrasse }}</p>
      <p><strong>PLZ/Ort:</strong> {{ selectedAuftragKunde.lieferplz }} {{ selectedAuftragKunde.lieferort }}</p>
      <p><strong>Land:</strong> {{ selectedAuftragKunde.lieferland }}</p>
    </div>

    <div v-if="selectedAuftragPositionen.length > 0">
      <h5>Auftrag Positionen</h5>
      <div v-for="pos in selectedAuftragPositionen" :key="pos.id" class="row">
        <div class="col">
          <AuftragPosRow :auftragpos="pos" />
        </div>
        <div class="col-1 my-auto">
          <button type="button" class="btn btn-primary" @click="addKommissionPosFromAuftragPos(pos)"><font-awesome-icon icon="plus" /></button>
        </div>
      </div>
    </div>

    <hr />

    <div class="mb-3">
      <label for="Notizen" class="form-label">Notizen</label>
      <textarea class="form-control" rows="3" v-model="neueKommission.kommission.notizen"></textarea>
    </div>

    <hr class="my-4" />

    <h3>Positionen</h3>

    <div v-for="(pos, index) in kommissionPositionen.positionen" :key="index" class="row mb-3">
      {{ pos }}
      <!-- <div class="col">
        <AutcompleteInputSelect :id="'position-artikelnr-' + index" :ref="'positionArtikelnrInput-' + index" :select-list="artikelNummerList" :inputValue="pos.artikelnr" :index="index" @set-value="setPositionByArtikelnr" />
      </div>
      
      <div class="col">
        <AutcompleteInputSelect :id="'position-artikeltitel-' + index" :ref="'positionArtikeltitelInput-' + index" :select-list="artikelTitelList" :inputValue="pos.artikeltitel" :index="index" @set-value="setPositionByArtikeltitel" />
      </div>

      <div class="col">
        <input type="number" class="form-control" :id="'pos-anzahl-' + index" v-model="pos.anzahl" />
      </div> -->
    </div>
    <button type="button" class="btn btn-secondary" @click="addPosition()"><font-awesome-icon icon="plus" /> Position hinzufügen</button>

    <div v-if="!loading && errorMsg != ''" class="alert alert-danger mt-5">
      {{ errorMsg }}
    </div>

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="returnToMain()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>
  </form>
</template>

<script lang="ts">
  import { defineComponent, computed, reactive, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Auftrag, AuftragPos, EditAuftragPos, CreateAuftragRequest } from '@/models/AuftragModels';
  import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';
  import { ActionTypes as KundeActionTypes } from '@/store/modules/Kunde/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { ActionTypes as KommissionlActionTypes } from '@/store/modules/Kommission/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
  import AuftragPosRow from '@/components/Auftrag/AuftragPosRow.vue';

  import { Kunde } from '@/models/KundeModels';
  import { Artikel } from '@/models/ArtikelModels';
  import { Kommission, CreateKommissionRequest, KommissionPos } from '@/models/KommissionModels';

  export default defineComponent({
    name: 'CreateAuftrageView',

    components: {
      LoadingSpinner,
      AutocompleteInputSelect,
      AuftragPosRow,
    },

    setup() {
      const store = useStore();
      const router = useRouter();

      const neueKommission = reactive({
        kommission: new CreateKommissionRequest(),
      });

      const kommissionPositionen = reactive({
        positionen: new Array<KommissionPos>(),
      });

      const auftrage = computed(() => store.getters.auftraege);
      const auftragNrList = computed(() => auftrage.value.map((obj: Auftrag) => obj['id'].toString()));
      const auftragNrInput = reactive({ auftragnr: '' });
      const selectedAuftrag = computed(() => auftrage.value.find((a: Auftrag) => a.id == neueKommission.kommission.auftragid));
      const selectedAuftragKunde = computed(() => store.getters.kunden.find((k: Kunde) => k.id == selectedAuftrag.value?.kundeid));
      const selectedAuftragPositionen = computed(() => store.getters.auftragpos.filter((p: AuftragPos) => p.auftragid == selectedAuftrag.value?.id));

      function addKommissionPosFromAuftragPos(pos: AuftragPos) {
        var position = new KommissionPos();
        position.artikelid = pos.artikelid;
        position.anzahl = pos.anzahl;

        kommissionPositionen.positionen.push(position);
      }

      const artikel = computed(() => store.getters.artikel);
      const artikelTitelList = computed(() => artikel.value.map((obj: Artikel) => obj['titel']));
      const artikelNummerList = computed(() => artikel.value.map((obj: Artikel) => obj['artikelNr']));

      const kunden = computed(() => store.getters.kunden);
      const kundenNrList = computed(() => kunden.value.map((obj: Kunde) => obj['id'].toString()));
      const kundenFirmaList = computed(() => kunden.value.map((obj: Kunde) => obj['firma']));
      const kundenNameList = computed(() => kunden.value.map((obj: Kunde) => obj['vorname'] + ' ' + obj['name']));

      const kundeInput = reactive({
        id: '',
        firma: '',
        name: '',
      });

      const loading = computed(() => store.getters.status.createKommissionLoading);
      const errorMsg = computed(() => store.getters.status.kommissionErrorMsg);

      function loadData() {
        store.dispatch(AuftragActionTypes.GetAllAuftraege, undefined);
        store.dispatch(AuftragActionTypes.GetAuftragStatus, undefined);
        store.dispatch(KundeActionTypes.GetKunde, undefined);
        store.dispatch(ArtikelActionTypes.GetArtikel, undefined);
      }

      function createKommission() {
        console.log('create kommission');
        neueKommission.kommission.positionen = kommissionPositionen.positionen;

        store.dispatch(KommissionlActionTypes.CreateKommission, neueKommission.kommission).then(() => {
          if (errorMsg.value == '') {
            returnToMain();
          }
        });
      }

      function addPosition() {
        kommissionPositionen.positionen.push(new KommissionPos());
      }

      function returnToMain() {
        clearInputs();
        router.push({ name: 'Kommissionen' });
      }

      function clearInputs() {
        neueKommission.kommission = new CreateKommissionRequest();
      }

      return {
        router,
        auftrage,
        auftragNrList,
        auftragNrInput,
        selectedAuftrag,
        selectedAuftragKunde,
        selectedAuftragPositionen,
        addKommissionPosFromAuftragPos,
        artikelTitelList,
        artikelNummerList,
        artikel,
        kunden,
        kundeInput,
        kundenNrList,
        kundenFirmaList,
        kundenNameList,
        kommissionPositionen,
        neueKommission,
        loading,
        errorMsg,
        loadData,
        createKommission,
        addPosition,
        returnToMain,
      };
    },

    methods: {
      setAuftragById(id: string) {
        console.log(id);
        const auftrag = this.auftrage.find((a: Auftrag) => a.id == parseInt(id));

        if (auftrag != null) {
          this.setAuftrag(auftrag);
          // (this.$refs.kundeFirmaInput as typeof AutcompleteInputSelect).inputvalue = kunde.firma;
          // (this.$refs.kundeNameInput as typeof AutcompleteInputSelect).inputvalue = `${kunde.vorname} ${kunde.name}`;
        }
      },

      setAuftrag(auftrag: Auftrag) {
        this.neueKommission.kommission.auftragid = auftrag.id;
      },

      setKundeById(id: string) {
        const kunde = this.kunden.find((k: Kunde) => k.id == parseInt(id));

        if (kunde != null) {
          this.setKunde(kunde);
          (this.$refs.kundeFirmaInput as typeof AutocompleteInputSelect).inputvalue = kunde.firma;
          (this.$refs.kundeNameInput as typeof AutocompleteInputSelect).inputvalue = `${kunde.vorname} ${kunde.name}`;
        }
      },

      setKundeByFirma(firma: string) {
        const kunde = this.kunden.find((k: Kunde) => k.firma == firma);

        if (kunde != null) {
          this.setKunde(kunde);
          (this.$refs.kundeIdInput as typeof AutocompleteInputSelect).inputvalue = kunde.id.toString();
          (this.$refs.kundeNameInput as typeof AutocompleteInputSelect).inputvalue = `${kunde.vorname} ${kunde.name}`;
        }
      },

      setKundeByName(name: string) {
        const kunde = this.kunden.find((k: Kunde) => `${k.vorname} ${k.name}` == name);

        if (kunde != null) {
          this.setKunde(kunde);
          (this.$refs.kundeIdInput as typeof AutocompleteInputSelect).inputvalue = kunde.id.toString();
          (this.$refs.kundeFirmaInput as typeof AutocompleteInputSelect).inputvalue = kunde.firma;
        }
      },

      setPositionByArtikelnr(artikelnummer: string, index: number) {
        const artikel = this.artikel.find((a: Artikel) => a.artikelNr == artikelnummer);

        if (artikel != null) {
          this.setPositionArtikel(artikel, index);
          (this.$refs['positionArtikeltitelInput-' + index] as (typeof AutocompleteInputSelect)[])[0].inputvalue = artikel.titel;
        }
      },

      setPositionByArtikeltitel(artikeltitel: string, index: number) {
        const artikel = this.artikel.find((a: Artikel) => a.titel == artikeltitel);

        if (artikel != null) {
          this.setPositionArtikel(artikel, index);
          (this.$refs['positionArtikelnrInput-' + index] as (typeof AutocompleteInputSelect)[])[0].inputvalue = artikel.artikelNr;
        }
      },

      setKunde(kunde: Kunde) {
        console.log(kunde);
        // this.neuerAuftrag.auftrag.kundeid = kunde.id
      },

      setPositionArtikel(artikel: Artikel, index: number) {
        console.log(artikel, index);
        // this.auftragPositionen.positionen[index].id = artikel.id
        // this.auftragPositionen.positionen[index].artikelnr = artikel.artikelNr
        // this.auftragPositionen.positionen[index].artikeltitel = artikel.titel
      },
    },

    mounted() {
      this.loadData();
    },
  });
</script>
