<template>
  <h1>Artikel Template erstellen</h1>
  <LoadingSpinner v-if="loading" text="... der Artikel wird angelegt ..." />

  <form v-else @submit.prevent="createArtikeltemplate">
    <h2>Template</h2>
    <div class="mb-3">
      <label for="template-titel" class="form-label">Titel</label>
      <input type="text" v-model="template.template.titel" class="form-control" id="template-titel" required />
    </div>

    <div class="mb-3">
      <label for="template-beschreibung" class="form-label">Beschreibung</label>
      <textarea class="form-control" v-model="template.template.beschreibung" id="template-beschreibung" rows="3"></textarea>
    </div>

    <hr class="my-4" />

    <h2>Artikel Template Definition</h2>

    <div class="row mb-3">
      <div class="col-4">
        <label for="artikelart" class="form-label">Artikelart auswählen</label>
        <select class="form-select" id="artikel-template" v-model="template.template.artikelartid">
          <option v-for="artikelart in artikelarten" :key="artikelart.id" :value="artikelart.id">
            {{ artikelart.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="mb-3">
      <label for="artikel-titel" class="form-label">Artikel Titel</label>
      <input type="text" v-model="template.template.artikeltitel" class="form-control" id="artikel-titel" />
    </div>

    <div class="mb-3">
      <label for="artikelnummer" class="form-label">Artikelnummer</label>
      <input type="text" v-model="template.template.artikelnummer" class="form-control" id="artikelnummer" />
    </div>

    <div class="mb-3">
      <label for="artikel-beschreibung" class="form-label">Beschreibung</label>
      <textarea class="form-control" v-model="template.template.artikelbeschreibung" id="artikel-beschreibung" rows="3"></textarea>
    </div>

    <div class="mb-3">
      <label for="anzahl" class="form-label">Anzahl</label>
      <input type="text" v-model="template.template.anzahl" class="form-control" id="anzahl" />
    </div>

    <div class="mb-3">
      <label for="einheit" class="form-label">Einheit</label>
      <select class="form-select" id="einheit" v-model="template.template.einheitid">
        <option v-for="ae in artikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
      </select>
    </div>

    <p class="fw-bold">Bestand Einstellung</p>
    <div class="form-check mb-3">
      <input class="form-check-input" type="checkbox" v-model="template.template.inProdZusammenfuehren" id="in-prod-zusammenfuehren" />
      <label class="form-check-label" for="in-prod-zusammenfuehren"> Bestand in Produktion zusammenführen </label>
    </div>

    <hr class="my-3" />

    <!-- EIGENSCHAFTEN -->
    <div class="mb-3">
      <p class="fw-bold">Eigenschaften</p>

      <div class="row w-50 mb-3">
        <div class="col-4">
          <div>
            <span>Eigenschaft</span>
          </div>
        </div>

        <div class="col-8">
          <div>
            <label class="form-label">Wert</label>
          </div>
        </div>
      </div>

      <div v-for="(eigenschaft, index) in template.template.eigenschaften" :key="index">
        <div class="row w-50 mb-3">
          <div class="col-3">
            <div>
              <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required />
            </div>
          </div>

          <div class="col-7">
            <div>
              <input type="text" v-model="eigenschaft.value" class="form-control" :id="'value-' + index" placeholder="Rot" required />
            </div>
          </div>
          <div class="col-2">
            <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEigenschaft(index)">
              <font-awesome-icon icon="trash" />
            </button>
          </div>
        </div>
      </div>

      <div>
        <button type="button" class="btn btn-secondary" @click="addEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
      </div>
    </div>

    <hr class="my-3" />

    <!-- TAGS -->
    <div class="my-3">
      <p class="fw-bold">Tags</p>

      <div class="d-flex flex-row mb-3 flex-wrap">
        <p v-for="tag in template.template.tags" :key="tag.id" class="lead me-2">
          <span class="badge text-bg-primary">{{ tags.find((t) => t.id == tag.tagid)?.key }}</span>
        </p>
      </div>

      <ArtikeltemplateTagSelectModal :templateTags="template.template.tags.map((t) => t.tagid)" @set-selected-tags="setSelectedTags" />
    </div>

    <!-- VARIANTENEIGENSCHAFTEN -->
    <div class="mb-3" v-if="template.template.artikelartid == 2">
      <hr class="my-3" />

      <p class="fw-bold">Varianten</p>

      <div class="mb-3">
        <p class="fw-bold mb-3">Varianten Eigenschaften</p>

        <div class="row w-50 mb-3">
          <div class="col-4">
            <div>
              <span>Eigenschaft</span>
            </div>
          </div>

          <div class="col-8">
            <div>
              <label class="form-label">Werte</label>
            </div>
          </div>
        </div>

        <div v-for="(eigenschaft, index) in template.variantenEigenschaften" :key="index">
          <div class="row mb-4">
            <div class="col-2">
              <div>
                <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required />
              </div>
            </div>

            <div class="col-8">
              <div class="row">
                <div class="col-5">Wert</div>
                <div class="col-5">Artikelnummer Erweiterung</div>
                <div class="col-2"></div>
              </div>

              <div class="row mb-2" v-for="(wert, windex) in eigenschaft.values" :key="'key-' + index + '-' + windex">
                <div class="col-5">
                  <input type="text" class="form-control" v-model="wert.value" placeholder="Rot" required />
                </div>

                <div class="col-5">
                  <input type="text" class="form-control" v-model="wert.artikelnummerextension" placeholder="04" required />
                </div>

                <div class="col-2">
                  <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVarianteEigenschaftValue(index, windex)">
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>

              <div>
                <button type="button" class="btn btn-outline-secondary" @click="addVarianteEigenschaftValue(index)">
                  <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
                </button>
              </div>
            </div>

            <div class="col-2">
              <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVarianteEigenschaft(index)">
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>

        <div>
          <button type="button" class="btn btn-secondary" @click="addVarianteEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
        </div>
      </div>
    </div>

    <div v-if="errorMsg != ''" class="alert alert-danger">Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.</div>

    <hr class="my-4" />

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>
  </form>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, onMounted, reactive } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { ActionTypes } from '@/store/modules/Artikeltemplates/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { v4 as uuidv4 } from 'uuid';

  import { EditVariantenEigenschaft, EditVariantenEigenschaftValue } from '@/models/ArtikelModels';
  import { Artikeltemplate, ArtikeltemplateEigenschaft, ArtikeltemplateVariantenEigenschaft, ArtikeltemplateTag } from '@/models/ArtikeltemplateModels';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import ArtikeltemplateTagSelectModal from '@/components/Artikeltemplates/ArtikeltemplateTagSelectModal.vue';
  //#endregion

  const store = useStore();
  const router = useRouter();

  const loading = computed(() => store.getters.status.createArtikeltemplateLoading);
  const errorMsg = computed(() => store.getters.status.artikeltemplateErrorMsg);

  const artikelarten = computed(() => store.getters.artikelarten);
  const artikeleinheiten = computed(() => store.getters.artikeleinheiten);

  const tags = computed(() => store.getters.tags);

  const template = reactive({
    template: new Artikeltemplate(),
    variantenEigenschaften: new Array<EditVariantenEigenschaft>(),
    defaulteinheit: -1,
  });

  //#region Tags
  function setSelectedTags(data: Array<number>) {
    template.template.tags = new Array<ArtikeltemplateTag>();
    for (var value of data) {
      const newValue = new ArtikeltemplateTag();
      newValue.artikeltemplateid = template.template.id;
      newValue.tagid = value;
      template.template.tags.push(newValue);
    }
  }
  //#endregion

  //#region Eigenschaft
  function addEigenschaft() {
    template.template.eigenschaften.push(new ArtikeltemplateEigenschaft());
  }

  function removeEigenschaft(index: number) {
    template.template.eigenschaften.splice(index, 1);
  }
  //#endregion

  //#region Varianteneigenschaften
  function addVarianteEigenschaft() {
    template.variantenEigenschaften.push(new EditVariantenEigenschaft(''));
  }

  function removeVarianteEigenschaft(index: number) {
    template.variantenEigenschaften.splice(index, 1);
  }

  function addVarianteEigenschaftValue(varianteindex: number) {
    template.variantenEigenschaften[varianteindex].values.push(new EditVariantenEigenschaftValue(uuidv4(), '', ''));
  }

  function removeVarianteEigenschaftValue(varianteindex: number, index: number) {
    template.variantenEigenschaften[varianteindex].values.splice(index, 1);
  }
  //#endregion

  function clearInputs() {
    template.template = new Artikeltemplate();
    template.variantenEigenschaften = new Array<EditVariantenEigenschaft>();
    template.defaulteinheit = -1;
  }

  function cancel() {
    clearInputs();
    router.push({ name: 'Artikeltemplates' });
  }

  function createArtikeltemplate() {
    // add varianteneigenschaften
    if (template.variantenEigenschaften.length > 0) {
      for (var eigenschaft of template.variantenEigenschaften) {
        for (var value of eigenschaft.values) {
          template.template.variantenEigenschaften.push(
            new ArtikeltemplateVariantenEigenschaft(0, 0, value.identifier, eigenschaft.key, value.value, value.artikelnummerextension)
          );
        }
      }
    }

    store.dispatch(ActionTypes.CreateArtikeltemplate, template.template).then(() => {
      if (errorMsg.value == '') {
        cancel();
      }
    });
  }

  onMounted(() => {
    if (artikelarten.value.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikelarten, undefined);
    }

    if (artikeleinheiten.value.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikelEinheit, undefined);
    }
  });
</script>

<style scoped>
  .footerbar {
    position: absolute;
    bottom: 0;
  }
</style>
