<template>
  <div class="row py-3 mb-2 mx-1 bg-white shadow-sm auftrag-row">
    <div class="col-1 my-auto">
      {{ artikel?.artikelNr }}
    </div>

    <div class="col-2 my-auto">
      {{ artikel?.titel }}
    </div>

    
    <div class="col-2 my-auto">
      {{ kommissionpos.anzahl }}
    </div>

    <div class="col-2 my-auto">
      {{ artikel?.einheit }}
    </div>

    <div class="col-2 my-auto">
      {{ kommissionpos.artikelbestandid }}
    </div>

    <div class="col"></div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { AuftragPos } from "@/models/AuftragModels";
import { Artikel } from "@/models/ArtikelModels";
import { KommissionPos } from "@/models/KommissionModels";

export default defineComponent({
  name: "AuftragPosRow",

  props: {
    kommissionpos: {
      type: Object as PropType<KommissionPos>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    // const router = useRouter()

    const artikel = computed(() => store.getters.artikel.find((a: Artikel) => a.id == props.kommissionpos.artikelid))

    return {
      // router,
      artikel
    }
  },
})

</script>

<style scoped>
.kunde-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
}
</style>