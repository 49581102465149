import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import { store } from './store';
import { registerSW } from 'virtual:pwa-register';

import { library } from '@fortawesome/fontawesome-svg-core';

import { faWindows } from '@fortawesome/free-brands-svg-icons';

import {
  faArrowDownLong,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowUpLong,
  faBarcode,
  faBars,
  faBox,
  faBoxOpen,
  faBoxesPacking,
  faBoxesStacked,
  faCertificate,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faEnvelope,
  faFileDownload,
  faFloppyDisk,
  faListCheck,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMap,
  faMinus,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPlus,
  faPrint,
  faRotate,
  faTableCells,
  faTableColumns,
  faTrash,
  faUsers,
  faUsersViewfinder,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowDownLong,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowUpLong,

  faBarcode,
  faBars,
  faBox,
  faBoxOpen,
  faBoxesPacking,
  faBoxesStacked,

  faCertificate,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,

  faEnvelope,

  faFileDownload,
  faFloppyDisk,

  faListCheck,

  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMap,
  faMinus,

  faPaperPlane,
  faPen,
  faPenToSquare,
  faPlus,
  faPrint,

  faRotate,

  faTableCells,
  faTableColumns,
  faTrash,

  faUsers,
  faUsersViewfinder,

  faWarehouse,
  faWindows
);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
registerSW({ immediate: true });

const app = createApp(App);

app.use(store);
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');

import 'bootstrap';
