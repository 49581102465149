import { RootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';
import ApiService from '@/services/ApiService';

import { ActionTypes as CommonActionTypes } from '../Common/actions';

import { Auftrag, AuftragStatus, GetAuftraegeRequest } from '@/models/AuftragModels';

export enum ActionTypes {
  GetAllAuftraege = 'GETALLAUFTRAEGE',
  GetAllKundeAuftraege = 'GETALLKUNDEAUFTRAEGE',
  CreateAuftrag = 'CREATEAUFTRAG',
  UpdateAuftrag = 'UPDATEAUFTRAG',
  DeleteAuftrag = 'DELETEAUFTRAG',

  GetAuftragStatus = 'GETAUFTRAGSTATUS',
  CreateAuftragStatus = 'CREATEAUFTRAGSTATUS',
  UpdateAuftragStatus = 'UPDATEAUFTRAGSTATUS',
  DeleteAuftragStatus = 'DELETEAUFTRAGSTATUS',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.GetAllAuftraege](context: ActionArguments, data: GetAuftraegeRequest): Promise<void>;
  [ActionTypes.GetAllKundeAuftraege](context: ActionArguments, data: number): Promise<void>;
  [ActionTypes.CreateAuftrag](context: ActionArguments, data: Auftrag): Promise<void>;
  [ActionTypes.UpdateAuftrag](context: ActionArguments, data: Auftrag): Promise<void>;
  [ActionTypes.DeleteAuftrag](context: ActionArguments, id: number): Promise<void>;

  [ActionTypes.GetAuftragStatus](context: ActionArguments): Promise<void>;
  [ActionTypes.CreateAuftragStatus](context: ActionArguments, data: AuftragStatus): Promise<void>;
  [ActionTypes.UpdateAuftragStatus](context: ActionArguments, data: AuftragStatus): Promise<void>;
  [ActionTypes.DeleteAuftragStatus](context: ActionArguments, id: number): Promise<void>;
};

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Auftrag
  async [ActionTypes.GetAllAuftraege]({ commit, dispatch, rootGetters }: ActionArguments, data: GetAuftraegeRequest) {
    const status = rootGetters.status;
    status.getAuftragLoading = true;
    status.auftragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllAuftrag(data)
      .then((res) => {
        const data: Array<Auftrag> = res.data;
        commit(MutationTypes.SetAuftrag, data);
      })
      .catch((error) => {
        status.auftragErrorMsg = error.response.data;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getAuftragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.GetAllKundeAuftraege]({ commit, dispatch, rootGetters }: ActionArguments, data: number) {
    const status = rootGetters.status;
    status.getAuftragLoading = true;
    status.auftragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    const request = new GetAuftraegeRequest();
    request.kundeid = data.toString();

    return ApiService.getAllAuftrag(request)
      .then((res) => {
        const data: Array<Auftrag> = res.data;
        commit(MutationTypes.AddOrUpdateAuftraege, data);
      })
      .catch((error) => {
        status.auftragErrorMsg = error.response.data;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getAuftragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateAuftrag]({ commit, dispatch, rootGetters }: ActionArguments, data: Auftrag) {
    const status = rootGetters.status;
    status.createAuftragLoading = true;
    status.auftragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createAuftrag(data)
      .then((res) => {
        const data: Auftrag = res.data;
        commit(MutationTypes.AddOrUpdateAuftrag, data);
      })
      .catch((error) => {
        status.auftragErrorMsg = error.response.data;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createAuftragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateAuftrag]({ commit, dispatch, rootGetters }: ActionArguments, data: Auftrag) {
    const status = rootGetters.status;
    status.updateAuftragLoading = true;
    status.auftragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateAuftrag(data)
      .then((res) => {
        const data: Auftrag = res.data;
        commit(MutationTypes.AddOrUpdateAuftrag, data);
      })
      .catch((error) => {
        status.auftragErrorMsg = error.response.data;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateAuftragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DeleteAuftrag]({ commit, dispatch, rootGetters }: ActionArguments, id: number) {
    const status = rootGetters.status;
    status.deleteAuftragLoading = true;
    status.auftragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteAuftrag(id)
      .then((_res) => {
        commit(MutationTypes.DeleteAuftrag, id);
      })
      .catch((error) => {
        status.auftragErrorMsg = error.response.data;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteAuftragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
  //#endregion

  //#region AuftragStatus
  async [ActionTypes.GetAuftragStatus]({ commit, dispatch, rootGetters }: ActionArguments) {
    const status = rootGetters.status;
    status.getAuftragStatusLoading = true;
    status.auftragStatusErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllAuftragStatus()
      .then((res) => {
        const data: Array<AuftragStatus> = res.data;
        commit(MutationTypes.SetAuftragStatus, data);
      })
      .catch((error) => {
        status.auftragStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getAuftragStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateAuftragStatus]({ commit, dispatch, rootGetters }: ActionArguments, data: AuftragStatus) {
    const status = rootGetters.status;
    status.createAuftragStatusLoading = true;
    status.auftragStatusErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createAuftragStatus(data)
      .then((res) => {
        const data: AuftragStatus = res.data;
        commit(MutationTypes.AddAuftragStatus, data);
      })
      .catch((error) => {
        status.auftragErrorStatusMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createAuftragStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateAuftragStatus]({ commit, dispatch, rootGetters }: ActionArguments, data: AuftragStatus) {
    const status = rootGetters.status;
    status.updateAuftragStatusLoading = true;
    status.auftragStatusErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateAuftragStatus(data)
      .then((res) => {
        const data: AuftragStatus = res.data;
        commit(MutationTypes.UpdateAuftragStatus, data);
      })
      .catch((error) => {
        status.auftragStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateAuftragStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DeleteAuftragStatus]({ commit, dispatch, rootGetters }: ActionArguments, id: number) {
    const status = rootGetters.status;
    status.deleteAuftragStatusLoading = true;
    status.auftragStatusErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteAuftragStatus(id)
      .then((_res) => {
        commit(MutationTypes.DeleteAuftragStatus, id);
      })
      .catch((error) => {
        status.auftragErrorStatusMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteAuftragStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
  //#endregion
};
