<template>
  <div class="row mx-1 mb-1 py-3 bg-white shadow-sm">
    <div class="col-1 my-auto">{{ bestand.artikelnummer }}</div>
    <div class="col-3 my-auto">{{ bestand.artikeltitel }}</div>
    <div class="col-1 my-auto">{{ bestand.barcode }}</div>

    <!-- <div class="col-1 my-auto">{{ bestand.anzahl }}</div>
    <div class="col-1 my-auto">{{ einheit.name }}</div> -->
    <div class="col-2 my-auto d-flex flex-wrap">
      <span class="border border-dark py-1 px-2 me-1">{{ bestand.anzahl }} {{ bestand.einheit?.name }}</span>
    </div>

    <div class="col-2 my-auto">{{ lagerort?.name }}</div>
    <div class="col-1 my-auto">{{ lagerbereich?.name }}</div>
    <div class="col-1 my-auto">{{ lagerreihe?.reihe }}</div>
    <div class="col-1 my-auto">{{ lagerplatz?.platz }}</div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed } from 'vue';
  import { useStore } from '@/store';

  import { ArtikelBestand } from '@/models/BestandModels';
  import { Lagerort, Lagerbereich, Lagerreihe, Lagerplatz } from '@/models/LagerModels';
  //#endregion
  const props = defineProps({
    bestand: {
      type: Object as PropType<ArtikelBestand>,
      required: true,
    },
  });

  const store = useStore();

  const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == props.bestand.lagerortid));
  const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == props.bestand.lagerbereichid));
  const lagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == props.bestand.lagerreiheid));
  const lagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == props.bestand.lagerplatzid));
</script>
