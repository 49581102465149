<template>
  <button type="button" class="btn btn-secondary" @click="showSelectTagsModal()"><font-awesome-icon icon="plus" /> Tags auswählen</button>

  <div
    class="modal fade"
    id="selectTagsModal"
    tabindex="-1"
    aria-labelledby="selectTagsModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="selectTagsModalRef"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="selectTagsModalLabel">Tags auswählen</h5>
          <button type="button" class="btn-close" @click="closeSelectTagsModal()"></button>
        </div>

        <div class="modal-body">
          <LoadingSpinner v-if="getTagsLoading" text="... Tags werden geladen ..." />

          <div v-else>
            <section>
              <div class="mb-2" v-for="(tag, index) in displayTags" :key="index">
                <input class="form-check-input me-3 my-auto" type="checkbox" :value="tag" :id="'tag-' + tag" v-model="selectedTags" />
                <label class="form-check-label flex-fill" :for="'tag-' + tag">
                  {{ tags.find((t: Tag) => t.id == tag)?.key }}
                </label>
              </div>
            </section>

            <hr class="my-3" />

            <section class="w-50">
              <LoadingSpinner v-if="createTagLoading" text="... Daten werden gespeichert ..." />

              <form v-else @submit.prevent="createTag">
                <label for="newTagInput" class="form-label">Tag hinzufügen</label>
                <div class="input-group">
                  <input type="text" class="form-control" id="newTagInput" v-model="newTag.key" required />
                  <button type="submit" class="btn btn-primary"><font-awesome-icon icon="floppy-disk" /></button>
                </div>

                <div v-if="tagErrorMsg != ''" class="alert alert-danger">
                  {{ tagErrorMsg }}
                </div>
              </form>
            </section>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-3 float-end" @click="closeSelectTagsModal()">Abbrechen</button>
          <button type="button" class="btn btn-success float-end" @click="setTags()">Speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed, onMounted, ref } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';

  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';

  import { Tag } from '@/models/ArtikelModels';

  import LoadingSpinner from '../LoadingSpinner.vue';

  const props = defineProps({
    templateTags: {
      type: Object as PropType<Array<number>>,
      default: new Array<number>(),
    },
  });

  const emits = defineEmits(['setSelectedTags']);

  const store = useStore();

  const getTagsLoading = computed(() => store.getters.status.getTagLoading);
  const createTagLoading = computed(() => store.getters.status.createTagLoading);
  const tagErrorMsg = computed(() => store.getters.status.tagErrorMsg);

  const tags = computed(() => store.getters.tags);
  const displayTags = computed<Array<number>>(() => tags.value.map((t: Tag) => t.id));

  const selectedTags = ref(props.templateTags);

  function setTags() {
    emits('setSelectedTags', selectedTags.value);
    closeSelectTagsModal();
  }

  const newTag = ref(new Tag());
  function createTag() {
    store.dispatch(ArtikelActionTypes.CreateTag, newTag.value).then(() => {
      if (tagErrorMsg.value == '') {
        newTag.value = new Tag();
      }
    });
  }

  const selectTagsModalRef = ref();
  function closeSelectTagsModal() {
    newTag.value = new Tag();
    // selectedTags.value = props.tags;
    Modal.getInstance(selectTagsModalRef.value)?.hide();
  }
  function showSelectTagsModal() {
    if (selectTagsModalRef.value) {
      new Modal(selectTagsModalRef.value).show();
    }
  }

  onMounted(() => {
    if (tags.value.length == 0) {
      store.dispatch(ArtikelActionTypes.GetTags, undefined);
    }
  });
</script>

<style scoped>
  .artikel-row:hover {
    cursor: pointer;
    color: #2a579b;
    background-color: #dfe9f8 !important;
  }
</style>
