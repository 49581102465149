<template>
  <Sidebar v-if="!isPublicPage" />

  <div class="d-flex flex-column w-100">
    <Navbar v-if="!isPublicPage" />
    <!-- <AutoLogout v-if="currentRouteName != 'Login' && loggedIn" /> -->

    <div id="main-content" class="p-3">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
  import Sidebar from '@/components/Sidebar.vue';
  import Navbar from '@/components/Navbar.vue';
  // import AutoLogout from "@/components/AutoLogout.vue";
  // import LoadingSpinner from "@/components/LoadingSpinner.vue";

  import { defineComponent, computed } from 'vue';
  import { useStore } from '@/store';
  import { useRoute } from 'vue-router';

  import { ActionTypes as AuthActionTypes } from '@/store/modules/Auth/actions';

  export default defineComponent({
    components: {
      Sidebar,
      Navbar,
    },

    setup() {
      const store = useStore();
      const route = useRoute();

      const authLoading = computed(() => store.getters.status.authLoading);
      const msalConfig = computed(() => store.getters.msalConfig);

      const publicPages = ['Login', 'Logout'];
      const isPublicPage = computed(
        () =>
          route.name != null &&
          route.name != '' &&
          publicPages.includes(route.name.toString())
      );

      return {
        store,
        authLoading,
        msalConfig,
        isPublicPage,
      };
    },

    beforeCreate() {
      if (this.msalConfig == null) {
        this.store.dispatch(AuthActionTypes.GetAzureLoginConfig, undefined);
      }
    },
  });
</script>

<style lang="scss">
  @import './assets/sass/main.scss';
  @import '../node_modules/bootstrap/scss/bootstrap.scss';

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    min-height: 100vh;
    display: flex;
  }

  #main-content {
    height: 100%;
    background: rgb(242, 243, 248);
  }
</style>
