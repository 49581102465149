<template>
  <div class="d-flex justify-content-between">
    <h1>Artikel Templates</h1>

    <button class="btn" @click="loadArtikeltemplates()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <router-link :to="{ name: 'CreateArtikeltemplate' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
  </div>

  <LoadingSpinner v-if="loading" text="... Artikel werden geladen ..." />

  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Artikel aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="artikeltemplates.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-10 fa-bold">Titel</div>
      <div class="col-2"></div>
    </div>
    <div v-for="template in artikeltemplates" :key="template.id">
      <ArtikeltemplateRow :artikeltemplate="template" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && artikeltemplates.length == 0" class="alert alert-info mt-5">Es wurden keine Artikel gefunden.</div>
</template>

<script lang="ts" setup>
  import { computed, onMounted } from 'vue';
  import { useStore } from '@/store';

  import { ActionTypes as ArtikeltemplateActionTypes } from '@/store/modules/Artikeltemplates/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import ArtikeltemplateRow from '@/components/Artikeltemplates/ArtikeltemplateRow.vue';

  const store = useStore();

  const loading = computed(() => store.getters.status.getArtikeltemplateLoading);
  const errorMsg = computed(() => store.getters.status.artikeltemplateErrorMsg);

  const artikeltemplates = computed(() => store.getters.artikeltemplates);
  const artikeleinheiten = computed(() => store.getters.artikeleinheiten);

  function loadArtikeltemplates() {
    store.dispatch(ArtikeltemplateActionTypes.GetArtikeltemplate, undefined);
  }

  onMounted(() => {
    if (artikeltemplates.value.length == 0) {
      loadArtikeltemplates();
    }

    if (store.getters.tags.length == 0) {
      store.dispatch(ArtikelActionTypes.GetTags, undefined);
    }

    if (artikeleinheiten.value.length == 0) {
      store.dispatch(ArtikelActionTypes.GetArtikelEinheit, undefined);
    }
  });
</script>
