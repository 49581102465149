// import { ArtikelEinheit, ArtikelEinheitEigenschaft, Artikelart, Eigenschaft } from "./ArtikelModels";
import { ArtikelEinheit } from '@/models/ArtikelModels';

export class ArtikeltemplateEigenschaft {
  id: number;
  artikeltemplateid: number;
  key: string;
  value: string;

  constructor() {
    this.id = 0;
    this.artikeltemplateid = 0;
    this.key = '';
    this.value = '';
  }
}

export class ArtikeltemplateVariantenEigenschaft {
  id: number;
  artikeltemplateid: number;
  identifier: string;
  key: string;
  value: string;
  artikelnummerextension: string;

  constructor(id: number, artikeltemplateid: number, identifier: string, key: string, value: string, artikelnummerextension: string) {
    this.id = id;
    this.artikeltemplateid = artikeltemplateid;
    this.identifier = identifier;
    this.key = key;
    this.value = value;
    this.artikelnummerextension = artikelnummerextension;
  }
}

export class ArtikeltemplateTag {
  id: number;
  artikeltemplateid: number;
  tagid: number;

  constructor() {
    this.id = 0;
    this.artikeltemplateid = 0;
    this.tagid = 0;
  }
}

export class Artikeltemplate {
  id: number;
  titel: string;
  artikelartid: number;
  beschreibung: string;
  // artikelart: Artikelart;
  artikeltitel: string;
  artikelnummer: string;
  artikelbeschreibung: string;

  anzahl: number;
  einheit: ArtikelEinheit | null;
  einheitid: number | null;

  eigenschaften: Array<ArtikeltemplateEigenschaft>;
  variantenEigenschaften: Array<ArtikeltemplateVariantenEigenschaft>;
  tags: Array<ArtikeltemplateTag>;

  inProdZusammenfuehren: boolean;
  isDeleted = 0;

  constructor() {
    this.id = 0;
    this.titel = '';
    // this.artikelart = new Artikelart();
    this.artikelartid = 1;
    this.beschreibung = '';
    this.artikeltitel = '';
    this.artikelnummer = '';
    this.artikelbeschreibung = '';

    this.anzahl = 0;
    this.einheit = null;
    this.einheitid = null;

    this.eigenschaften = new Array<ArtikeltemplateEigenschaft>();
    this.variantenEigenschaften = new Array<ArtikeltemplateVariantenEigenschaft>();
    this.tags = new Array<ArtikeltemplateTag>();

    this.inProdZusammenfuehren = false;
    this.isDeleted = 0;
  }
}
