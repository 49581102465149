<template>
  <div v-if="artikeltemplate == null" class="alert alert-warning">Ungültige Artikeltemplate ID: {{ id }}</div>

  <div v-else class="bg-white shadow p-2">
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToArtikeltemplate()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>{{ artikeltemplate.titel }}</h1>
      <button type="button" class="btn" @click="openEditArtikeltemplate()" title="Artikel bearbeiten"><font-awesome-icon icon="pen" /></button>
    </div>

    <p>
      <strong>Template Beschreibung:</strong>
    </p>
    <pre>{{ artikeltemplate.beschreibung }}</pre>

    <hr class="my-4" />

    <h3>Artikel Template Definition</h3>

    <p><strong>Artikelart:</strong> {{ artikelart?.name }}</p>

    <p><strong>Artikel Titel:</strong> {{ artikeltemplate.artikeltitel }}</p>

    <p><strong>Artikelnummer:</strong> {{ artikeltemplate.artikelnummer }}</p>

    <p><strong>Anzahl:</strong> {{ artikeltemplate.anzahl }}</p>

    <p><strong>Einheit:</strong> {{ artikeltemplate.einheit?.name }} ({{ artikeltemplate.einheit?.kurz }})</p>

    <p><strong>Bestand in Produktion zusammenführen:</strong> {{ artikeltemplate.inProdZusammenfuehren ? 'Ja' : 'Nein' }}</p>

    <p>
      <strong>Artikel Beschreibung:</strong>
    </p>
    <pre>{{ artikeltemplate.artikelbeschreibung }}</pre>

    <hr class="my-3" />

    <!-- EIGENSCHAFTEN -->
    <div class="mb-3">
      <h3>Eigenschaften</h3>

      <div class="row w-50 mb-2">
        <div class="col-4">
          <div>
            <span class="fs-5">Eigenschaft</span>
          </div>
        </div>

        <div class="col-8">
          <div>
            <span class="fs-5">Wert</span>
          </div>
        </div>
      </div>

      <div v-for="(eigenschaft, index) in artikeltemplate.eigenschaften" :key="eigenschaft.key + '-' + index">
        <div class="row w-50 mb-3">
          <div class="col-4">
            <strong class="fst-italic">{{ eigenschaft.key }}</strong>
          </div>

          <div class="col-8">
            {{ eigenschaft.value }}
          </div>
        </div>
      </div>
    </div>

    <hr class="my-3" />

    <!-- Tags -->
    <div class="my-3">
      <h3>Tags</h3>

      <div class="d-flex flex-row mb-3 flex-wrap">
        <p v-for="tag in artikeltemplate.tags" :key="tag.id" class="lead me-2">
          <span class="badge text-bg-primary">{{ tags.find((t) => t.id == tag.tagid)?.key }}</span>
        </p>
      </div>
    </div>

    <hr class="my-3" />

    <!-- VARIANTENEIGENSCHAFTEN -->
    <div class="mb-3" v-if="artikeltemplate.artikelartid == 2">
      <h3>Varianten Eigenschaften</h3>

      <div class="row w-75 mb-2">
        <div class="col-3">
          <div>
            <span class="fs-5">Eigenschaft</span>
          </div>
        </div>

        <div class="col">
          <div>
            <span class="fs-5">Wert</span>
          </div>
        </div>
      </div>

      <!-- {{ artikeltemplate.variantenEigenschaften }} -->
      <div v-for="(values, key, index) in Object.groupBy(artikeltemplate.variantenEigenschaften, ({ key }) => key)" :key="key + '-' + index">
        <div class="row w-75 mb-3">
          <div class="col-3">
            <strong class="fst-italic">{{ key }}</strong>
          </div>

          <div class="col">
            <div class="row">
              <div class="col-4 fw-bold">Wert</div>
              <div class="col fw-bold">Artikelnummer Erweiterung</div>
            </div>

            <div class="row" v-for="wert in values">
              <div class="col-4">{{ wert.value }}</div>
              <div class="col">{{ wert.artikelnummerextension }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  const { id } = defineProps({
    id: {
      type: String,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const artikeltemplate = computed(() => store.getters.artikeltemplates.find((a) => a.id === parseInt(id)));
  const artikelart = computed(() => store.getters.artikelarten.find((a) => a.id == artikeltemplate.value?.artikelartid));
  const tags = computed(() => store.getters.tags);

  function returnToArtikeltemplate() {
    router.push({ name: 'Artikeltemplates' });
  }

  function openEditArtikeltemplate() {
    router.push({ name: 'EditArtikeltemplate', params: { id: id } });
  }
</script>

<style scoped>
  .footerbar {
    position: absolute;
    bottom: 0;
  }
</style>
