<template>
  <div class="row py-3 mb-2 mx-1 bg-white shadow-sm auftrag-row">
    <div class="col-1 my-auto">
      {{ artikel?.artikelNr }}
    </div>

    <div class="col-2 my-auto">
      {{ artikel?.titel }}
    </div>

    <div class="col-2 my-auto">
      {{ auftragpos.anzahl }}
    </div>

    <div class="col-2 my-auto">
      {{ einheit?.name }}
    </div>

    <div class="col"></div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue';
  import { useStore } from '@/store';
  import { AuftragPos } from '@/models/AuftragModels';
  import { Artikel, ArtikelEinheit } from '@/models/ArtikelModels';

  const { auftragpos } = defineProps({
    auftragpos: {
      type: Object as PropType<AuftragPos>,
      required: true,
    },
  });

  const store = useStore();
  // const router = useRouter()

  const artikel = computed(() => store.getters.artikel.find((a: Artikel) => a.id == auftragpos.artikelid));
  const einheit = computed(() => store.getters.artikeleinheiten.find((a: ArtikelEinheit) => a.id == auftragpos.einheitid));
</script>

<style scoped>
  .kunde-row:hover {
    cursor: pointer;
    color: #2a579b;
    background-color: #dfe9f8 !important;
  }
</style>
