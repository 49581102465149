<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm kunde-row" @click="openKundeDetails()">
    <div class="col-1 my-auto">
      {{ kunde.id }}
    </div>

    <div class="col-2 my-auto">
      {{ kunde.firma }}
    </div>

    <div class="col-3 my-auto">
      {{ kunde.vorname }} {{ kunde.name }}
    </div>

    <div class="col-2 my-auto">
      {{ kunde.email }}
    </div>
    
    <div class="col-2 my-auto">
      {{ kunde.tel }}
    </div>
    
    <div class="col-1 my-auto">
      {{ kunde.lieferland }}
    </div>

    <div class="col-1 my-auto">
      <button class="btn btn-primary m-0 float-end">
        <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { Kunde } from "@/models/KundeModels";

export default defineComponent({
  name: "ArtikelRow",
  props: {
    kunde: {
      type: Object as PropType<Kunde>,
      required: true
    }
  },

  setup (props) {
    const router = useRouter()

    return {
      router
    }
  },

  methods: {
    openKundeDetails () {
      this.router.push({name: "DetailsKunde", params: {id: this.kunde.id}})
    }
  }
})

</script>

<style scoped>
.kunde-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
}
</style>